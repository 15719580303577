import * as d3 from 'd3';

export const strings  = {
  watermark: "Ubimemes Research Inc.",
  norec:     "No data is available."
};

export const bar = {
  width: 480,
  height: 480,
  legendGutter: 0,
  margin: { top: 40, bottom: 100, left: 60, right: 40 },
  maxValue: 5.0,
  wrapWidth: 60,
  aspectRatio: 1.0,
  numFormat: '.3s',
  fontSize: 12,
  rangeFactor: 1.25,
  numTicks: 5,
  useLegend: false,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
};

export const barwide = {
  width: 2400,
  margin: { top: 40, right: 40, bottom: 80, left: 60 },
  legendGutter: 0,
  maxValue: 5.0,
  wrapWidth: 60,
  aspectRatio: 1.0,
//  formatString: '.3s',
  fontSize: 14,
  rangeFactor: 1.25,
  numTicks: 5,
  useLegend: false,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
};

export const barfullwidth = {
  width: 2400,
  height: 480,
  legendGutter: 0,
  margin: { top: 40, bottom: 40, left: 60, right: 40 },
  maxValue: 5.0,
  wrapWidth: 600,
  aspectRatio: 1.0,
  formatString: '.3s',
  fontSize: 12,
  rangeFactor: 1.25,
  numTicks: 5,
  useLegend: false,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
};



export const line = {
  width: 480,
  height: 480,
  margin: { top: 40, bottom: 40, left: 60, right: 40 },
  legendGutter: 50,
  // maxValue: 2000,
//  strokeWidth: 2,
  formatString: '.3s',
  fontSize: 12,
  pointSize: 14,
  rangeFactor: 1.25,
  numTicks: 5,
  strokeWidth: 3,
};
export const groupedBar = {
  width: 580,
  height: 480,
  legendGutter: 50,
  margin: { top: 40, bottom: 40, left: 20, right: 10 },
  maxValue: 5.0,
  wrapWidth: 60,
  aspectRatio: 1.6,
  numFormat: ',',
//  formatValue: d3.format(',.2f'),
  formatValue: d3.format(','),
  fontSize: 14,
  rangeFactor: 1,
//  rangeFactor: 1.25,
  numTicks: 6,
  useLegend: true,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
// units: '(단위:명)',
};

export const faucet = {
  width: 960,
  height: 480,
  legendGutter: 50,
  margin: { top: 40, bottom: 40, left: 60, right: 40 },
  maxValue: 5.0,
  wrapWidth: 60,
  aspectRatio: 1.6,
  formatString: '.2f',
  fontSize: 12,
  numCharts: 2,
  minValue: 1,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
  formatValue: d3.format('.2f'),
  // numTicks: 6,
};

export const bead = {
  width: 720,
  height: 480,
  margin: { top: 40, bottom: 40, left: 40, right: 30 },
  fontSize: 16,
  strokeWidth: 3,
};

export const network = {
  width: 720,
  height: 480,
  margin: { top: 40, bottom: 40, left: 40, right: 40 },
  formatString: '.2f',
  fontSize: 16,
  variant: 1,
};

/** Frequency radar */
export const radar1 = {
  width: 480,
  height: 480,
  margin: { top: 40, bottom: 40, left: 40, right: 40 },
  levels: 5,
  maxValue: 5,
  labelFactor: 1.2,
  wrapWidth: 60,
  opacityArea: 0.9,
  dotRadius: 24,
  opacityCircles: 0.1,
  strokeWidth: 2,
  roundStrokes: false,
  aspectRatio: 1.0,
  formatString: '.2f',
  fontSize: 12,
  labelGutter: 1.2,
  legendGutter: 50,
};

/** Top 3 radar */
export const radar2 = {
  width: 480,
  height: 480,
  margin: { top: 20, bottom: 70, left: 40, right: 40 },
  levels: 5,
  maxValue: 5,
  labelFactor: 1.2,
  wrapWidth: 60,
  opacityArea: 0.9,
  dotRadius: 8,
  opacityCircles: 0.1,
  strokeWidth: 2,
  roundStrokes: false,
  aspectRatio: 1.0,
  formatString: '.2f',

  fontSize: 12,
  labelGutter: 1.2,
  legendGutter: 50,
};

/** Shaded area radar */
export const radar3 = {
  width: 480,
  height: 480,
  margin: { top: 40, bottom: 40, left: 40, right: 40 },
  levels: 5,
  maxValue: 5,
  labelFactor: 1.2,
  wrapWidth: 60,
  opacityArea: 0.9,
  dotRadius: 8,
  opacityCircles: 0.1,
  strokeWidth: 2,
  roundStrokes: false,
  aspectRatio: 1.0,
  fontSize: 12,
  formatString: '.2f',

  labelGutter: 1.2,
  legendGutter: 50,
};

/** Non-shaded radar (red stroke only). */
export const radar4 = {
  width: 480,
  height: 480,
  margin: { top: 40, bottom: 40, left: 40, right: 40 },
  levels: 5,
  maxValue: 5,
  labelFactor: 1.2,
  wrapWidth: 100,
  opacityArea: 0.35,
  dotRadius: 8,
  opacityCircles: 0.1,
  strokeWidth: 3,
  roundStrokes: false,
  aspectRatio: 1.0, // width:height aspect ratio
  formatString: '.2f',
  fontSize: 12,
  labelGutter: 1.2,
};

export const pyramid = {
  legendGutter: 30,
  margin: { top: 10, bottom: 20, left: 20, right: 20, middle: 28 },
  maxValue: 5.0,
  wrapWidth: 60,
  aspectRatio: 1.0,
  formatString: '.1s',
  fontSize: 12,
  rangeFactor: 1.0,
  numTicks: 5,
  useLegend: true,
  units: '(단위:명)',
  colors: ['steelblue', 'firebrick' ],
};

export const pie = {
  width: 480,
  height: 480,
  margin: { top: 40, bottom: 40, left: 40, right: 40 },
  colors: d3.schemeCategory10,
  levels: 5,
  maxValue: 5,
  labelFactor: 1.2,
  wrapWidth: 100,
  opacityArea: 0.35,
  dotRadius: 8,
  opacityCircles: 0.1,
  strokeWidth: 3,
  roundStrokes: false,
  aspectRatio: 1.0, // width:height aspect ratio
  formatString: '.2f',
  fontSize: 12,
  labelGutter: 1.2,
  legendGutter: 50,
  useLegend: true,
  firstColor: null,
  lastColor: null,
  units: '(단위:명)',
};

export const mline = {
  margin: { top: 40, bottom: 20, left: 50, right: 30 },
  legendGutter: 30,
  formatString: '.3s',
  fontSize: 14,
  pointSize: 14,
  rangeFactor: 1.25,
  numTicks: 5,
  strokeWidth: 3,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
  useLegend: true,
  units: '단위:개',
};


