import { join } from 'path';

// Current source: http://192.168.0.15:9000/japan
export async function sourceFromUrl(url) {
  const res = await fetch(url);
  const metadata = await res.json();
  const source = {
    type: 'vector',
    bounds: metadata.bounds,
    center: metadata.center,
    minzoom: 11,
    maxzoom: metadata.maxzoom,
    promoteId: 'key_code',
    tiles: [join(url, `{z}/{x}/{y}.${metadata.format}`)],
  };
  return source;
}

export const gridSource = {
  type: 'vector',
  bounds: [139, 24.29167, 153.97812, 36],
  center: [139.658203, 35.675116, 11],
  minzoom: 11,
  maxzoom: 11,
  promoteId: 'key_code',
  tiles: ['http://192.168.0.15:9000/japan/{z}/{x}/{y}.pbf'],
};

// export const clusterGridSource = {
//   type: 'vector',
//   cluster: true,
//   clusterMaxZoom: 11, // Max zoom to cluster points on
//   clusterRadius: 10000, // Radius of each cluster when clustering points (defaults to 50)
//   bounds: [139, 24.29167, 153.97812, 36],
//   maxzoom: 14,
//   minzoom: 0,
//   promoteId: 'key_code',
//   tiles: ['http://192.168.0.15:9000/japan/{z}/{x}/{y}.pbf'],
// };
