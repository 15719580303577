import mapboxgl from 'mapbox-gl';
import React, { useEffect } from "react";
import MapToolbar from './CvsMapTbar';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MBMap.scss';
import { sourceFromUrl } from './sources';
import * as Config from 'config';

import {
  grid,
  population,
  popTimeseries,
  cvs,
  popHighlighted,
  classinfo,
  cellSelected,
  housetype,
} from './layers4cvsMap';

import { popScheme } from './styles';

//import { makeStyles } from '@material-ui/core/styles';
import SmallbizData from "components/Table/Smallbiz";

const SEOUL_COORDS = [37.532600, 127.024612];
var map;

//class CvsMap extends React.Component {
const CvsMap = React.forwardRef( 
               ({searchResults, selection, onSelectionChange, onGmapLoad }, ref ) => {

  const cfg = Object.assign({}, Config);
  var mapContainer;
  var selected = new Map();
//  var layer = {}

  // skip the first request to avoid to load dummy data 
//  if( searchResults.flag === 'init' ) return;

//  const seoul={lng:"127.024612", lat: "37.532600", zoom:11}

  const stateDef = {
      lng: SEOUL_COORDS[0],
      lat: SEOUL_COORDS[1],
      zoom: 11,
      tool: 'select',
      action: 'new',
  };
  const [state,   setState ] = React.useState(stateDef);
//  const [coord,   setCoord]  = React.useState(seoul);
//  const [windowCenter,   setWindowCenter]  = React.useState({'x':0, 'y':0});
//  const [keycode,   setKeycode]  = React.useState('');
  const [ didMount, setDidMount ] = React.useState( false )

  useEffect( () => {

    if( didMount ) {
      compWillUpdate()
    }
    else 
    {
      compDidMount()
      setDidMount(true)
    }

  }, [selection] );
//  }, [selection, searchResults] );

  const compDidMount = () => {

    map = new mapboxgl.Map({
      container: mapContainer,
      style: `${cfg.maptiler.styleUrl}`,
//      style: 'https://api.maptiler.com/maps/streets/style.json?key=4gVkDqVUee77OM3olijM',
//      style: 'https://api.maptiler.com/maps/streets/style.json?key=ElkgcjiPHXyLvEovADXa',
      center: [127.024612, 37.532600],
      zoom: state.zoom,
    });

    map.boxZoom.disable();

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    map.addControl(scale);
    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', onMapLoad);
    map.on('move', () => {
      state.lng = map.getCenter().lng.toFixed(4)
      state.lat = map.getCenter().lat.toFixed(4)
      state.zoom = map.getZoom().toFixed(2)
      setState( state )
    });

    disableDragRotate();
  }

  const compWillUpdate = () => {
    loadSelection(selection);
  }

  const loadSelection = (selection) => {
    if (selection) {
      selected = new Map(selection.featPop);
      map.setFilter('population-highlighted', [
        'in',
        'key_code',
        ...Array.from(selected.keys()),
      ]);
    }
  }

  const onContextMenu = (e) => {}

  const disableDragRotate = () => {
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();
    map.on('contextmenu', onContextMenu);
  }

/**
  const enableDragRotate = () => {
    map.dragRotate.enable();
    map.touchZoomRotate.enableRotation();
    map.off('contextmenu', onContextMenu);
  }
**/

  async function onMapLoad () {

    const canvas = map.getCanvasContainer();

    // Add vector tile sources and layers
    map.addSource(
      'cvsinfo',
//      await sourceFromUrl('https://api.maptiler.com/tiles/a2b65864-c3b6-4950-93a3-4bc2873845ba/tiles.json?key=4gVkDqVUee77OM3olijM'),
//      await sourceFromUrl(`${cfg.aws.ec2.url}:${cfg.aws.ec2.port.mbtiles}/kr.cvsWithPop`),
//      await sourceFromUrl(`${cfg.aws.ec2.url}:${cfg.aws.ec2.port.mbtiles}/kr.cvsWithPopHtype`),
      await sourceFromUrl(`${cfg.net.enabled.url}:${cfg.net.enabled.port.mbtiles}/kr.cvsWithPopHtype`),
    );


    map.addLayer(grid);
    map.addLayer(population);
    map.addLayer(popHighlighted);
    map.addLayer(popTimeseries);
    map.addLayer(cvs);
    map.addLayer(classinfo);
    map.addLayer(cellSelected);
    map.addLayer(housetype);

//    const key_codes = Array.from(searchResults.rows.map( (d) => {return d.key_code}) )
//    map.addLayer(highlightedLayer(key_codes));
//    map.addLayer(cvsLayer(key_codes));
//    map.addLayer(selectedLayer(key_codes));

    /** Load selection */
    loadSelection(selection);

    let selStart;
    let selCurrent;
    let selBoxElement;
    let selButton;

    // Return the xy coordinates of the mouse position
    const mousePos = e => {
      var rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop,
      );
    };

    const mouseDown = e => {
      const { tool } = state;

      selButton = e.button;
      const rightClicked = selButton === 2;

      if( rightClicked ) return;

      if (!(tool === 'select' || tool === 'deselect')) return;

      if( (e.shiftKey && e.button === 0) ) return;

      // Disable default drag panning when the shift key is held down.
      map.dragPan.disable();

      // Call functions for the following events
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('keydown', onKeyDown);

      // Capture the first xy coordinates
      selStart = mousePos(e);
    };

    const onMouseMove = e => {
      // Capture the ongoing xy coordinates
      selCurrent = mousePos(e);

      // Append the box element if it doesnt exist
      if (!selBoxElement) {
        selBoxElement = document.createElement('div');
        selBoxElement.classList.add('boxdraw');
        canvas.appendChild(selBoxElement);
      }

      var minX = Math.min(selStart.x, selCurrent.x),
          maxX = Math.max(selStart.x, selCurrent.x),
          minY = Math.min(selStart.y, selCurrent.y),
          maxY = Math.max(selStart.y, selCurrent.y);

      // Adjust width and xy position of the box element ongoing
      var pos = 'translate(' + minX + 'px,' + minY + 'px)';
      selBoxElement.style.transform = pos;
      selBoxElement.style.WebkitTransform = pos;
      selBoxElement.style.width = maxX - minX + 'px';
      selBoxElement.style.height = maxY - minY + 'px';

    };

    const onMouseUp = (e) => {
      // Capture xy coordinates
      finish([selStart, mousePos(e)]);
    };

    const onKeyDown = (e) => {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    };

    const finish = (bbox) => {
      const { action } = state;
      const rightClicked = selButton === 2;

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mouseup', onMouseUp);

      if (selBoxElement) {
        selBoxElement.parentNode.removeChild(selBoxElement);
        selBoxElement = null;
      }

      if (bbox) {
        const featPop = map.queryRenderedFeatures(bbox, {
          layers: ['population' ],
        });

/**
  var filter = featPop.reduce(
      function( memo, feature ) {
        memo.push(feature.properties.key_code);
        return memo
      },
      ['in', 'key_code']
  );
  map.setFilter('popHighlighted', filter)
**/

        const featPoptrend = map.queryRenderedFeatures(bbox, {
          layers: ['popTimeseries'],
        });

        const featClass2 = map.queryRenderedFeatures(bbox, {
          layers: ['classinfo'],
        });

        const featHousetype = map.queryRenderedFeatures(bbox, {
          layers: ['housetype'],
        });

        const features = {
          'population': featPop, 
          'poptrend': featPoptrend, 
          'cvsclass2': featClass2,
          'housetype': featHousetype
        }

        if (features.length >= 5000) {
          return window.alert('Select a smaller number of features');
        }

/**
  var filter = featPop.reduce(
      function( memo, feature ) {
console.log( feature );
        memo.push(feature.properties.key_code);
        return memo
      },
      ['in', 'key_code']
  );
  map.setFilter('popHighlighted', filter)
**/

        if (action === 'new') {
          if (!rightClicked) {
            clearSelection();
            selectAll(features);
          } else {
            unselectAll(features);
          }
        } else if (action === 'add') {
          if (!rightClicked) {
            selectAll(features);
          } else {
            unselectAll(features);
          }
        } else if (action === 'remove') {
          if (!rightClicked) {
            unselectAll(features);
          } else {
            selectAll(features);
          }
        } else {
          console.log('Invalid action.');
        }

        // true: selection by row click, false: selection by mouse drag
        onSelectionChange(selected, map.getCenter(), 'SELECTION');
      }
      map.dragPan.enable();
    };

    // dragging behaviour.
    canvas.addEventListener('mousedown', mouseDown, true);
  }

//  const onMapClick = (e) => {}

  const selectAll = (features) => {

    var featPop = new Map()
    var featPoptrend = new Map()
    var featCvsclass2 = new Map()
    var featHousetype = new Map()
//    var key_code = ""

    features.population.forEach(feature => {
      select(feature.id, feature.properties, featPop );
    });

    features.poptrend.forEach(feature => {
      select(feature.id, feature.properties, featPoptrend );
    });

    features.cvsclass2.forEach(feature => {
      select(feature.id, JSON.parse(feature.properties.props), featCvsclass2 );
    });

    features.housetype.forEach(feature => {
      select(feature.id, feature.properties, featHousetype );
    });

    selected = {
        'population': featPop,
        'poptrend': featPoptrend,
        'cvsclass2': featCvsclass2,
        'housetype': featHousetype,
    }
  }

  const select = (key_code, feature, buf) => {

    buf.set( key_code, feature )
  }

  const unselectAll = (features) => {
    features.forEach(feature => {
      unselect(feature.properties.key_code);
    });
  }


  const unselect = (gid) => {
    selected.delete(gid);
  }
  const clearSelection = () => {
    if( selected.cvsclass2  ) selected.cvsclass2.clear()
    if( selected.poptrend   ) selected.poptrend.clear()
    if( selected.population ) selected.population.clear()
    if( selected.housetype  ) selected.housetype.clear()
  }

/**
  const setTool = (tool) => {

    state.lastTool = state.tool
    state.tool = tool
    setState(state)
  }

  const lastTool = () => {
    state.lastTool = state.tool
    state.tool = state.lastTool
    setState(state)
  }

  const resizeMap = () => {
    map.resize();
  }
***/

  const handleToolChange = (newTool) => {
    if (newTool === 'clearAll') {
      map.setFilter('highlightedLayer', ['in', 'key_code', '']);
    }
    state.tool = newTool
    setState( state );

  }

  const handleActionChange = (newAction) => {
    state.action = newAction
    setState( state )
  }

  const handleRowClick = ( param ) => {

    const coord = { lng: parseFloat(param.data.lng, 4).toFixed(4),
                    lat: parseFloat(param.data.lat, 4).toFixed(4) }

    map.jumpTo( {center: [coord.lng, coord.lat]} )

//    const key_codes = Array.from(searchResults.rows.map( (d) => {return d.key_code}) )
    map.setFilter('cell-selected', [
      '==',
      'key_code',
      param.data.key_code
    ]);

    map.once('idle', (e) =>  {

      const population = map.querySourceFeatures('cvsinfo', {
          sourceLayer: 'krpop',
          filter: ['==', 'key_code', param.data.key_code ]
      });

      const poptrend = map.querySourceFeatures('cvsinfo', {
          sourceLayer: 'krpoptrend',
          filter: ['==', 'key_code', param.data.key_code ]
      });

/***
      const cvsinfo = map.querySourceFeatures('cvsinfo', {
          sourceLayer: 'krretailcvs',
          filter: ['==', 'key_code', param.data.key_code ]
      });
***/

      const cvsclass2info = map.querySourceFeatures('cvsinfo', {
          sourceLayer: 'krretailcvsclass2',
          filter: ['==', 'key_code', param.data.key_code ]
      });

      const housetype = map.querySourceFeatures('cvsinfo', {
          sourceLayer: 'krhousetype',
          filter: ['==', 'key_code', param.data.key_code ]
      });


      const cellInfo = {
        'population': population,
        'poptrend': poptrend,
        'cvsclass2': cvsclass2info,
        'housetype': housetype
      }

      clearSelection();
      selectAll(cellInfo);
        onSelectionChange(selected, map.getCenter(), 'TARGET');
    });
  }


  const handleDataSync = () => {
//    map.once('idle', (e) =>  {
      const renderedData = map.queryRenderedFeatures({
        layers: ['grid' ],
      });
      const key_codes = Array.from(renderedData.map( d => {return (d.properties.key_code)} ) ) ;
      onGmapLoad( key_codes );
//    });

  }


  return (

    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"}} >
      <div style={{ width: "35%", padding: '0px 8px 0px 0px' }}>
        <SmallbizData
            id="results"
            callback={handleRowClick}
            data={searchResults}
        />
      </div>
      <div style={{width: "65%"}  }>
        <div className="relative" style={{height: "480px", width: "100%"  }}>

          <div className="interactive-map">
            <div
                ref={node => (mapContainer = node)}
                className="mapContainer"
            />
            <div className="overlay">
              <MapToolbar
                  className="toolbar"
                  onToolChange={handleToolChange}
                  onActionChange={handleActionChange}
                  onDataSync={handleDataSync}
              />
              <div className="legend">
{/*
                {cvsPopScheme.colors.map((c, i) => (
*/}
                {popScheme.colors.map((c, i) => (
                  <div key={"legendScheme"+i}>
                    <span style={{ backgroundColor: c }}></span>
{/**
                    {cvsPopScheme.thresholds[i]}
**/}
                    {popScheme.thresholds[i]}
                  </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
});

export default CvsMap;
