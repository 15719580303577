import React from "react";
import { Paper } from "@material-ui/core";

const Card = ({ w, h, flex, children, visibility }) => {
  return (
    <Paper
      variant="outlined"
      style={{
        width: w || "auto",
        height: h || "auto",
//        margin: 3,
//        maxHeight: 480 + 48,
//        maxWidth: 950,
        flex: flex || "1 0 21%",
        visibility: visibility || "visible",
      }}
    >
      {children}
    </Paper>
  );
};

export default Card;
