import React, { useEffect } from "react";

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";

import MBMap from 'components/Map/kr/RetailMap';

// core components
import ChartContainer from "components/ChartContainer";
import Bar from "components/Chart/Bar";
import Barwide from "components/Chart/BarWide";
import SmallbizData from "components/Table/Smallbiz";

import Card from "components/ChartCard";
import * as dummy from "shared/dummy";
import * as custom from "shared/custom";
import * as config from "./config";
import * as Config from "config";

export default function Smallbiz() {
  const cfg = Object.assign({}, Config, config);
  const seoul=[127.024612, 37.532600]
  const chartlist = [
    { id: "kr_dummy",  title: "",                      },
    { id: "kr_class1", title: "8개 대표 업종 현황"  },
    { id: "kr_class2", title: "" },
    { id: "kr_class3", title: "" },
  ];

  const [selection, setSelection ] = React.useState(null);

  const [class1,        setClass1]        = React.useState(null);
  const [class2,        setClass2]        = React.useState(null);
  const [class3,        setClass3]        = React.useState(null);
  const [storeList,     setStoreList]     = React.useState(null);

  const [class1Selected, setClass1Selected] = React.useState("");
  const [class2Selected, setClass2Selected] = React.useState("");
  const [class3Selected, setClass3Selected] = React.useState("");

  const [class2Title,   setClass2Title]   = React.useState("")
  const [class3Title,   setClass3Title]   = React.useState("")

  const handleSelectionChange = function(features, center) {
    const sel = newSelectionObject(features, center);
    setSelection(sel);
  }

  const saveSelection = (key, value) => {
    localStorage.setItem( key, value );
  }

  const readSelection = (key) => {
    return localStorage.getItem( key ) ? localStorage.getItem( key ) : "";
  }


  const newSelectionObject = function(features, latlng) {
    const sel = {
      center: latlng.toArray(),
      features: new Map(features),
      dateCreated: Date.now(),
    };
    return sel;
  };

  useEffect(() => {
    if( selection ) {
      drawClass1Chart (selection );
    }
  }, [selection]);


  useEffect(() => {
    if( selection ) {
      drawClass2Chart (selection, class1Selected);
    }
  }, [selection, class1Selected]);


  useEffect(() => {
    if( selection ) {
      setStoreList(null);
      drawClass3Chart (selection, class2Selected);
    }
  }, [selection, class2Selected]);


  useEffect(() => {
    if( selection ) {
      fetchData(selection, class3Selected);
    }
  }, [class3Selected]);


  const drawClass1Chart = (chartData ) => {

    var nosAgg = new Map();
    const rdata = Array.from(chartData.features.values())

    /** Prepare data for use with d3 */
    let dataAgg = [];

//    if (rdata.length > 0) {

      var nosAgg = new Map();
      rdata.map( row => {
        for( var i = 0; i < row.length; i++ ) {
          const accum = nosAgg.get( row[i].class1_nm ) ? nosAgg.get( row[i].class1_nm ) : 0
          nosAgg.set( row[i].class1_nm, accum + row[i].cnt );
        }
        return 0;
      });
      dataAgg = []
      nosAgg.forEach( (value, key) => {
        dataAgg.push({ name: key, nos: value})
      })
      dataAgg["columns"] = ["name", "nos"]
      setClass1(dataAgg)
//    }
  }

  const drawClass2Chart = (chartData, class1Nm) => {
    var nosAgg = new Map();
    const rdata = Array.from(chartData.features.values())
    rdata.map( row => {
      for( var i = 0; i < row.length; i++ ) {
        if( row[i].class1_nm === class1Nm ) {
          const accum = nosAgg.get( row[i].class2_nm ) ? nosAgg.get( row[i].class2_nm ) : 0
          nosAgg.set( row[i].class2_nm, accum + row[i].cnt );
        }
      }
      return 0;
    });
    const dataAgg = []
    nosAgg.forEach( (value, key) => {
      dataAgg.push({ name: key, nos: value})
    })
    dataAgg["columns"] = ["name", "nos"]

    setClass2Title( class1Nm )

    saveSelection( "class1Nm", class1Nm );

    setClass2(dataAgg)
  }

  const drawClass3Chart = (data, class2Nm) => {
    var nosAgg = new Map();
    const rdata = Array.from(data.features.values())
    rdata.map( row => {
      for( var i = 0; i < row.length; i++ ) {
        if( row[i].class2_nm === class2Nm ) {
          const accum = nosAgg.get( row[i].class3_nm ) ? nosAgg.get( row[i].class3_nm ) : 0
          nosAgg.set( row[i].class3_nm, accum + row[i].cnt );
        }
      }
      return 0;
    });
    const dataAgg = []
    nosAgg.forEach( (value, key) => {
      dataAgg.push({ name: key, nos: value})
    })
    dataAgg["columns"] = ["name", "nos"]
    const title = class2Nm ? class1Selected + " > " + class2Nm : "";
    setClass3Title( title );
    saveSelection( "class2Nm", class2Nm );
    setClass3(dataAgg)
  }

  async function fetchData(dataSelected, class3Nm) {

    const keycodes = Array.from(dataSelected.features.keys())
    const stores = []
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/smallbiz`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({'key_codes': keycodes, 'class3': class3Nm })

    })
    .then(response => { return response.json() } )
    .then(data => { 
       data = data.map( (d,i) => {return( Object.assign({'id': i+1}, d)) })

       stores['columns'] = formatHeader(Object.keys(data[0]))
       stores['rows'] = data

       setStoreList(stores)
     });
  }

  const formatHeader = (header) => {

    const format = [ 
          {'hid': 'id',        'title': 'No.',          'hide': false, resizable: false, 'width': 60, 'type': 'string'},
          {'hid': 'key_code',  'title': 'KEY_CODE',     'hide': true,  resizable: false, 'width': 140, 'type': 'string'},
          {'hid': 'shop_nm',   'title': '상호명',       'hide': false, resizable: false, 'width': 400, 'type': 'string'},
          {'hid': 'branch_nm', 'title': '지점명',       'hide': false, resizable: false, 'width': 200, 'type': 'string'},
          {'hid': 'sic_nm',    'title': '표준산업분류', 'hide': false, resizable: false, 'width': 200, 'type': 'string'},
          {'hid': 'biztype',   'title': '상권업종분류', 'hide': false, resizable: false, 'width': 400, 'type': 'string'},
          {'hid': 'oaddr',     'title': '지번주소',     'hide': false, resizable: false, 'width': 400, 'type': 'string'},
          {'hid': 'naddr',     'title': '도로명주소',   'hide': false, resizable: false, 'width': 400, 'type': 'string'},
          {'hid': 'bldg_nm',   'title': '건물정보',     'hide': false, resizable: false, 'width': 200, 'type': 'string'},
          {'hid': 'floor_no',  'title': '층정보',       'hide': false, resizable: false, 'width': 80, 'type': 'number'},
          {'hid': 'unit_no',   'title': '호정보',       'hide': false, resizable: false, 'width': 80, 'type': 'number'},
    ]

    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i];
      const item = format.find( it => it.hid === hid )
        columns.push( {
          'field': hid,
          'hide': item ? item.hide : false,
          'resizable': item ? item.resizable : false,
          'headerName': item ? item.title : '',
          'width': item ? item.width : 80,
          'type': item ? item.type : 'string'
        })
    }

    return( columns )
  }

  const handleChartClick = (chartId, itemClicked) => {

    switch( chartId ) {
      case 'kr_class1':
            setClass1Selected( itemClicked );
            break;
      case 'kr_class2':
            setClass2Selected( itemClicked );
            break;
      case 'kr_class3':
            setClass3Selected( itemClicked );
            break;
      default :
    }
  };

  return (
    <>
      <Sidebar />
      <Navbar />

      <div className="relative md:ml-64 bg-gray-200">
        <div className="flex" style={{padding: '16px 16px 8px 16px' }}>
          <div className="relative" style={{height: "480px", width: "75%", marginBottom: '-30px'}}>

            <MBMap
              coord={seoul}
              selection={selection}
              onSelectionChange={handleSelectionChange}
            />
          </div>

          <div className="flex" style={{width: "25%", paddingLeft: '8px' }}>

              <Card w="100%" h="100%" flex="auto"> 
                <ChartContainer
                  component={Bar}
                  jsonString={class1}
                  id={chartlist[1].id}
                  title={chartlist[1].title}
                  wparam={cfg.class1.wparam}
                  onChartClick={handleChartClick}
                />
              </Card>
          </div>
        </div>


        <div className="flex-auto w-full xl:w-4/4" style={{padding: '8px 16px 8px 16px' }}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >

              <Card w="50%" h="360px" flex="auto">

                <ChartContainer
                  component={Barwide}
                  jsonString={class2}
                  id={chartlist[2].id}
                  title={class2Title}
                  wparam={cfg.class2.wparam}
                  onChartClick={handleChartClick}
                />
              </Card>

              <Card w="50%" h="360px" flex="auto">
                <ChartContainer
                  component={Barwide}
                  jsonString={class3}
                  id={chartlist[3].id}
                  title={class3Title}
                  wparam={cfg.class3.wparam}
                  onChartClick={handleChartClick}
                />
              </Card>
              <Card w="100%" h="auto" flex="auto">
                <SmallbizData 
                  id="SmallbizList"
                  data={storeList}
                />
              </Card>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
