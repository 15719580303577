import React, { useEffect } from "react";

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";

import MBMap from 'components/Map/MBMap';
import * as d3 from 'd3';

// core components
import CopyDialog from "components/CopyDialog/CopyDialog.js";
import ChartContainer from "components/ChartContainer";
import Pyramid from "components/Chart/Pyramid";
import GroupedBar from "components/Chart/GroupedBar";
import Pie from "components/Chart/Pie";
import Mline from "components/Chart/Mline";

import Card from "components/ChartCard";
import * as custom from "shared/custom";
import * as config from "./config";

export default function Population() {
  const population = {

    data: [
      { agegroup : "0-4",    agenmMale: "age_male_5",   agenmFemale: "age_female_5",   male: 0, female: 0 },
      { agegroup : "5-9",    agenmMale: "age_male_10",  agenmFemale: "age_female_10",  male: 0, female: 0 },
      { agegroup : "10-14",  agenmMale: "age_male_15",  agenmFemale: "age_female_15",  male: 0, female: 0 },
      { agegroup : "15-19",  agenmMale: "age_male_20",  agenmFemale: "age_female_20",  male: 0, female: 0 },
      { agegroup : "20-24",  agenmMale: "age_male_25",  agenmFemale: "age_female_25",  male: 0, female: 0 },
      { agegroup : "25-29",  agenmMale: "age_male_30",  agenmFemale: "age_female_30",  male: 0, female: 0 },
      { agegroup : "30-34",  agenmMale: "age_male_35",  agenmFemale: "age_female_35",  male: 0, female: 0 },
      { agegroup : "36-39",  agenmMale: "age_male_40",  agenmFemale: "age_female_40",  male: 0, female: 0 },
      { agegroup : "40-44",  agenmMale: "age_male_45",  agenmFemale: "age_female_45",  male: 0, female: 0 },
      { agegroup : "45-49",  agenmMale: "age_male_50",  agenmFemale: "age_female_50",  male: 0, female: 0 },
      { agegroup : "50-54",  agenmMale: "age_male_55",  agenmFemale: "age_female_55",  male: 0, female: 0 },
      { agegroup : "55-59",  agenmMale: "age_male_60",  agenmFemale: "age_female_60",  male: 0, female: 0 },
      { agegroup : "60-64",  agenmMale: "age_male_65",  agenmFemale: "age_female_65",  male: 0, female: 0 },
      { agegroup : "65-69",  agenmMale: "age_male_70",  agenmFemale: "age_female_70",  male: 0, female: 0 },
      { agegroup : "70-74",  agenmMale: "age_male_75",  agenmFemale: "age_female_75",  male: 0, female: 0 },
      { agegroup : "75-79",  agenmMale: "age_male_80",  agenmFemale: "age_female_80",  male: 0, female: 0 },
      { agegroup : "80-84",  agenmMale: "age_male_85",  agenmFemale: "age_female_85",  male: 0, female: 0 },
      { agegroup : "85-89",  agenmMale: "age_male_90",  agenmFemale: "age_female_90",  male: 0, female: 0 },
      { agegroup : "90-94",  agenmMale: "age_male_95",  agenmFemale: "age_female_95",  male: 0, female: 0 },
      { agegroup : "95-100", agenmMale: "age_male_100", agenmFemale: "age_female_100", male: 0, female: 0 },
      { agegroup : "100+",   agenmMale: "age_male_105", agenmFemale: "age_female_105", male: 0, female: 0 },
    ],

    init : () => {
      population.data.map( it => {
        it.male = 0;
        it.female = 0;
        return( it )
      })
    },
  }

  const chartlist = [
    { id: "kr_dummy",       title: "",                },
    { id: "kr_population",  title: "인구분포"         },
    { id: "kr_keyAgeGroup", title: "연령별 인구분포"  },
    { id: "kr_genderRate",  title: "성별 인구분포"    },
    { id: "kr_popTimelines", title: "인구변동추이"    },
  ];

  const [selection, setSelection ] = React.useState(null);

  const [open,         setOpen]         = React.useState(false);
  const [krpop,        setKrpop]        = React.useState(null);
  const [keyAgePop,    setKeyAgePop]    = React.useState(null);
  const [genderRate,   setGenderRate]   = React.useState(null);
  const [popTimelines, setPopTimelines] = React.useState(null);

  //
  // draw population pyramid chart
  //
  const drawKrpopChart = ( features ) => {

    population.init()

    const rdata = Array.from(features.values())

    rdata.map( row => {

      /* eslint-disable no-unused-vars */
      const [year, key_code, proptype, ...fields] = Object.keys(row);
      /* eslint-enable no-unused-vars */

      fields.forEach(field => {
        if( field.includes("female") ) {
          const item = population.data.find( it => it.agenmFemale === field)
          if( item ) { item.female += row[field] }
        }
        else if( field.includes("male") ) {
          const item = population.data.find( it => it.agenmMale === field)
          if( item ) item.male += row[field]
        }
      })
      return 0
    });

    const chartdata = []
    population.data.map( row => {
      chartdata.push({ group: row.agegroup, male: row.male, female: row.female });
      return 0
    });
    chartdata["columns"] = ["group", "male", "female"]
    return( chartdata )
  }

  const drawKrpopTrendChart = ( feat ) => {

    const rdata = Array.from(feat.values())

    var ages = [];
    var years = [];
    rdata.map( d => {

      ages = Object.keys(d).map( key => {
             return( key.substring(0,3) )
           })

          .filter( (item, pos, self) => {
             return( item !== "key" && item !== "pro" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })

      years =  Object.keys(d).map( key => {
             return( key.substring(4,8) )
           })

          .filter( (item, pos, self) => {
             return( item !== "code" && item !== "type" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })
      return 0
    });


    const matrix = new Array(ages.length).fill(0).map( () => new Array(years.length).fill(0));
    const chartdata = []
    for( var i = 0; i < ages.length; i++ ) {
      for( var j = 0; j < years.length; j++ ) {
        const idx = `${ages[i]}y${years[j]}`;
        matrix[i][j] = {year: years[j], population: d3.sum(rdata.map( row => row[idx]) ) }
      }
      chartdata.push( { name: ages[i], values: matrix[i] } )
    }

    return( chartdata )
  }

  const drawKrpopKeyageChart = ( features ) => {

    const rdata = Array.from(features.values())


      const mAge14Cnt = d3.sum(rdata.map( row => row['age_male_5'] + row['age_male_10'] + row['age_male_15'] ) )
      const mAge64Cnt = d3.sum(rdata.map( row => row['age_male_20'] + row['age_male_25'] + row['age_male_30']
                                         + row['age_male_35'] + row['age_male_40'] + row['age_male_45']
                                         + row['age_male_50'] + row['age_male_55'] + row['age_male_60']
                  ) )
      const mAge65Cnt = d3.sum(rdata.map( row => row['age_male_65'] + row['age_male_70'] + row['age_male_75']
                                         + row['age_male_80'] + row['age_male_85'] + row['age_male_90']
                                         + row['age_male_95'] + row['age_male_100'] + row['age_male_105']
                  ) )

      const fAge14Cnt = d3.sum(rdata.map( row => row['age_female_5'] + row['age_female_10'] + row['age_female_15'] ) )
      const fAge64Cnt = d3.sum(rdata.map( row => row['age_female_20'] + row['age_female_25'] + row['age_female_30']
                                         + row['age_female_35'] + row['age_female_40'] + row['age_female_45']
                                         + row['age_female_50'] + row['age_female_55'] + row['age_female_60']
                  ) )
      const fAge65Cnt = d3.sum(rdata.map( row => row['age_female_65'] + row['age_female_70'] + row['age_female_75']
                                         + row['age_female_80'] + row['age_female_85'] + row['age_female_90']
                                         + row['age_female_95'] + row['age_female_100'] + row['age_female_105']
                  ) )

    const chartdata = []
      chartdata.push({ gender: "male",   age14: mAge14Cnt, age64:mAge64Cnt, age65:mAge65Cnt})
      chartdata.push({ gender: "female", age14: fAge14Cnt, age64:fAge64Cnt, age65:fAge65Cnt})
      chartdata["columns"] = ["gender", "age14", "age64", "age65"]

    return( chartdata )
  }

  const drawGenderRateChart = ( features ) => {

    const rdata = Array.from(features.values())

    var chartdata = []

    chartdata.push({ gender: "male",   "population" : d3.sum(rdata.map( row => row['age_male_total'  ])) });
    chartdata.push({ gender: "female", "population" : d3.sum(rdata.map( row => row['age_female_total'])) });
    chartdata["columns"] = ["gender", "population" ]

    return( chartdata )
  }


  const handleSelectionChange = function(features, center) {

    const sel = newSelectionObject(features, center);
    setSelection(sel);

    // population pyramid
    var chartdata = drawKrpopChart( sel.featPop )
    setKrpop( chartdata );

    // population tendency
    chartdata = null
    chartdata = drawKrpopTrendChart( sel.featPoptrend )
    setPopTimelines( chartdata );

    // key age group
    chartdata = null
    chartdata = drawKrpopKeyageChart( sel.featPop )
    setKeyAgePop( chartdata );

    // gender rate
    chartdata = null
    chartdata = drawGenderRateChart( sel.featPop )
    setGenderRate(chartdata)

  }

  const newSelectionObject = function(features, latlng) {
    const sel = {
      center: latlng.toArray(),
      featPop: new Map(features.population),
      featPoptrend: new Map(features.poptrend),
      dateCreated: Date.now(),
    };
    return sel;
  };

  useEffect(() => {
  }, []);


  const handleClose = (value) => {
    setOpen(false);
  };

  const handleApply = (contents) => {
    setOpen(false);

  };


  return (

    <>
      <Sidebar />
      <Navbar />


      <div className="relative md:ml-64 bg-gray-200">
        <div className="flex" style={{padding: '16px 16px 8px 16px' }}>
          <div className="relative" style={{height: "480px", width: "75%", marginRight: '6px', marginBottom: '-30px'}}>

            <MBMap
              selection={selection}
              onSelectionChange={handleSelectionChange}
            />

          </div>
          <div className="flex" style={{width: "25%", paddingLeft: '1px' }}>

              <Card>
                <ChartContainer
                  component={Pie}
                  jsonString={genderRate}
                  id={chartlist[3].id}
                  title={chartlist[3].title}
                  wparam={config.pie.wparam}
                />
              </Card>
          </div>
        </div>
        <div className="flex-auto w-full xl:w-4/4" style={{padding: '8px 16px 8px 16px' }}>
          <div>
            <CopyDialog open={open} onClose={handleClose} onApply={handleApply} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Card w="40%" h="360px" flex="auto">
                <ChartContainer
                  component={Pyramid}
                  jsonString={krpop}
                  id={chartlist[1].id}
                  title={chartlist[1].title}
                  wparam={config.pyramid.wparam}
                />
              </Card>

              <Card w="35%" h="360px" flex="auto">
                <ChartContainer
                  component={Mline}
                  jsonString={popTimelines}
                  id={chartlist[4].id}
                  title={chartlist[4].title}
                  wparam={config.mline.wparam}
                />
              </Card>

              <Card w="25%" h="360px" flex="auto">
                <ChartContainer
                  component={GroupedBar}
                  jsonString={keyAgePop}
                  id={chartlist[2].id}
                  title={chartlist[2].title}
                  wparam={config.gbar.wparam}
                  transpose
                />
              </Card>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
