import { saveSvgAsPng, svgAsPngUri } from 'save-svg-as-png';
import { parse } from 'json2csv';
import fileDownload from 'js-file-download';
import JSZip from 'jszip';

export const generateCSVBlob = (data, options) => {
  const csvString = parse(data, { withBOM: true });

  return URL.createObjectURL(
    new Blob([csvString], {
      type: 'text/csv; charset=utf-8',
    }),
  );
};

export const exportToPNG = (element, filename) => {
  saveSvgAsPng(element, `${filename}.png`, {
    scale: 2,
    // encoderType: 'image/jpg',
    backgroundColor: 'white',
  });
};

export const exportToCSV = (data, filename) => {
  const csvString = parse(data, { withBOM: true });
  // const url = URL.createObjectURL(
  //   new Blob([csvString], {
  //     type: 'text/csv; charset=utf-8',
  //   }),
  // );
  fileDownload(csvString, `${filename}.csv`);
};

export const exportAllToZIP = async (element) => {
  const zip = new JSZip();
  const svgs = element.getElementsByClassName('chart');
  let svg, img;
  for (let i = 0; i < svgs.length; i++) {
    svg = svgs[i];
    img = await svgAsPngUri(svg, {
      scale: 2,
      backgroundColor: 'white',
    });
    zip.file(`${i}.png`, img.split(',', 2)[1], { base64: true });
  }
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    fileDownload(content, 'example.zip');
  });
};
