import * as d3 from 'd3';

const heights = [480, 360]

export const pie = {
  wparam : {
    width : 480,
    height: heights[0],
  }
};

export const pyramid = {
  wparam : {
    width : 720,
    height: heights[1],
  }
};

export const mline = {
  wparam : {
    width : 540,
    height: heights[1],
  }
};

export const gbar = {
  wparam : {
    width : 580,
    height: 480,
    units: '(단위:명)'
  }
};
