import * as d3 from 'd3';
import omit from 'lodash/omit';
import map from 'lodash/map';
import pbar from 'shared/pbar';
import water from 'shared/watermark';
import { Auth } from 'aws-amplify';

export const arrayToCSV = (arr) => {
  if (!Array.isArray(arr))
    throw new TypeError('Input data is not an array.', 'helpers.js', 3);

  const fieldNames = Object.keys(arr[0]).join(',');
  const values = arr.map((d) => Object.values(d).join(',')).join('\n');
  const csvString = fieldNames + '\n' + values;

  return csvString;
};

export const getArray = (o, type) => {
  switch (type) {
    case 'network':
      return o.nodes;
    case 'radar':
      return o.points;
    default:
      return o;
  }
};

export const polygonPath = (radius, sides) => {
  return d3
    .radialLine()
    .angle((d, i) => (i / sides) * 2 * Math.PI)
    .curve(d3.curveLinearClosed)
    .radius(() => radius)(Array.from({ length: sides }));
};

export function parseTransposed(tsvString) {
  const array = d3.transpose(d3.tsvParseRows(tsvString));
  const string = array.map((row) => row.join('\t')).join('\n');
  return d3.tsvParse(string, d3.autoType);
}

export const parseDataString = (string, transpose) =>
  transpose ? parseTransposed(string) : d3.tsvParse(string, d3.autoType);

export function flattenObjArray(objArray, groupKey, provideId = false) {
  if (!groupKey) throw 'Invalid group key.';

  let id = 0;

  return objArray
    .map((d) => {
      const group = d[groupKey];
      const values = omit(d, [groupKey]);
      const entries = map(values, (v, k) => {
        const entry = {};
        entry.group = group;
        entry.axis = k;
        entry.value = v;
        if (provideId) entry.id = id++;
        return entry;
      });
      return entries;
    })
    .flat();
}

export const createColorScale = (colors, domain, firstColor, lastColor) => {
  const middle = colors.slice(
    0,
    domain.length - (firstColor ? 1 : 0) - (lastColor ? 1 : 0),
  );
  const scheme = [firstColor, ...middle, lastColor].filter((d) => !!d);
  return d3.scaleOrdinal().domain(domain).range(scheme);
};

export const progressBar = {
    show : (ref, width, height, msg) => {
           d3.select(ref.current)
             .style('display', 'block')
             .call(pbar, {
                 msg: msg,
                 width: width,
                 height:height,
              })
    },
    hide : (ref) => {
           d3.select(ref.current)
             .style('display', 'none')

    }
};

export const watermark = {
    show : (ref, width, height, msg) => {
            if( !Auth.user ) {
              d3.select(ref.current)
                .style('display', 'block')
                .call(water, {
                    msg: msg,
                    width: width,
                    height:height,
                 })
            }
    },
    hide : (ref) => {
           d3.select(ref.current)
             .style('display', 'none')

    }
};

