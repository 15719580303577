import { popScheme } from './styles';

export const grid = {
  id: 'grid',
  type: 'line',
  source: 'chickeninfo',
  'source-layer': 'krpop',

  paint: {
    'line-color': 'rgba(0,0,0,0.3)',
    'line-width': 0.5,
  },
//  filter: ['==', 'year', '2010'],
};


export const cellSelected = {
  id: 'cell-selected',
  type: 'fill',
  source: 'chickeninfo',
  'source-layer': 'krpop',

  paint: {
    'fill-outline-color': '#484896',
    'fill-color': 'red',
    'fill-opacity': 0.50,
  },
 filter: ['in', 'key_code', '' ]
};


//
// to display selections on map
//
export const popHighlighted = {
  id: 'population-highlighted',
  type: 'fill',
  source: 'chickeninfo',
  'source-layer': 'krpop',

  paint: {
    'fill-outline-color': '#484896',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'age_both_total'],
      ...popScheme.expr,
    ],
    'fill-opacity': 0.75,
  },

  filter: ['in', 'key_code', '' ]
};

//
// for population pyramid chart
//
export const population = {
  id: 'population',
  type: 'fill',
  source: 'chickeninfo',
  'source-layer': 'krpop',

  paint: {
    'fill-outline-color': '#484896',
    'fill-opacity': 0,
  },
//  filter: ['==', 'year', '2018'],
};

export const popTimeseries = {
  id: 'popTimeseries',
  type: 'fill',
  source: 'chickeninfo',
  'source-layer': 'krpoptrend',

  paint: {
    'fill-outline-color': '#484896',
    'fill-opacity': 0,
  },
//  filter: ['==', 'year', ''],
};

export const cvs = {
  id: 'cvs',
  type: 'fill',
  source: 'chickeninfo',
  'source-layer': 'krretailcvs',
  paint: {
    'fill-outline-color': '#484896',
    'fill-color': 'red',
    'fill-opacity': 0.50,
  },
 filter: ['in', 'key_code', '' ] 
};

export const classinfo = {
  id: 'classinfo',
  type: 'fill',
  source: 'chickeninfo',
  'source-layer': 'krretailcvsclass2',
  paint: {
    'fill-outline-color': '#484896',
    'fill-color': 'red',
    'fill-opacity': 0,
  },
// filter: ['in', 'key_code', '' ]
};

