/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:11b75c1f-c0ba-43ec-ba3a-19e3c29b4496",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_6rpl40VHF",
    "aws_user_pools_web_client_id": "2kt76668ko3i4pgse78cnajo6b",
    "oauth": {}
};


export default awsmobile;
