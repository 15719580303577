import React, { useEffect } from "react";
/**
import { withAuthenticator } from 'aws-amplify-react'
import { Auth } from 'aws-amplify';
**/
import Navbar from "components/Navbar.js";


import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Sidebar from "components/Sidebar.js";

import MBMap from 'components/Map/kr/CvsMap';
import * as d3 from 'd3';

// core components

import TextField from '@material-ui/core/TextField';
import ChartContainer from "components/ChartContainer";
import TableContainer from "components/Table/TableContainer";
import Barwide from "components/Chart/BarWide";
import Pyramid from "components/Chart/Pyramid";
import Mline from "components/Chart/Mline";
import Radar from "components/Chart/Radar";
import SmallbizData from "components/Table/Smallbiz";

import Card from "components/ChartCard";
import * as dummy from "shared/dummy";
import * as Config from "config";
import * as config from "./config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  column_25: {
    flexBasis: '25.0%',
  },
  column_30: {
    flexBasis: '30.0%',
  },
  column_35: {
    flexBasis: '35.0%',
  },

  column_65: {
    flexBasis: '65.0%',
  },
  column_70: {
    flexBasis: '70.0%',
  },
  column_75: {
    flexBasis: '75.0%',
  },

  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));


//export default function Convenience() {
function Convenience() {

    const cfg = Object.assign({}, Config, config);
    const population = {

      data: [
        { agegroup : "0-4",    agenmMale: "age_male_5",   agenmFemale: "age_female_5",   male: 0, female: 0 },
        { agegroup : "5-9",    agenmMale: "age_male_10",  agenmFemale: "age_female_10",  male: 0, female: 0 },
        { agegroup : "10-14",  agenmMale: "age_male_15",  agenmFemale: "age_female_15",  male: 0, female: 0 },
        { agegroup : "15-19",  agenmMale: "age_male_20",  agenmFemale: "age_female_20",  male: 0, female: 0 },
        { agegroup : "20-24",  agenmMale: "age_male_25",  agenmFemale: "age_female_25",  male: 0, female: 0 },
        { agegroup : "25-29",  agenmMale: "age_male_30",  agenmFemale: "age_female_30",  male: 0, female: 0 },
        { agegroup : "30-34",  agenmMale: "age_male_35",  agenmFemale: "age_female_35",  male: 0, female: 0 },
        { agegroup : "36-39",  agenmMale: "age_male_40",  agenmFemale: "age_female_40",  male: 0, female: 0 },
        { agegroup : "40-44",  agenmMale: "age_male_45",  agenmFemale: "age_female_45",  male: 0, female: 0 },
        { agegroup : "45-49",  agenmMale: "age_male_50",  agenmFemale: "age_female_50",  male: 0, female: 0 },
        { agegroup : "50-54",  agenmMale: "age_male_55",  agenmFemale: "age_female_55",  male: 0, female: 0 },
        { agegroup : "55-59",  agenmMale: "age_male_60",  agenmFemale: "age_female_60",  male: 0, female: 0 },
        { agegroup : "60-64",  agenmMale: "age_male_65",  agenmFemale: "age_female_65",  male: 0, female: 0 },
        { agegroup : "65-69",  agenmMale: "age_male_70",  agenmFemale: "age_female_70",  male: 0, female: 0 },
        { agegroup : "70-74",  agenmMale: "age_male_75",  agenmFemale: "age_female_75",  male: 0, female: 0 },
        { agegroup : "75-79",  agenmMale: "age_male_80",  agenmFemale: "age_female_80",  male: 0, female: 0 },
        { agegroup : "80-84",  agenmMale: "age_male_85",  agenmFemale: "age_female_85",  male: 0, female: 0 },
        { agegroup : "85-89",  agenmMale: "age_male_90",  agenmFemale: "age_female_90",  male: 0, female: 0 },
        { agegroup : "90-94",  agenmMale: "age_male_95",  agenmFemale: "age_female_95",  male: 0, female: 0 },
        { agegroup : "95-100", agenmMale: "age_male_100", agenmFemale: "age_female_100", male: 0, female: 0 },
        { agegroup : "100+",   agenmMale: "age_male_105", agenmFemale: "age_female_105", male: 0, female: 0 },
      ],

      init : () => {
        population.data.map( it => {
          it.male = 0;
          it.female = 0;
          return 0
        })
      },
    }

  // default data those need to be initialized every time (static dummy data are defined in shared/dummy.js)
  const def = {

//    seoul:{lng:"127.024612", lat: "37.532600", zoom:11},
    cardValues : {
      noc: 0,		// noc: Number Of Cells
      cellCvs: 0,	// 셀당 편의점 갯수
      cellPop: 0,	// 셀당 평균 인구
      popRate: 0,	// 점포 1개당 인구
    }
  }
  const classes = useStyles();
  const chartlist = [
    { id: "_dummy",             title: "",                      },
    { id: "_class3InTarget",    title: "유관 업종(종합소매점) 현황" },
    { id: "_popInTarget",       title: "인구분포"               },
    { id: "_ptrendInTarget",    title: "인구변동"               },
    { id: "_htypeInTarget",     title: "주거지형태"             },
    { id: "_class3InSelection", title: "유관 업종(종합소매점) 현황" },
    { id: "_popInSelection",    title: "인구분포"               },
    { id: "_ptrendInSelection", title: "인구변동"               },
    { id: "_htypeInSelection",  title: "주거지형태"             },
  ];

  const [selection, setSelection ] = React.useState(null);

  // 해당 상권 챠트 데이터
  const [popInTarget,    setPopInTarget]    = React.useState(null);
  const [ptrendInTarget, setPtrendInTarget] = React.useState(null);
  const [class3InTarget, setClass3InTarget] = React.useState(null);
  const [htypeInTarget,  setHtypeInTarget ] = React.useState(null);
  const [keycodesInTarget, setKeycodesInTarget]       = React.useState();

  // 선택 지역  챠트 데이터
  const [popInSelection,    setPopInSelection]    = React.useState(null);
  const [ptrendInSelection, setPtrendInSelection] = React.useState(null);
  const [class3InSelection, setClass3InSelection] = React.useState(null);
  const [htypeInSelection,  setHtypeInSelection ] = React.useState(null);
  const [keycodesInSelection, setKeycodesInSelection]       = React.useState();
//  const [htypeInSelection,  setHtypeInSelection ] = React.useState(config.htype);

  const [results,       setResults]       = React.useState(dummy.defTblData);
  const [storeList,     setStoreList]     = React.useState(null);
  const [paramPop,      setParamPop]      = React.useState(3000);
  const [paramMin,      setParamMin]      = React.useState(5);
  const [paramComp,     setParamComp]     = React.useState(0);
  const [selectedPop,   setSelectedPop]   = React.useState(3000);
  const [selectedMin,   setSelectedMin]   = React.useState(5);
  const [selectedComp,  setSelectedComp]   = React.useState(0);



  const [cardValues,    setCardValues]    = React.useState(def.cardValues);

  const [slistTitle ,   setSlistTitle]    = React.useState("");
  const [data ,   setData]    = React.useState({});
  const [keyCodes ,   setKeyCodes]    = React.useState([]);
  const [mapTitle ,   setMapTitle]    = React.useState("상권 검색 결과(전국)");


  const handleMapLoad = function( key ) {
    setKeyCodes( key );
    setMapTitle( "상권 검색 결과(지도 표시 지역)" );
  }

  const handleSelectionChange = function(features, center, selectionType) {


    const funcs = [
      { 
        class3Func: setClass3InSelection,  
        popFunc   : setPopInSelection,  
        ptrendFunc: setPtrendInSelection,  
        dataFunc  : setKeycodesInSelection,
        htypeFunc : setHtypeInSelection
      },
      {  
        class3Func: setClass3InTarget,    
        popFunc   : setPopInTarget,    
        ptrendFunc: setPtrendInTarget,    
        dataFunc  : setKeycodesInTarget,
        htypeFunc : setHtypeInTarget
      }
    ]
    const sel = newSelectionObject(features, center);
    setSelection(sel);

    const keycodes =  Array.from( sel.featCvs.keys() )

    const idx = selectionType === 'SELECTION' ? 0 : 1     // 0: SELECTION, 1: TARGET

    // 데이터 설정
    funcs[idx].dataFunc(keycodes)

    // 유관업종 챠트
    setStoreList(null);    // reset data table
    var chartdata = drawClass3Chart( sel.featCvs, "종합소매점" )
    funcs[idx].class3Func(chartdata)

    // 인구 피라미드 챠트
    chartdata = null
    chartdata = drawKrpopChart( sel.featPop )
    funcs[idx].popFunc(chartdata)

    // 인구 변동 챠트
    chartdata = null
    chartdata = drawKrpopTrendChart( sel.featPoptrend )
    funcs[idx].ptrendFunc(chartdata)

    // 주거형태
    chartdata = null
    chartdata = drawKrHousetypeChart( sel.featHtype )
    funcs[idx].htypeFunc(chartdata)
  }

  const newSelectionObject = function(features, latlng) {
    const sel = {
      center: latlng.toArray(),
      featPop: new Map(features.population),
      featPoptrend: new Map(features.poptrend),
      featCvs: new Map(features.cvsclass2),
      featHtype: new Map(features.housetype),
      dateCreated: Date.now(),
    };

    return sel;
  };

  const search = () => {
    
    setSelectedPop(paramPop)
    setSelectedMin(paramMin)
    setSelectedComp(paramComp)

  }

  const updateCards = ( data ) => {
    if( !data || data.length == 0 ) {
      setResults(dummy.defTblData);
      const cardValues = {
        noc:     0,          // noc: Number Of Cells
        cellCvs: 0,   // 셀당 편의점 갯수
        cellPop: 0,   // 셀당 평균 인구
        popRate: 0    // 점포 1개당 인구
      }
      setCardValues( cardValues )

      return;
    }
    
    const cvslist = []
    cvslist['columns'] = theaderCvs(Object.keys(data[0]))
    cvslist['rows'] = data

    setResults(cvslist)
    const noc = cvslist.rows.length
    const cellCvs =  d3.sum(cvslist.rows.map( (d) => d.nocvs ) ) / noc
    const cellPop =  d3.sum(cvslist.rows.map( (d) => d.pop ) ) / noc
    const popRate =  d3.sum(cvslist.rows.map( (d) => d.rate ) ) / noc

    const cardValues = {
      noc:     cvslist.rows.length,          // noc: Number Of Cells
      cellCvs: cellCvs.toFixed(2),   // 셀당 편의점 갯수
      cellPop: cellPop.toFixed(2),   // 셀당 평균 인구
      popRate: popRate.toFixed(2)    // 점포 1개당 인구
    }
    setCardValues( cardValues )
  }

  useEffect(() => {
    async function fetchData(population, minDistance, keycodes ) {
  
      await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/cvslist`, {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
        },
        body: JSON.stringify({pop: population, min: minDistance, keyCodes: keycodes })
      })
      .then(response => { return response.json() } )
      .then(data => {
         data = data.map( (d,i) => { d.pop = parseInt(d.pop);
                                     d.nocvs = parseInt(d.nocvs);
                                     d.rate = parseInt(d.rate);
                                     return( Object.assign({'id': i+1}, d))
                        })

         updateCards( data )
         setData(data);

      });
    }

    fetchData(selectedPop, selectedMin, keyCodes );

  }, [selectedPop, selectedMin, keyCodes ]);

  const theaderCvs = (header) => {

    const format = [
      {'hid': 'id',       headerName: 'Cell Id',       'hide': false, 'width': 100,  'type': 'string' },
      {'hid': 'pop',      headerName: '인구.',         'hide': false, 'width': 90,  'type': 'number' },
      {'hid': 'nocvs',    headerName: '기존 편의점수', 'hide': false, 'width': 160, 'type': 'number' },
      {'hid': 'rate',     headerName: '점포당 인구',   'hide': false, 'width': 140, 'type': 'number' },
      {'hid': 'key_code', headerName: 'key_code',      'hide': true,  'width': 180,  'type': 'string' },
      {'hid': 'lng',      headerName: '경도',          'hide': true,  'width': 120, 'type': 'string' },
      {'hid': 'lat',      headerName: '위도',          'hide': true,  'width': 120, 'type': 'string' }
    ]

    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i];
      const item = format.find( it => it.hid === hid )
        columns.push( {
          'field': hid,
          'hide': item ? item.hide : false,
          'headerName': item ? item.headerName : '',
          'width': item ? item.width : 80,
          'type': item ? item.type : 'string',
          'valueGetter': item ? item.valueGetter : ''
        })
    }

    return( columns )
  }

  async function fetchClass3Data( keycodes, chartdata, classNm) {

    const stores = []
    await fetch(`${cfg.aws.ec2.url}:${cfg.aws.ec2.port.be}/api/sbizInClass3`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({'key_codes': keycodes, 'class3': classNm })
    })
    .then(response => { return response.json() } )
    .then(data => {
       data = data.map( (d,i) => {return( Object.assign({'id': i+1}, d)) })

       stores['columns'] = theaderStores(Object.keys(data[0]))
       stores['rows'] = data

       setStoreList(stores)
    });
  }

  const theaderStores = (header) => {

    const format = [
          {'hid': 'id',        'headerName': 'No.',          'hide': false, 
                               'resizable': false,           'width': 60,  'type': 'string'},
          {'hid': 'key_code',  'headerName': 'KEY_CODE',     'hide': true, 
                               'resizable': false,           'width': 140, 'type': 'string'},
          {'hid': 'shop_nm',   'headerName': '상호명',       'hide': false, 
                               'resizable': false,           'width': 400, 'type': 'string'},
          {'hid': 'branch_nm', 'headerName': '지점명',       'hide': false, 
                               'resizable': false,           'width': 200, 'type': 'string'},
          {'hid': 'sic_nm',    'headerName': '표준산업분류', 'hide': false, 
                               'resizable': false,           'width': 200, 'type': 'string'},
          {'hid': 'biztype',   'headerName': '상권업종분류', 'hide': false, 
                               'resizable': false,           'width': 300, 'type': 'string'},
          {'hid': 'oaddr',     'headerName': '지번주소',     'hide': false, 
                               'resizable': false,           'width': 400, 'type': 'string'},
          {'hid': 'naddr',     'headerName': '도로명주소',   'hide': false, 
                               'resizable': false,           'width': 400, 'type': 'string'},
          {'hid': 'bldg_nm',   'headerName': '건물정보',     'hide': false, 
                               'resizable': false,           'width': 200, 'type': 'string'},
          {'hid': 'floor_no',  'headerName': '층정보',       'hide': false, 
                               'resizable': false,           'width': 80, 'type': 'number'},
          {'hid': 'unit_no',   'headerName': '호정보',       'hide': false, 
                               'resizable': false,           'width': 80, 'type': 'number'},
    ]

    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i]
      const item = format.find( it => it.hid === hid )
        columns.push( {
          'field': hid,
          'hide': item ? item.hide : false,
          'headerName': item ? item.headerName : '',
          'width': item ? item.width : 80,
          'type': item ? item.type : 'string',
          'valueGetter': item ? item.valueGetter : ''
        })
    }

    return( columns )
  }

  const drawKrHousetypeChart = ( features ) => {

    var nosAgg = new Map();

    const rdata = Array.from(features.values())

    rdata.map( (d) => {

      const [...fields] = Object.keys(d);

      fields.forEach(field => {
/**
        if( field !== 'year' && field !== 'key_code' && field !== 'proptype' ){
          const accum = nosAgg.get( field ) ? nosAgg.get( field ) : 0;
          nosAgg.set( field, accum + d[field] );
        }
**/
        if( field === 'num_ho_single' || field === 'num_ho_multi' || field === 'num_ho_semi' ) {
          const accum = nosAgg.get( 'num_house' ) ? nosAgg.get( 'num_house' ) : 0;
          nosAgg.set( 'num_house', accum + d[field] );
        }
        else if( field === 'num_th_small' || field === 'num_th_large' ||  field === 'num_etc'  ) {
          const accum = nosAgg.get( 'num_etc' ) ? nosAgg.get( 'num_etc' ) : 0;
          nosAgg.set( 'num_etc', accum + d[field] );
        }
        else if( field === 'num_apt' ) {
          const accum = nosAgg.get( 'num_apt' ) ? nosAgg.get( 'num_apt' ) : 0;
          nosAgg.set( 'num_apt', accum + d[field] );
        }

      })

      return 0
    })

    const chartdata = []
//    nosAgg.forEach( (value, key) => {
//console.log( key );
//console.log( value );
      chartdata.push({ name: "htype", '단독주택': nosAgg.get( 'num_house' ), 
                                      '아파트':   nosAgg.get( 'num_apt'   ), 
                                      '다세대/빌라/기타':   nosAgg.get( 'num_etc'   ) 
                    })
//    })
    chartdata["columns"] = ["name", "num_house", "num_apt", "num_etc" ];

    return( chartdata )
  }


  const drawKrpopChart = ( features ) => {

    population.init()

    const rdata = Array.from(features.values())

    rdata.map( row => {
      /* eslint-disable no-unused-vars */
      const [year, keyCode, ...fields] = Object.keys(row);
      /* eslint-enable no-unused-vars */

      fields.forEach(field => {
        if( field.includes("female") ) {
          const item = population.data.find( it => it.agenmFemale === field)
          if( item ) { item.female += row[field] }
        }
        else if( field.includes("male") ) {
          const item = population.data.find( it => it.agenmMale === field)
          if( item ) item.male += row[field]
        }
      })
      return 0
    });

    const chartdata = []
    population.data.map( row => {
      chartdata.push({ group: row.agegroup, male: row.male, female: row.female });
      return 0
    });
    chartdata["columns"] = ["group", "male", "female"]
    return( chartdata )
  }

  const drawKrpopTrendChart = ( features ) => {

    const rdata = Array.from(features.values())

    var ages = [];
    var years = [];
    rdata.map( d => {

      ages = Object.keys(d).map( key => {
             return( key.substring(0,3) )
           })

          .filter( (item, pos, self) => {
             return( item !== "key" && item !== "pro" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })

      years =  Object.keys(d).map( key => {
             return( key.substring(4,8) )
           })

          .filter( (item, pos, self) => {
             return( item !== "code" && item !== "type" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })
      return 0
    });

    const matrix = new Array(3).fill(0).map( () => new Array(0).fill(0));

    var chartdata = []
    for( var i = 0; i < ages.length; i++ ) {
      for( var j = 0; j < years.length; j++ ) {
        const idx = `${ages[i]}y${years[j]}`;
        matrix[i][j] = {year: years[j], population: d3.sum(rdata.map( row => row[idx]) ) }
      }
      chartdata.push( {name: ages[i], values: matrix[i]})
    }

    return( chartdata )
  }

  const drawClass3Chart = (features, class2Nm) => {
    var nosAgg = new Map();

    const rdata = Array.from(features.values())

    rdata.map( (d) => {
      for( var i = 0; i < d.length; i++ ) {
        if( d[i].class2_nm === class2Nm ) {
          const accum = nosAgg.get( d[i].class3_nm ) ? nosAgg.get( d[i].class3_nm ) : 0
          nosAgg.set( d[i].class3_nm, accum + d[i].cnt );
        }
      }
      return 0
    })
    const chartdata = []
    nosAgg.forEach( (value, key) => {
      chartdata.push({ name: key, nos: value})
    })
    chartdata["columns"] = ["name", "nos"]

    return( chartdata )
  }


  const handleRowClick = ( param ) => {
  }

  const handleChartClick = (chartId, class3Selected) => {

    switch( chartId ) {
      case '_class3InSelection':
            setSlistTitle( "주변정보 > " + class3Selected);
            fetchClass3Data(keycodesInSelection, class3InSelection, class3Selected);
            break;
      case '_class3InTarget':
            setSlistTitle( "해당상권 > " + class3Selected);
            fetchClass3Data(keycodesInTarget, class3InTarget, class3Selected);
            break;
      default :
            break;
    }
  };

  return (
    <>
      <Sidebar />
      <Navbar />

      <div className="relative md:ml-64 bg-gray-200">
        <div className="relative md:pt-4 pb-4 pt-6">
          <div className="px-4 mx-auto w-full" style={{padding: '0px 0px 0px 0px' }}>

            <Accordion default style={{margin: '-15px 16px 8px 16px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column_30}>
                  <Typography className={classes.heading}>편의점 입지 검색 조건</Typography>
                </div>
                <div className={classes.column_70}>
                  <Typography 
                    className={classes.secondaryHeading}>도보 {selectedMin}분이내 거리에 {selectedPop}명 이상의 인구가 거주하는 상권을 표시 중입니다.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.column_70} 
                     style={{display: 'flex', backgroundColor:'white', alignItems:'center'}}>

                  <Typography className={classes.secondaryHeading} >
                    도보 
                  </Typography>
      
                  <TextField
                    className={classes.input}
                    defaultValue="5"
                    type="number"
                    onChange={ (event) => {setParamMin(event.target.value) }}
                    inputProps={{ 'aria-label': 'search google maps', step: 5, min: 5, max: 30,
                                  style:{color:'red', textAlign: 'right'}}}
                  />
      
                  <Typography className={classes.secondaryHeading} >
                    분 이내 거리에 
                  </Typography>
                  <TextField
                    className={classes.input}
                    defaultValue="3000"
                    type="number"
                    onChange={ (event) => {setParamPop(event.target.value) }}
                    inputProps={{ 'aria-label': 'search google maps', step: 1000, min: 1000, max: 10000,
                                  style:{color:'red', textAlign: 'right'}}}
                  /> 
                  <Typography className={classes.secondaryHeading} >
                    명 이상의 인구가 거주하는 셀
                  </Typography>
                  <AccordionActions>
                    <Button variant="outlined" size="small" color="primary" 
                            onClick={ () => {search()}}> 재검색 </Button>
                  </AccordionActions>
                </div>
              </AccordionDetails>
            </Accordion>
      
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-gray-500 uppercase font-bold text-xs">
                          검색결과
                        </h5>
                        <span className="font-semibold text-xl text-gray-800">
                          {cardValues.noc}개 셀
                        </span>
                      </div>
                      <div className="relative w-auto pl-4 flex-initial">
                        <div className="text-white p-3 text-center inline-flex items-center justify-center 
                             w-12 h-12 shadow-lg rounded-full bg-blue-500">
                          <i className="fab fa-buromobelexperte"></i>
                        </div>
                      </div>
                    </div>
                    <p className="text-sm text-gray-500 mt-4">
                      <span className="whitespace-no-wrap">
                        도보 {selectedMin}분, {selectedPop}명 이상
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-gray-500 uppercase font-bold text-xs">
                          평균 편의점 수
                        </h5>
                        <span className="font-semibold text-xl text-gray-800">
                          {cardValues.cellCvs}개
                        </span>
                      </div>
                      <div className="relative w-auto pl-4 flex-initial">
                        <div className="text-white p-3 text-center inline-flex items-center justify-center 
                             w-12 h-12 shadow-lg rounded-full bg-red-500">
                          <i className="fas fa-store"></i>
                        </div>
                      </div>
                    </div>
                      <p className="text-sm text-gray-500 mt-4">
                      <span className="whitespace-no-wrap">
                        셀 평균 현존 편의점 수
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-gray-500 uppercase font-bold text-xs">
                          평균 인구
                        </h5>
                        <span className="font-semibold text-xl text-gray-800">
                          {cardValues.cellPop}명
                        </span>
                      </div>
                      <div className="relative w-auto pl-4 flex-initial">
                        <div className="text-white p-3 text-center inline-flex items-center justify-center 
                             w-12 h-12 shadow-lg rounded-full bg-red-500">
                          <i className="fas fa-users"></i>
                        </div>
                      </div>
                    </div>
                      <p className="text-sm text-gray-500 mt-4">
                      <span className="whitespace-no-wrap">
                        셀 평균 인구
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-gray-500 uppercase font-bold text-xs">
                          커버리지
                        </h5>
                        <span className="font-semibold text-xl text-gray-800">
                          {cardValues.popRate}명
                        </span>
                      </div>
                      <div className="relative w-auto pl-4 flex-initial">
                        <div className="text-white p-3 text-center inline-flex items-center justify-center 
                             w-12 h-12 shadow-lg rounded-full bg-orange-500">
                          <i className="fas fa-umbrella"></i>
                        </div>
                      </div>
                    </div>
                    <p className="text-sm text-gray-500 mt-4">
                      <span className="whitespace-no-wrap">
                        편의점 1개당 평균 인구
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Accordion defaultExpanded style={{height: "auto", margin: '8px 16px 8px 16px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column_30}>
                  <Typography className={classes.heading}>검색 결과</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className="relative" style={{height: "480px", width: "100%",  marginBottom: "-30px"  }}>
                  <MBMap
                    searchResults={results}
                    selection={selection}
                    onSelectionChange={handleSelectionChange}
                    onGmapLoad={handleMapLoad}
                  />
                </div>

              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded style={{height: "auto", margin: '8px 16px 8px 16px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column_30}>
                  <Typography className={classes.heading}>해당 상권</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className="flex" style={{height: "100%", width: "100%",  marginBottom: "0px"  }}>
                  <Card w="24%" h="360px" flex="auto">
                    <ChartContainer
                      component={Barwide}
                      jsonString={class3InTarget}
                      id={chartlist[1].id}
                      title={chartlist[1].title}
                      wparam={config.class3.wparam}
                      onChartClick={handleChartClick}
                    />
                  </Card>
                  <Card w="30%" h="360px" flex="auto">
                    <ChartContainer
                      component={Pyramid}
                      jsonString={popInTarget}
                      id={chartlist[2].id}
                      title={chartlist[2].title}
                      wparam={config.pyramid.wparam}
                    />
                  </Card>
                  <Card w="23%" h="360px" flex="auto">
                    <ChartContainer
                      component={Mline}
                      jsonString={ptrendInTarget}
                      id={chartlist[3].id}
                      title={chartlist[3].title}
                      wparam={config.mline.wparam}

                    />
                  </Card>

                  <Card w="23%" h="360px" flex="auto">
                    <ChartContainer
                      component={Radar}
                      jsonString={htypeInTarget}
                      id={chartlist[4].id}
                      title={chartlist[4].title}
                      wparam={config.radar3.wparam}

                    />
                  </Card>

                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded style={{height: "auto", margin: '8px 16px 8px 16px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column_30}>
                  <Typography className={classes.heading}>주변 정보</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className="flex" style={{height: "100%", width: "100%",  marginBottom: "0px"  }}>
                  <Card w="24%" h="360px" flex="auto">
                    <ChartContainer
                      component={Barwide}
                      jsonString={class3InSelection}
                      id={chartlist[5].id}
                      title={chartlist[5].title}
                      wparam={config.class3.wparam}
                      onChartClick={handleChartClick}
                    />
                  </Card>
                  <Card w="30%" h="360px" flex="auto">
                    <ChartContainer
                      component={Pyramid}
                      jsonString={popInSelection}
                      id={chartlist[6].id}
                      title={chartlist[6].title}
                      wparam={config.pyramid.wparam}
                    />
                  </Card>
                  <Card w="23%" h="360px" flex="auto">
                    <ChartContainer
                      component={Mline}
                      jsonString={ptrendInSelection}
                      id={chartlist[7].id}
                      title={chartlist[7].title}
                      wparam={config.mline.wparam}
                    />
                  </Card>

                  <Card w="23%" h="360px" flex="auto">
                    <ChartContainer
                      component={Radar}
                      jsonString={htypeInSelection}
                      id={chartlist[8].id}
                      title={chartlist[8].title}
                      wparam={config.radar3.wparam}

                    />
                  </Card>

                </div>
              </AccordionDetails>
            </Accordion>

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: '0px 16px 8px 16px' }} >
              <Card w="100%" h="auto" flex="auto">
{/**
                      jsonString={class3InSelection}
                <SmallbizData
                  id="SmallbizList"
                  callback={handleRowClick}
                  data={storeList}
                />
**/}
                    <TableContainer
                      component={SmallbizData}
                      id="SmallbizList"
                      callback={handleRowClick}
                      title={slistTitle}
                      data={storeList}
                    />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
//export default withAuthenticator(Convenience, true);
export default Convenience;

