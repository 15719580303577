//import * as d3 from 'd3';

export default function legend(element, options) {
  const color = options.color;
  const maxWidth = options.maxWidth || Infinity;
//  const maxHeight = options.maxHeight || null;
  const swatchWidth = options.swatchWidth || 11;
  const paddingInner = options.paddingInner || 5;
  const paddingOuter = options.paddingOuter || 30;
//  const lineHeight = 1.2;
  const fontSize = options.fontSize || 12;

  const data = color.domain();

  // Draw symbols.
  const swatch = element
    .selectAll('.swatch')
    .data(data)
    .join('rect')
    .attr('class', 'swatch')
    .attr('width', swatchWidth)
    .attr('height', swatchWidth)
    .attr('fill', color);

  const label = element
    .selectAll('.label')
    .data(data)
    .join('text')
    .attr('class', 'label')
    .attr('x', swatchWidth + paddingInner)
    .attr('y', swatchWidth - 1)
    .text((d) => d)
    .style('font-size', fontSize);

  const textWidths = [];
  let textHeight = fontSize;
  //let line = 0;

  label.nodes().map((node) => {
    textWidths.push(node.getComputedTextLength());
    return 0
  });

  let maxX = 0;
  let pos = [{ x: 0, y: 0 }];
  textWidths.reduce(
    (acc, d, i) => {
      let { x, y } = acc;
      x += d + paddingInner + paddingOuter;
      if (x > maxWidth) {
        y += textHeight;
        x = 0;
      }
      maxX = Math.max(maxX, x);
      acc = { x, y };
      pos.push(acc);
      return acc;
    },
    { x: 0, y: 0 },
  );

  const xOffset = 0.5 * (maxWidth - maxX);

  swatch.attr('x', (d, i) => pos[i].x + xOffset);
  swatch.attr('y', (d, i) => pos[i].y);
  label.attr('x', (d, i) => pos[i].x + paddingInner + swatchWidth + xOffset);
  label.attr('y', (d, i) => pos[i].y + swatchWidth - 1);

  console.time();
//  console.log(xOffset);
//  console.timeEnd();
}
