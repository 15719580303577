import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import {
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';

/* Icons **/
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import CropFreeIcon from '@material-ui/icons/CropFree';
import DeleteIcon from '@material-ui/icons/Delete';
import BookmarkIcon from '@material-ui/icons/Bookmark';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: 'auto',
    backgroundColor: 'white',
    position: 'absolute',
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexDirection: 'column',
  },
  grouped: {
    margin: theme.spacing(0),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  groupedSizeSmall: {
    padding: '0px',
    width: '30px',
    height: '30px',
  },
}))(ToggleButtonGroup);

const StyledButtonGroup = withStyles(theme => ({
  grouped: {
    minWidth: '30px',
  },
}))(ButtonGroup);

const StyledButton = withStyles(theme => ({
  sizeSmall: {
    padding: '0px',
    width: '30px',
    height: '30px',
  },
}))(Button);

export default function MapToolbar(props) {
  const [tool, setTool] = React.useState('select');
//  const [action, setAction] = React.useState('new');

/**
  const handleActionChange = (event, newAction) => {
    setAction(newAction);
    props.onActionChange(newAction);
  };
**/

  const handleToolChange = (event, newTool) => {
    setTool(newTool);
    props.onToolChange(newTool);
  };

  const options = [
    'New selection',
    'Add to selection',
    'Remove from selection',
  ];

  const actions = ['new', 'add', 'remove'];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleBookmarkAdd = () => {
    props.onBookmarkAdd();
  };

/**
  const handleClick = () => {
    // props.onActionChange(action);
  };
**/

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    props.onActionChange(actions[index]);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          value={tool}
          exclusive
          onChange={handleToolChange}
          aria-label="current tool"
        >
          <ToggleButton value="select" aria-label="select">
            <CropFreeIcon />
          </ToggleButton>
          <ToggleButton value="pan" aria-label="pan">
            <ControlCameraIcon />
          </ToggleButton>
          <ToggleButton value="clearAll" aria-label="clear all">
            <DeleteIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
        {/* <Divider orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup
          size="small"
          value={action}
          exclusive
          onChange={handleActionChange}
          aria-label="default action"
        >
          <ToggleButton value="new" aria-label="new selection">
            <CropIcon />
          </ToggleButton>
          <ToggleButton value="add" aria-label="add to selection">
            <AddIcon />
          </ToggleButton>
          <ToggleButton value="remove" aria-label="remove from selection">
            <RemoveIcon />
          </ToggleButton>
        </StyledToggleButtonGroup> */}
        <Divider orientation="vertical" className={classes.divider} />
        <StyledButtonGroup orientation="vertical">
          <StyledButton size="small" onClick={handleBookmarkAdd}>
            <BookmarkIcon />
          </StyledButton>
          <StyledButton size="small" onClick={handleToggle} ref={anchorRef}>
            <ArrowDropDownIcon />
          </StyledButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="right-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </StyledButtonGroup>
      </Paper>
    </div>
  );
}
