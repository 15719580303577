import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Amplify, { Auth } from 'aws-amplify';
import aws_exports from 'aws-exports';

import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "views/Landing";
import Dashboard from "views/Dashboard.js";
import MapViewJpn from "views/MapViewJpn/MapView.js";
import Population from "views/Population/Population.js";
import Smallbiz from "views/Smallbiz/Smallbiz.js";
import Convenience from "views/Location/Convenience/Convenience";
import Chicken from "views/Location/Chicken/Chicken.js";

Amplify.configure(aws_exports);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/landing"    component={Landing} />
      <Route path="/population" component={Population} />
      <Route path="/smallbiz"   component={Smallbiz} />
      <Route path="/cvs"        component={Convenience} />
      <Route path="/chicken"    component={Chicken} />
      <Route path="/dashboard"  component={Dashboard} />
      <Route path="/jmap"       component={MapViewJpn} />
      <Route path="/"             component={Landing} />

{/**
      <Redirect from="/" to="/landing" />
**/}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
