import * as d3 from 'd3';

const heights = [360]

export const class3 = {
  wparam : {
    width : 520,
    height: heights[0],
    units:'(단위:개)',
    numFormat:',',
  },
};

export const pyramid = {
  wparam : {
    width : 720,
    height: heights[0],
  }
};

export const mline = {
  wparam : {
    width : 540,
    height: heights[0],
  }
};





