import React from 'react';
import * as d3 from 'd3';

import legend from 'shared/legend';
import { progressBar, watermark } from 'shared/helpers';
import './Mline.scss';

import { mline as defaults, strings } from 'shared/defaults';

const Mline = React.forwardRef(({ id, data, wparam }, ref) => {

/**
  if( typeof(data) === 'undefined' || typeof(data.length) === 'undefined' ) 
  {
    console.log( "111" );
    return;
  }
  else
  {
    console.log( "222" );
  }
**/
  //
  // configuration data
  //

  //  const flat = data
  const cfg = Object.assign({}, defaults, strings, wparam);

  const w       = cfg.width,
        h       = cfg.height,
        mtop    = cfg.margin.top,
        mleft   = cfg.margin.left,
        mbottom = cfg.margin.bottom,
        mright  = cfg.margin.right,
        gutter  = cfg.legendGutter;


  const bounded = {
    width: w - mleft - mright,
    height: h - mbottom - mtop - gutter,
  };

  var duration = 250;

  var lineOpacity = "0.50";
  var lineOpacityHover = "0.85";
  var otherLinesOpacityHover = "0.1";
  var lineStroke = "1.5px";
  var lineStrokeHover = "2.5px";

  var circleOpacity = '0.85';
  var circleOpacityOnLineHover = "0.25"
  var circleRadius = 3;
  var circleRadiusHover = 6;

  //
  // define Refs
  //
//  const titleRef = React.useRef(null);
//  const unitsRef = React.useRef(null);
  const bodyRef  = React.useRef(null);
//  const xAxisRef = React.useRef(null);
//  const yAxisRef = React.useRef(null);
  const pbarRef  = React.useRef(null);
  const legendRef = React.useRef(null);
  const watermarkRef = React.useRef(null);

  const draw = () => {


  /* Format Data */
  var parseDate = d3.timeParse("%Y");
  data.forEach(function(d) { 
    d.values.forEach(function(d) {
      d.year = parseDate(d.year);
      d.population = +d.population;    
    });
  });


  /* Scale */
  var xScale = d3.scaleTime()
    .domain(d3.extent(data[0].values, d => d.year))
    .range([0, bounded.width]);

  var yScale = d3.scaleLinear()
    .domain([0, d3.max(data[1].values, d => d.population)])
    .range([bounded.height, 0]);

  var color = d3.scaleOrdinal(d3.schemeCategory10);


  /* Add line into SVG */
  var line = d3.line()
    .x(d => xScale(d.year))
    .y(d => yScale(d.population));

  var svg = d3.select(bodyRef.current)
//    .selectAll('.line-group')
    .selectAll('*')
    .remove()

  svg = d3.select(bodyRef.current)
    .attr("transform", `translate(${0}, ${0})`)

  let lines = svg
    .append('g')

  const ageLabels = { 'a14': '0 - 14 ', 'a64': '15 - 64', 'a65':'65+' }

  svg.exit()
     .transition().duration(500).remove();

  lines.selectAll('.line-group')
    .data(data).enter()
    .append('g')
    .attr('class', 'line-group')  
    .on("mouseover", function(d, i) {
        svg.append("text")
        .attr("class", "title-text")
        .style("fill", color(i))        
        .text(ageLabels[d.name])
        .attr("text-anchor", "middle")
        .attr("x", (bounded.width)/2)
        .attr("y", 5);
      })
    .on("mouseout", function(d) {
        svg.select(".title-text").remove();
      })

    .append('path')
    .attr('class', 'line')  
    .attr('d', d => line(d.values))
    .style('stroke', (d, i) => color(i))
    .style('opacity', lineOpacity)
    .on("mouseover", function(d) {
      d3.selectAll('.line')
        .style('opacity', otherLinesOpacityHover);
      d3.selectAll('.circle')
        .style('opacity', circleOpacityOnLineHover);
      d3.select(this)
        .style('opacity', lineOpacityHover)
        .style("stroke-width", lineStrokeHover)
        .style("cursor", "pointer");
    })
    .on("mouseout", function(d) {
      d3.selectAll(".line")
        .style('opacity', lineOpacity);
      d3.selectAll('.circle')
        .style('opacity', circleOpacity);
      d3.select(this)
        .style("stroke-width", lineStroke)
        .style("cursor", "none");
    })


    /* Add circles in the line */
  lines.selectAll("circle-group")
    .data(data).enter()
    .append("g")
    .style("fill", (d, i) => color(i))
    .selectAll("circle")
    .data(d => d.values).enter()
    .append("g")
    .attr("class", "circle")  
    .on("mouseover", function(d) {
      d3.select(this)     
        .style("cursor", "pointer")
        .append("text")
        .attr("class", "text")
        .text(`${d.population}`)
        .attr("x", d => xScale(d.year) + 5)
        .attr("y", d => yScale(d.population) - 10);
    })
    .on("mouseout", function(d) {
      d3.select(this)
        .style("cursor", "none")  
        .transition()
        .duration(duration)
        .selectAll(".text").remove();
    })
    .append("circle")
    .attr("cx", d => xScale(d.year))
    .attr("cy", d => yScale(d.population))
    .attr("r", circleRadius)
    .style('opacity', circleOpacity)
    .on("mouseover", function(d) {
      d3.select(this)
        .transition()
        .duration(duration)
        .attr("r", circleRadiusHover);
      })
    .on("mouseout", function(d) {
      d3.select(this) 
        .transition()
        .duration(duration)
        .attr("r", circleRadius);  
      })

  /* Add Axis into SVG */
  var xAxis = d3.axisBottom(xScale).ticks(5);
  var yAxis = d3.axisLeft(yScale).ticks(5)

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", `translate(0, ${bounded.height})`)
    .call(xAxis);

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append('text')
    .attr("y", 15)
    .attr("transform", "rotate(-90)")
    .attr("fill", "#000")
    .text("단위: 명")
      .transition()
      .on("end", (d, i) => {
            progressBar.hide(pbarRef)
            watermark.show(watermarkRef, cfg.width, cfg.height, cfg.watermark );
       })


    // Draw the legend.

    color = d3.scaleOrdinal(cfg.colors).domain(['0 - 14 ', '15 - 64', '65+'])
    if (cfg.useLegend) {

      d3.select(legendRef.current).call(legend, {
        color,
        maxWidth: bounded.width,
      });

    }

  }

  React.useEffect(() => {
    try {

      const numrows = d3.sum(data, (d) => { return d.values[0].population; })
      if( data && data.length > 0 && numrows > 0 ) {

          draw();
      }
      else {

        // show progress bar
        progressBar.show(pbarRef, cfg.width, cfg.height, cfg.norec );
        watermark.hide(watermarkRef);

        d3.select(bodyRef.current) .selectAll("*") .remove()
        d3.select(legendRef.current) .selectAll("*") .remove()
      }

    } catch (error) {
      alert(`Invalid data entered for chart id. ` + error );
    }
  }, [data, cfg, draw]);

  return (


    <svg
      ref={ref}
      className="chart"
      viewBox={`0 0 ${cfg.width} ${cfg.height}`}
      style={{ margin: 'auto', display: 'block', fontSize: cfg.fontSize }}
    >

      <g ref={pbarRef} transform={`translate(0,0)`}/>
      <g ref={watermarkRef} transform={`translate(0,0)`}/>

      <g transform={`translate(${cfg.margin.left}, ${cfg.margin.top})`}>
{/**
        <text
          ref={titleRef}
          transform={`translate(${bounded.width / 2}, ${cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize * 1.5, textAnchor: 'middle' }}
        />
        <text
          ref={unitsRef}
          transform={`translate(${-4 * cfg.fontSize}, ${-cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize, textAnchor: 'right' }}
        />
**/}
        <g ref={bodyRef} />

        <g className="bar"
          ref={legendRef}
          transform={`translate(${cfg.margin.left}, ${bounded.height + cfg.legendGutter})`}
        />


{/**
        <g
          ref={xAxisRef}
          transform={`translate(${0}, ${bounded.height})`}
          style={{ fontSize: cfg.fontSize }}
        />
        <g ref={yAxisRef} style={{ fontSize: cfg.fontSize }} />
**/}
      </g>
    </svg>

  );
});

export default Mline;
