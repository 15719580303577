import { populationScheme } from './styles';


export const grid = {
  id: 'grid',
  type: 'line',
  source: 'population',
  'source-layer': 'krpop',

  paint: {
    'line-color': 'rgba(0,0,0,0.3)',
    'line-width': 0.5,
  },
//  filter: ['==', 'year', '2010'],
};

//
// to display selections on map
//
export const popHighlighted = {
  id: 'population-highlighted',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpop',

  paint: {
    'fill-outline-color': '#484896',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'age_both_total'],
      ...populationScheme.expr,
    ],
    'fill-opacity': 0.75,
  },

  filter: ['in', 'key_code', '' ]
};


//
// for population pyramid chart
//
export const population = {
  id: 'population',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpop',

  paint: {
    'fill-outline-color': '#484896',
    'fill-opacity': 0,
  },
//  filter: ['==', 'year', '2018'],
};



/**
export const gridLayer = {
  id: 'class1',
  type: 'line',
  source: 'retail',
  'source-layer': 'class1',
  paint: {
    // 'fill-outline-color': 'rgba(0,0,0,0.1)',
    // 'fill-color': 'rgba(0,0,0,0)',
    'line-color': 'rgba(0,0,0,0.1)',
    'line-width': 1,
  },
  //   filter: ['!', ['has', 't000876001']],
};
**/

export const krPopLayer = {
  id: 'krPopLayer',
  type: 'line',
  source: 'population',
//  source: 'retail',
  'source-layer': 'krpop',
  paint: {
    'line-color': 'rgba(0,0,0,0.1)',
    'line-width': 1,
  },
//  filter: ['==', 'year', '2010'],
};

export const krPopTrendLayer = {
  id: 'krPopTrendLayer',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpoptrend',

  paint: {
    'fill-outline-color': '#484896',
/**
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'age_dummy_total'],
      ...populationScheme.expr,
    ],
**/
    'fill-opacity': 0,
  }

//  filter: ['==', 'year', '2010'],
};

/**
export const pointLayer = {
  id: 'raw',
  source: 'retail',
  type: 'circle',
  'source-layer': 'raw',

  paint: {
    'circle-radius': 3,
    'circle-color': '#007cbf'
  },

};
**/

/**
export const hangmapLayer = {
  id: 'hangmap',
  type: 'fill',
  source: 'hangmap',
  paint: {
     'fill-color': [
        'let',
        'density',
        ['/', ['get', 'adm_cd2'], ['get', 'sq-km']],
        [
            'interpolate',
            ['linear'],
            ['zoom'],
            8,
            [
               'interpolate',
               ['linear'],
               ['var', 'density'],
               274,
//               ['to-color', '#edf8e9'],
               ['to-color', '#ec3614'],
               1551,
               ['to-color', '#ec3614']
//               ['to-color', '#006d2c']
            ],
            10,
            [
               'interpolate',
               ['linear'],
               ['var', 'density'],
               274,
//               ['to-color', '#eff3ff'],
               ['to-color', '#ec3614'],
               1551,
//               ['to-color', '#08519c']
               ['to-color', '#ec3614']
            ]
         ]
      ],
      'fill-color': '#ec3614',
      'fill-opacity': 0.1
   }
};
**/



export const highlightedGridLayer = {
  id: 'grid-highlighted',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpop',
  paint: {
    'fill-outline-color': '#484896',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'age_both_total'],
      ...populationScheme.expr,
    ],
    'fill-opacity': 0.75,
  },
  filter: ['in', 'key_code', ''],
};

export const clusterGridLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'clustergrid',
  'source-layer': 'krpop',
  filter: ['has', 'age_both_total'],
  paint: {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    // with three steps to implement three types of circles:
    //   * Blue, 20px circles when point count is less than 100
    //   * Yellow, 30px circles when point count is between 100 and 750
    //   * Pink, 40px circles when point count is greater than or equal to 750
    'circle-color': [
      'step',
      ['get', 'age_both_total'],
      '#51bbd6',
      100,
      '#f1f075',
      750,
      '#f28cb1',
    ],
    'circle-radius': ['step', ['get', 'age_both_total'], 20, 100, 30, 750, 40],
  },
};

export const clusterGridCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'clustergrid',
  'source-layer': 'krpop',
  filter: ['has', 'age_both_total'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
};

// export const clusterCountLayer = {
//   id: 'cluster-count',
//   type: 'symbol',
//   source: 'earthquakes',
//   filter: ['has', 'point_count'],
//   layout: {
//     'text-field': '{point_count_abbreviated}',
//     'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
//     'text-size': 12,
//   },
// };

// export const unclusteredPointLayer = {
//   id: 'unclustered-point',
//   type: 'circle',
//   source: 'earthquakes',
//   filter: ['!', ['has', 'point_count']],
//   paint: {
//     'circle-color': '#11b4da',
//     'circle-radius': 4,
//     'circle-stroke-width': 1,
//     'circle-stroke-color': '#fff',
//   },
// };
