import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { exportToPNG, exportToCSV } from 'shared/exports';

const ChartMenu = ({
  svgElement,
  id,
  title,
  data,
  visible,
  handleVisClick,
  handleEditClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <Typography variant="h6" component="h6" style={{ marginLeft: 20 }}>
        {title}
      </Typography>

      <div style={{ flexGrow: 1 }} />
{/*
      <IconButton onClick={() => handleVisClick(!visible)}>
        {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
      <IconButton onClick={() => handleEditClick({ title })}>
        <EditIcon />
      </IconButton>
*/}
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="chart-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            exportToPNG(svgElement.current, `${id}`);
            handleClose();
          }}
        >
          Save as PNG
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportToCSV(data, `${id}`);
            handleClose();
          }}
        >
          Save as CSV
        </MenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(ChartMenu);
