import React from 'react';
 
// nodejs library that concatenates classes
//import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import Dialog from '@material-ui/core/Dialog';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// core components
//import styles from "./CopyDialogJss.js";
//const useStyles = makeStyles(styles);

export default function CopyDialog(props) {
//  const classes = useStyles();
  /* eslint-disable no-unused-vars */
  const { className, children, onClose, onApply, open, ...rest } = props;
  /* eslint-enable no-unused-vars */
//  const { className, children, onClose, onApply, open } = props;

  const [chartData, setChartData] = React.useState("");

/**
  const copyDialogClasses = classNames({
    [classes.copyDialog]: true,
    [classes.open]: open,
    [classes.onClose]: onClose,
    [classes.onApply]: onApply,
    [className]: className !== undefined
  });
**/

  const handleClose = () => {
    onClose();
  };

  const handleClickApply = () => {
    onApply( chartData );
  }

  const setTextValue =(e) => {
    setChartData( e.target.value );
  }

  return (
    <Dialog onClose={handleClose} fullWidth={true} maxWidth="sm" open={open}>
        <TextField onChange={setTextValue} variant="outlined" multiline rows={10}/>
        <Button variant="outlined" color="primary" onClick={handleClickApply}>
          Apply
       </Button>
    </Dialog>
  );

}

CopyDialog.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node
};
