import React from "react";
import Button from '@material-ui/core/Button';


import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Landing() {

  return (
    <>
      <Navbar />

      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "75vh"
            }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover" >
            <span id="blackOverlay" className="w-full h-full absolute opacity-85 bg-black"></span>
          </div>
          <div className="container relative mx-auto">
                    <h1 className="text-white font-semibold text-5xl">
                      격자형 통계 지도 서비스
                    </h1>
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-10/12 px-4 ml-auto mr-auto text-center">
                  <div className="pr-12">
                    <h1 className="text-white font-semibold text-2xl">
                      &nbsp;
                    </h1>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                    격자형 통계지도는 우리나라 전체를 약250m 간격의 격자로 나누어 각 격자안에 위치하는 자료를 손쉽게 활용할 수 있도록 한 지도 서비스 입니다. 
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                    격자형 통계를 사용하면 시.군.구로 구분하는 행정구역별 통계로는 하기 힘든 다음과 같은 분야에서의 활용이 가능합니다.
                    </p>
                    <p className="font-bold mt-8 text-lg text-gray-400 text-left">
                    민간영역
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    행정구역 구분에 따른 인위적인 경계의 영향을 받지 않아 상권분석, 상가업소 입지 조건 분석을 보다 정확하게 할 수 있습니다. 
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>상가업소 출점 최적지 조사(예: 편의점, 치킨전문점 등)</li>
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>소매업 상권 분석</li>
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>250m 단위의 세분화된 지역정보에 기반한 타겟 마케팅</li>
                    </p>
                    <p className="font-bold mt-8 text-lg text-gray-400 text-left">
                    지방자치단체
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>인구규모에 따른 도시계획 및 지역개발 계획의 기초자료로 활용</li>
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>재해시의 피해범위 예측 및 대피소 운영 계획의 기초자료</li>
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>인구 변동에 따른 대중교통 노선 조정의 기초자료</li>
                    </p>
                    <p className="font-bold mt-8 text-lg text-gray-400 text-left">
                    국가기관
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>원전사고, 지진 등 중대 재해에 대비한 광역 대피소 설치 및 운영 관리를 위한 기초자료로 활용</li>
                    </p>
                    <p className="mt-4 ml-8 text-lg text-gray-500 text-left">
                    <li>전국단위 국토개발계획 관련 업무에서의 기초자료로 활용</li>
                    </p>

{/**
                    <p className="mt-4 text-lg text-gray-500 text-left">


알기 어려운...
                    격자형 통계를 사용하면 지리적, 인구적 특성에 따른 비교분석이 가능하기 때문에 시.군.구로 나뉘는 행정구역별 통계로는 알기 어려운...
행정구역별 통계에서 
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                    현재 전국의 인구정보와 상가업소 정보를 제공하고 있으며 향후 부동산 정보 등을 추가하여 보다 충실한 정보 검색이 되도록...
                    </p>

                    <p className="mt-4 text-lg text-gray-500 text-left">
격자단위의 통계는 모든 지역을 같은 크기와 형태로 나누기 때문에 지역간의 특성을 비교하기 수월합니다.
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
또한 행정구역의 변동 또는 행정구역간 경계의 조정에 영향을 받지 않기 때문에 시간의 흐름에 따른 제약 없이 지역간 비교는 물론 동일 지역에서의 시계열적 특성 변화를을 손쉽고 정확하게 비교분석 하는 것이 가능합니다. 
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
격자형 통계의 이러한 특성 때문에 시.군.동과 같은 행정구역의 구분의 필요 없는 다음과 같은 분야에서 효과적으로 활용할 수 있습니다.
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                    ㅇ공공영역
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                    &nbsp;&nbsp;&nbsp; - 인구 분포에 따른 도시계획 및 지역개발 계획 수립
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                    &nbsp;&nbsp;&nbsp; - 인구 규모에 따른 재해 대피 장소 선정에 활용
                    </p>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                    &nbsp;&nbsp;&nbsp; - 미세먼지등의 환경오염요소에 대한 구체적 대책 수립
                    </p>
**/}

{/**
                    <p className="mt-4 text-lg text-gray-500 text-left">
행정구역 기반으로 되어 있는 기존의 지역정보를 격자형 통계로 재구성 하는데에 있어서의 기술적 어려움과 재구성 과정에서 생기는 피할 수 없는 오차를 최소화 하기 위하
However, there are some technical difficulties in compiling the Grid Square Statistics. Because the Grid Squares do not correspond with the tabulated small areas such as basic unit blocks or enumeration districts. The Grid Squares are demarcated by the longitude and latitude. On the other hand, the tabulated small areas are usually demarcated by geographical feature such as road, river, railway and so on. Therefore, the compilation of the Grid Squares is quite time-consuming and needs a lot of labor to maintain the accuracy of the data.
                    </p>

                    <p className="mt-4 text-lg text-gray-500 text-left">
The Statistics Bureau has taken various countermeasures for a long time to improve the compilation work of the Grid Squares.
                    </p>


                    <p className="mt-4 text-lg text-gray-500 text-left">
Thus, the Grid Square Statistics are used in both public and private sector.
                    </p>
**/}

                  </div>
                </div>

              </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">

            </div>


            <div className="flex flex-wrap items-center mt-32">
{/**
                    <p className="mt-4 text-lg text-black-500 text-left">
                    격자통계에 대한 이해를 돕기 위하여 구체적인 몇가지 사용 사례를 아래에 예시해 두었으며 저희가 제공하는 오픈소스를 활용하여 용도에 맞게 커스터마이징 하여 사용하실 수 있습니다. 
                    </p>
**/}
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  인구통계
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  선택한 지역의 연령대별 인구분포, 인구변동 추이 등을 시각화 하여 제공합니다. 
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  조사지역의 고령화 경향 조사등의 학술적 목적이나 인구밀도, 연령분포에 따른 상권특성을 이해해는 데 유용하게 활용할 수 있습니다. 
                </p>
                <p className="text-normal font-light leading-relaxed mt-4 text-blue-700">
                  <a href="/population" className="font-bold" >
                    이동하기
                  </a>
                </p>
              </div>

              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <blockquote className="relative p-0 mb-0"> </blockquote>
                  <img
                    alt="..."
                    src={require("assets/img/hp-population.png")}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-0 mb-0"> </blockquote>
                </div>
              </div>

            </div>
          </div>
        </section>

{/**
===== 1
**/}
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/hp-smallbiz.png")}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    상가업소 현황
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    조사지역에 분포하는 상가업소를 한국 표준산업분류에 따라 분류하였기 때문에 특정 지역의 산업적 특성을 이해하는 데 활용하면 좋습니다.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            8개 대표 업종의 분포 현황
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">세부 업종별 현황 </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">선택 업종의 상가업소 상세 정보 조회</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <p className="text-normal font-light leading-relaxed mt-4 text-blue-700">
                  <a href="/smallbiz" className="font-bold" >
                    이동하기
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>


{/**
===== 2
**/}
        <section className="relative bg-gray-300 py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-blue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-300">
                    <i className="fas fa-store text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    상권조사 예시 
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    상가업소 출점시 가장 우선적으로 고려되는 주변의 인구현황과 경쟁업체 현황을 손쉽게 파악할 수 있습니다.
                  </p>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    또한 인위적인 행정구역 구분을 제거하여 상권에 대한 정확한 이해가 가능하도록 하였습니다.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <h4 className="text-blue-600">
                            <a href="/cvs" className="font-bold mt-8" > 이동하기 </a>
                          </h4>
{/**
                      <button
                        className="bg-gray-800 text-white text-sm  px-6 py-3 rounded mr-1 mb-1" type="button"
                        style={{ transition: "all .15s ease" }} 
                      >
                      이동하기
                      </button>

                      <button
                        className="bg-gray-800 text-white text-sm  px-6 py-3 rounded mr-1 mb-1" type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                      사용 가이드 보기
                      </button>
**/}

                        </div>
                      </div>
                    </li>
{/**
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                            <a href="/chicken" className="font-bold mt-8" > 
                              <h4 className="text-gray-600">치킨 전문점 입지조건 조사</h4>
                            </a>
                        </div>
                      </div>
                    </li>
**/}
                  </ul>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    편의점 출점 최적지 선정에 대한 자세한 내용은
                    <a href="https://ubi-biz.github.io/codelabs/gmaps-user-guide-cvs/index.html" className="text-blue-600" > 여기</a>
                    에서 확인할 수 있습니다.
                  </p>
                </div>
              </div>

              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/hp-cvs.png")}
                />
              </div>

            </div>
          </div>
        </section>

{/**
=====
**/}

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-20 lg:pb-20">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-10/12 px-4">
                <h2 className="text-center text-4xl font-semibold text-white">
                  제공 서비스
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  유비밈즈는 격자형 통계 데이터를 지속적으로 개발하여 "격자형 통계 지도 서비스"를 통하여 제공할 것입니다.
                </p>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  아래와 같은 특화 서비스를 통하여 보다 전문적으로 활용하시는 것도 가능합니다.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  데이터 사용
                </h6>
                <p className="mt-2 mb-4 text-gray-500">
                  유비밈즈의 인구정보, 상가업소정보를 API로 제공받아 사용하실 수 있습니다.
                </p>
                <p className="mt-2 mb-4 text-gray-500">
                  고객이 보유하고 있는  데이터와 융합하여 사용하는 것도 가능합니다.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  데이터 마이그레이션
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                  고객이 보유하고 있는 데이터를 격자통계 지도에서 활용할 수 있도록 변환하여 드립니다.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  격자형 통계 지도 구축
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                  격자형 통계 지도를 구축하여 드립니다. 
                </p>
                <p className="mt-2 mb-4 text-gray-500">
                  무료로 제공하는 오픈소스를 사용하여 직접 구축하실 수도 있습니다.
                </p>
              </div>
            </div>
          </div>
        </section>


{/**
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                  <div className="flex-auto p-5 lg:p-10">
<form action="mailto:sjhanjp@gmail.com" method="post" enctype="text/plain" >
                    <h4 className="text-2xl font-semibold">
                      각종문의
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                      문의사항을 보내 주시면 최대한 빨리 답변 드리겠습니다. 
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        이름
                      </label>
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="홍길동 팀장"
                        style={{ transition: "all .15s ease" }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        이메일
                      </label>
                      <input
                        type="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="email@email.com"
                        style={{ transition: "all .15s ease" }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        메시지
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="자체 보유데이터가 있는데 격자형 데이터로 변환하여 사용해 보고 십습니다. 관련하여 필요한 절차가 궁급합니다. "
                      />
                    </div>
                    <div className="text-center mt-6">

                    <Button variant="outlined" size="small" color="primary"
                            onClick={ () => {sendEmail()}}> Send </Button>


                      <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="submit"
                        style={{ transition: "all .15s ease" }}
                      >
                        Send 
                      </button>
                    </div>
</form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
**/}
      </main>
      <Footer />
    </>
  );
}
