import React from 'react';
import * as d3 from 'd3';
import legend from 'shared/legend';
import { flattenObjArray, createColorScale } from 'shared/helpers';
import { groupedBar as defaults, strings } from 'shared/defaults.js';
import { progressBar, watermark } from 'shared/helpers';


const GroupedBar = React.forwardRef(({ id, data, wparam }, ref) => {
  const cfg = Object.assign({}, defaults, strings, wparam);

  const w       = cfg.width,
        h       = cfg.height,
        mtop    = cfg.margin.top,
        mleft   = cfg.margin.left,
        mbottom = cfg.margin.bottom,
        mright  = cfg.margin.right,
        gutter  = cfg.legendGutter;


  const bounded = {
    width: w - mleft - mright - mright,
    height: h - mbottom - mtop - gutter,
  };


/**
  const bounded = {
    width: cfg.width - cfg.margin.left - cfg.margin.right,
    height: cfg.height - cfg.margin.bottom - cfg.margin.top - cfg.legendGutter,
  };
**/
  const bodyRef      = React.useRef(null);
  const titleRef     = React.useRef(null);
  const unitsRef     = React.useRef(null);
  const xAxisRef     = React.useRef(null);
  const yAxisRef     = React.useRef(null);
  const legendRef    = React.useRef(null);
  const pbarRef      = React.useRef(null);
  const watermarkRef = React.useRef(null);



  const draw = () => {
    const body = d3.select(bodyRef.current);
    const xAxis = d3.select(xAxisRef.current);
    const yAxis = d3.select(yAxisRef.current);

    const groupColumn = data.columns[0];
    const axes = data.columns.slice(1);
    const groups = data.map((d) => d[groupColumn]);
    const flat = flattenObjArray(data, groupColumn);
    const maxValue = Math.max(
      cfg.maxValue || 0,
      d3.max(flat.map((d) => d.value)) * cfg.rangeFactor,
    );
    const color = createColorScale(
      cfg.colors,
      groups,
      cfg.firstColor,
      cfg.lastColor,
    );
    const labelFormat = cfg.formatValue;

    const byAxis = d3
      .nest()
      .key((d) => d.axis)
      .entries(flat);

    // Scales.
    const x0 = d3
      .scaleBand()
      .domain(axes)
      .rangeRound([0, bounded.width])
      .paddingInner(0.5)
      .paddingOuter(0.25);
    const x1 = d3
      .scaleBand()
      .domain(groups)
      .rangeRound([0, x0.bandwidth()])
      .paddingInner(0.33);
    const y = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([bounded.height, 0])
      .nice(cfg.numTicks);

    // Draw axes.
    xAxis.call(
      d3.axisBottom(x0).tickSizeInner(0).tickSizeOuter(0).tickPadding(10),
    );
    // xAxis.selectAll('.tick').select('line').remove();

    yAxis.call(
      d3
        .axisLeft(y)
        .tickSizeInner(0)
        .tickSizeOuter(0)
        .tickPadding(10)
        .tickFormat(labelFormat)
        .ticks(cfg.numTicks),
    );
    // yAxis.select('.domain').remove();

    // Draw slices.
    const slices = body
      .selectAll('.slice')
      .data(byAxis)
      .join('g')
      .attr('class', 'slice')
      .attr('transform', (d) => `translate(${x0(d.key)}, ${0})`)
      .style('fill', 'black');

    slices
      .selectAll('.bar')
      .data((d) => d.values)
      .join('rect')
      .attr('class', 'bar')
      .attr('width', x1.bandwidth())
      .attr('x', (d) => x1(d.group) )
      .transition()
      .on("end", (d, i) => {
         progressBar.hide(pbarRef)
         watermark.show(watermarkRef, cfg.width, cfg.height, cfg.watermark );
       })
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => bounded.height - y(d.value))
      .style('fill', (d) => color(d.group));

    // Draw value labels.
    slices
      .selectAll('.barValue')
      .data((d) => d.values)
      .join('text')
      .attr('class', 'barValue')
      .attr('x', (d) => x1(d.group) + x1.bandwidth() / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', cfg.fontSize)
      .text((d) => labelFormat(d.value))
      .style('fill', (d, i) => 'black')
      .transition()
      .attr('y', (d) => y(d.value) - 10);

    // Draw the legend.
/**
    d3.select(legendRef.current).call(legend, {
      color,
      maxWidth: bounded.width,
      fontSize: cfg.fontSize,
    });
**/

    // Draw title.
    d3.select(titleRef.current).text(cfg.title || null);

    // Draw the legend.
    if (cfg.useLegend) {
      d3.select(legendRef.current).call(legend, {
        color,
        maxWidth: bounded.width,
        fontSize: cfg.fontSize,
      });
    }

    if (cfg.units) {
      d3.select(unitsRef.current).text(cfg.units || null);
    }
  };

    React.useEffect(() => {
    try {

      const numrows = d3.sum(data, (d) => { return (d.age14 + d.age64 + d.age65) })

      if( data && data.length > 0 && numrows > 0 ) {
        draw();
      }
      else {
/**
        if( Object.keys(data).length === 0  ) {
          // show progress bar
          progressBar.show(pbarRef, cfg.width, cfg.height,
                           "no data selected" );
          watermark.hide(watermarkRef);

        } else {
          // show progress bar
          progressBar.show(pbarRef, cfg.width, cfg.height,
                           "no data available" );
          watermark.hide(watermarkRef);
        }
**/
        // show progress bar
        progressBar.show(pbarRef, cfg.width, cfg.height, cfg.norec );
        watermark.hide(watermarkRef);

        d3.select(bodyRef.current)  .selectAll("*") .remove()
        d3.select(xAxisRef.current) .selectAll("*") .remove()
        d3.select(yAxisRef.current) .selectAll("*") .remove()
        d3.select(unitsRef.current) .text( null )
        d3.select(legendRef.current).text( null )

      }

    } catch (error) {
      alert(`Invalid data entered for chart "${id}". `);
    }
  }, [data, cfg, draw]);

  return (
    <svg
      ref={ref}
      className="chart"
      viewBox={`0 0 ${cfg.width} ${cfg.height}`}
      style={{ margin: 'auto', display: 'block', fontSize: cfg.fontSize }}
    >

      <g ref={pbarRef} transform={`translate(0,0)`}/>
      <g ref={watermarkRef} transform={`translate(0,0)`}/>

      <g transform={`translate(${cfg.margin.left}, ${cfg.margin.top})`}>
        <text
          ref={titleRef}
          transform={`translate(${bounded.width / 2}, ${cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize * 1.5, textAnchor: 'middle' }}
        />
        <text
          ref={unitsRef}
          transform={`translate(${-3 * cfg.fontSize}, ${-cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize, textAnchor: 'left' }}
        />
        <g ref={bodyRef} />
        <g
          ref={xAxisRef}
          transform={`translate(${0}, ${bounded.height})`}
          style={{ fontSize: cfg.fontSize }}
        />
        <g ref={yAxisRef} style={{ fontSize: cfg.fontSize }} />
        <g
          ref={legendRef}
          transform={`translate(${0}, ${bounded.height + cfg.legendGutter})`}
        />
      </g>
    </svg>
  );
});

export default GroupedBar;
