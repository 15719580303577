import { schemeBlues } from 'd3';

export function createScaleExpr(thresholds, colors) {
  const expr = [];

  for (let i = 0; i < thresholds.length; i++) {
    expr.push(thresholds[i]);
    expr.push(colors[i]);
  }

  return {
    thresholds,
    colors,
    expr,
  };
}

export const populationScheme = createScaleExpr(
  [0, 250, 500, 750, 1000, 1250, 1500, 1750, 2000],
  schemeBlues[9],
);
