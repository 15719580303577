import React, { useEffect } from "react";
import { Auth } from 'aws-amplify';
import SigninDlg from 'components/SigninDlg/SigninDlg.js';
import CopyDialog from 'components/CopyDialog/CopyDialog.js';
import { withAuthenticator, Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings} from 'aws-amplify-react';
//import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

export default function Navbar(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('test@gmail.com');
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [username, setUsername] = React.useState();
  const [authState, setAuthState] = React.useState();

  useEffect( () => {

    if( Auth.user ) 
    {
      console.log( Auth.user.username );
      setUsername(Auth.user.username);
    }

  }, []);

  async function signOut() {
    let mounted = true
    try {
        await Auth.signOut()
             .then( () => {
                if(mounted) { 
                  setUsername('')
                }
             })
    } catch (error) {
        console.log('error signing out: ', error);
    }

    return function cleanup() {
      mounted = false
    }
  }

  const handleAuthStateChange = (state) => {
    if (state === 'signedIn') {
        /* Do something when the user has signed-in */
      setUsername( Auth.user.username )
    }
    else if (state === 'signIn') {
      setUsername( '' )
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    Auth.user ? setUsername( Auth.user.username ) : setUsername( '' )
  };

  const Greeting = () => {

    if( username  ) {
      return(
        <div>
            hello {Auth.user ? Auth.user.username : ''}
            <button
              className={
                    (props.transparent
                      ? "bg-white text-gray-800 active:bg-gray-100"
                      : "bg-pink-500 text-white active:bg-pink-600") +
                    " text-xs font-bold px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
              }
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={ () => {signOut(); } }
            >
              Sign Out
            </button>
        </div>
      ) 
    }
    else{
      return (
        <div>
            <button
              className={
                    (props.transparent
                      ? "bg-white text-gray-800 active:bg-gray-100"
                      : "bg-pink-500 text-white active:bg-pink-600") +
                    " text-xs font-bold px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
              }
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={ () => {handleClickOpen()}}
            >
              Sign In
            </button>
            <SigninDlg selectedValue={selectedValue} open={open} onClose={handleClose} />
        </div>
      )
    }
  }

  return (
    <>
      <nav
        className={
          ( props.transparent
            ? "top-0 absolute z-50 w-full"
            : "relative shadow-lg bg-white shadow-lg") +
          " flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
        }
      >
        <div className="w-full px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                " text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap "
              }
              href="/landing"
            >
              Ubimemes Research
            </a>
          </div>

          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Greeting/>
    <Authenticator hideDefault={true} onStateChange={handleAuthStateChange } />
{/**
    <AmplifyAuthenticator hideDefault={true} handleAuthStateChange={handleAuthStateChange} />
**/}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
