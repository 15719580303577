/** 3개년 매출액 추이 */

export const bar1 = `구분	2017	2018	2019
매출액	748	912	1303
`;

export const line1 = `구분	2017년	2018년	2019년
매출액	123	82	319
`;

export const gbar1 = `구분	동종산업	나인폴더스
매출액	232.94	130.3
`;
export const gbar2 = `구분	동종산업	나인폴더스
매출액	7.9	10.0
`;

export const gbar3 = `구분	A집단	B집단	나인폴더스
기술 개발비	347.3	288.7	319
`;

/** Rev vs R&D Percentages. */
export const gbar4 = `구분	A집단	B집단	나인폴더스
기술 개발비	8.45	13.56	24.48
`;

export const gbar5 = `구분	A집단	B집단	나인폴더스
특허권	3.9	3.3	2.0
실용신안권	0.7	0.6	0.0
디자인권	0.6	0.6	0
상표권	0.7	0.7	0
`;

export const bar2 = `구분	현재인원수	응답자수	유효응답수
응답결과	12	12	10
`;

export const radar1 = `구분	배려	개방성	학습	즐거움	결과	권위	안전	질서
1평균	 2.60 	 2.50 	 2.36 	 3.00 	 2.50 	 2.71 	 2.29 	 2.64 
2평균	 3.20 	 3.17 	 3.91 	 3.67 	 3.60 	 3.43 	 3.14 	 3.45 
3평균	 4.20 	 3.33 	 3.36 	 4.00 	 3.20 	 3.14 	 3.71 	 3.82 
4평균	 2.60 	 1.67 	 2.55 	 2.67 	 2.40 	 2.00 	 4.14 	 1.91 
5평균	 4.60 	 4.50 	 4.09 	 2.67 	 5.00 	 4.57 	 4.86 	 4.55 
6평균	 2.40 	 1.50 	 1.82 	 2.33 	 1.90 	 2.00 	 2.14 	 2.27 
7평균	 4.20 	 3.67 	 3.91 	 4.00 	 4.10 	 3.43 	 4.00 	 4.00 
8평균	 2.80 	 2.67 	 3.09 	 3.00 	 3.00 	 3.29 	 3.43 	 3.09 
9평균	 3.20 	 3.33 	 3.18 	 3.00 	 3.00 	 3.14 	 3.43 	 3.27 
10평균	 2.00 	 2.33 	 2.45 	 2.67 	 2.30 	 2.57 	 2.29 	 2.27 
11평균	 2.40 	 1.50 	 2.27 	 2.33 	 2.20 	 2.00 	 3.29 	 2.18 
12평균	 1.80 	 1.17 	 1.18 	 2.33 	 1.40 	 1.86 	 1.43 	 1.18 
13평균	 3.80 	 2.83 	 3.36 	 2.00 	 3.40 	 2.86 	 3.57 	 3.64 
14평균	 3.20 	 4.00 	 3.91 	 4.00 	 3.70 	 4.00 	 4.14 	 3.73 
15평균	 2.20 	 3.00 	 4.00 	 3.00 	 4.00 	 3.29 	 4.00 	 3.45 
16평균	 3.80 	 3.33 	 3.82 	 4.00 	 4.00 	 3.86 	 3.86 	 3.18 
17평균	 3.00 	 2.67 	 3.00 	 3.00 	 2.60 	 2.86 	 2.71 	 2.91 
18평균	 3.00 	 3.33 	 3.09 	 3.33 	 3.30 	 3.14 	 3.29 	 3.00 
19평균	 2.40 	 2.67 	 2.64 	 2.67 	 2.30 	 3.14 	 3.29 	 2.45 
20평균	 2.80 	 2.33 	 3.18 	 3.00 	 3.00 	 3.00 	 3.14 	 3.00 
21평균	 3.20 	 2.50 	 2.91 	 4.00 	 3.20 	 3.57 	 3.71 	 3.09 
22평균	 3.20 	 2.33 	 2.64 	 4.00 	 2.60 	 2.57 	 4.00 	 2.82 
23평균	 4.60 	 3.83 	 4.27 	 4.67 	 3.90 	 3.71 	 4.14 	 4.27 
24평균	 3.20 	 3.00 	 3.09 	 3.00 	 3.10 	 3.00 	 3.43 	 3.27 
25평균	 4.00 	 3.33 	 4.09 	 3.00 	 4.80 	 4.43 	 3.57 	 4.27 
26평균	 2.20 	 1.67 	 2.36 	 1.67 	 2.30 	 1.71 	 3.29 	 2.09 
27평균	 2.60 	 3.33 	 3.00 	 2.67 	 2.80 	 2.00 	 3.14 	 2.91 
28평균	 2.60 	 2.50 	 2.82 	 2.00 	 3.20 	 2.71 	 2.71 	 2.64 
29평균	 3.60 	 3.33 	 3.82 	 4.33 	 3.80 	 3.57 	 4.00 	 3.91 
30평균	 3.60 	 3.50 	 3.91 	 3.67 	 3.40 	 3.86 	 4.00 	 3.73 
31평균	 2.80 	 2.83 	 3.27 	 3.33 	 2.90 	 2.57 	 3.71 	 3.36 
32평균	 2.20 	 2.50 	 2.00 	 1.00 	 2.50 	 2.71 	 2.71 	 1.82 
33평균	 3.20 	 2.67 	 2.82 	 2.67 	 2.60 	 2.71 	 3.86 	 3.36 
34평균	 2.00 	 1.50 	 1.91 	 2.33 	 1.80 	 1.14 	 2.43 	 2.45 
35평균	 3.40 	 3.00 	 3.36 	 3.00 	 3.40 	 3.14 	 3.86 	 3.64 
36평균	 2.60 	 2.50 	 3.45 	 4.00 	 3.30 	 2.71 	 3.86 	 3.18 
37평균	 3.00 	 3.00 	 2.91 	 3.00 	 2.90 	 2.86 	 3.14 	 2.91 
38평균	 3.60 	 2.67 	 2.91 	 4.00 	 3.20 	 3.00 	 3.29 	 3.55 
39평균	 2.40 	 3.33 	 2.64 	 1.67 	 2.80 	 3.00 	 2.71 	 2.64 
40평균	 2.20 	 2.00 	 2.09 	 2.00 	 2.20 	 2.14 	 2.86 	 2.18 
41평균	 3.60 	 3.17 	 3.36 	 3.00 	 3.20 	 3.00 	 3.29 	 3.27 
42평균	 2.80 	 3.00 	 3.00 	 3.00 	 3.60 	 3.00 	 3.14 	 3.00 
43평균	 2.20 	 1.83 	 1.55 	 2.33 	 1.50 	 2.14 	 2.29 	 2.00 
44평균	 4.80 	 4.17 	 3.91 	 4.00 	 3.90 	 3.86 	 4.57 	 4.27 
45평균	 3.40 	 3.00 	 3.09 	 2.67 	 3.70 	 2.14 	 3.43 	 3.64 
46평균	 2.80 	 2.33 	 2.64 	 2.67 	 2.90 	 3.14 	 3.71 	 3.18 
47평균	 2.80 	 2.33 	 2.45 	 3.00 	 2.90 	 2.14 	 3.29 	 2.82 
48평균	 4.00 	 2.33 	 2.91 	 2.67 	 3.30 	 2.86 	 3.43 	 3.64 
49평균	 3.20 	 2.67 	 3.00 	 3.67 	 2.50 	 2.29 	 3.71 	 3.64 
`;

export const radar1b = `구분	배려	개방성	학습	즐거움	결과	권위	안전	질서
1평균	 9.60 	 8.50 	 4.36 	 1.00 	 9.50 	 6.71 	 2.29 	 4.64 
2평균	 3.20 	 4.17 	 8.91 	 6.67 	 5.60 	 4.43 	 1.14 	 9.45 
3평균	 9.20 	 1.33 	 1.36 	 4.00 	 1.20 	 8.14 	 7.71 	 8.82 
4평균	 8.60 	 3.67 	 3.55 	 7.67 	 2.40 	 3.00 	 4.14 	 6.91 
5평균	 8.60 	 3.50 	 1.09 	 8.67 	 5.00 	 7.57 	 6.86 	 8.55 
6평균	 2.40 	 8.50 	 4.82 	 9.33 	 9.90 	 2.00 	 3.14 	 5.27 
7평균	 1.20 	 3.67 	 6.91 	 2.00 	 9.10 	 5.43 	 2.00 	 4.00 
8평균	 3.80 	 6.67 	 2.09 	 7.00 	 7.00 	 1.29 	 9.43 	 2.09 
9평균	 5.20 	 6.33 	 5.18 	 9.00 	 7.00 	 6.14 	 1.43 	 7.27 
10평균	 7.00 	 4.33 	 1.45 	 1.67 	 7.30 	 6.57 	 1.29 	 1.27 
11평균	 8.40 	 6.50 	 3.27 	 3.33 	 6.20 	 1.00 	 4.29 	 2.18 
12평균	 2.80 	 3.17 	 3.18 	 1.33 	 6.40 	 7.86 	 6.43 	 9.18 
13평균	 2.80 	 2.83 	 6.36 	 4.00 	 5.40 	 3.86 	 4.57 	 5.64 
14평균	 3.20 	 1.00 	 9.91 	 4.00 	 5.70 	 5.00 	 3.14 	 2.73 
15평균	 8.20 	 4.00 	 8.00 	 6.00 	 1.00 	 6.29 	 5.00 	 4.45 
16평균	 8.80 	 3.33 	 7.82 	 6.00 	 7.00 	 7.86 	 6.86 	 1.18 
17평균	 2.00 	 3.67 	 4.00 	 9.00 	 1.60 	 6.86 	 5.71 	 2.91 
18평균	 7.00 	 8.33 	 7.09 	 2.33 	 2.30 	 9.14 	 3.29 	 6.00 
19평균	 4.40 	 5.67 	 3.64 	 3.67 	 9.30 	 2.14 	 3.29 	 5.45 
20평균	 1.80 	 4.33 	 7.18 	 9.00 	 9.00 	 9.00 	 1.14 	 7.00 
21평균	 1.20 	 1.50 	 1.91 	 6.00 	 2.20 	 8.57 	 4.71 	 9.09 
22평균	 5.20 	 4.33 	 3.64 	 3.00 	 8.60 	 8.57 	 3.00 	 8.82 
23평균	 7.60 	 9.83 	 6.27 	 7.67 	 5.90 	 7.71 	 4.14 	 4.27 
24평균	 4.20 	 2.00 	 4.09 	 2.00 	 2.10 	 3.00 	 6.43 	 6.27 
25평균	 5.00 	 6.33 	 8.09 	 1.00 	 3.80 	 6.43 	 9.57 	 2.27 
26평균	 3.20 	 2.67 	 6.36 	 6.67 	 6.30 	 1.71 	 5.29 	 1.09 
27평균	 1.60 	 5.33 	 2.00 	 7.67 	 5.80 	 3.00 	 8.14 	 3.91 
28평균	 1.60 	 3.50 	 7.82 	 7.00 	 6.20 	 7.71 	 2.71 	 8.64 
29평균	 6.60 	 9.33 	 1.82 	 8.33 	 5.80 	 9.57 	 7.00 	 7.91 
30평균	 9.60 	 1.50 	 3.91 	 1.67 	 1.40 	 7.86 	 4.00 	 8.73 
31평균	 9.80 	 2.83 	 2.27 	 1.33 	 6.90 	 4.57 	 2.71 	 7.36 
32평균	 6.20 	 3.50 	 3.00 	 7.00 	 3.50 	 1.71 	 7.71 	 9.82 
33평균	 4.20 	 2.67 	 5.82 	 5.67 	 7.60 	 8.71 	 4.86 	 8.36 
34평균	 3.00 	 7.50 	 7.91 	 5.33 	 4.80 	 6.14 	 9.43 	 5.45 
35평균	 9.40 	 3.00 	 2.36 	 8.00 	 1.40 	 8.14 	 4.86 	 2.64 
36평균	 9.60 	 2.50 	 5.45 	 9.00 	 3.30 	 5.71 	 6.86 	 9.18 
37평균	 9.00 	 3.00 	 1.91 	 5.00 	 2.90 	 2.86 	 1.14 	 4.91 
38평균	 6.60 	 9.67 	 1.91 	 8.00 	 9.20 	 7.00 	 5.29 	 3.55 
39평균	 2.40 	 3.33 	 3.64 	 6.67 	 1.80 	 3.00 	 1.71 	 6.64 
40평균	 8.20 	 8.00 	 6.09 	 4.00 	 7.20 	 6.14 	 1.86 	 4.18 
41평균	 2.60 	 7.17 	 9.36 	 6.00 	 8.20 	 3.00 	 8.29 	 4.27 
42평균	 7.80 	 9.00 	 6.00 	 1.00 	 6.60 	 1.00 	 6.14 	 7.00 
43평균	 4.20 	 7.83 	 3.55 	 2.33 	 5.50 	 9.14 	 9.29 	 2.00 
44평균	 7.80 	 5.17 	 9.91 	 4.00 	 9.90 	 8.86 	 8.57 	 1.27 
45평균	 2.40 	 9.00 	 2.09 	 4.67 	 8.70 	 7.14 	 3.43 	 4.64 
46평균	 9.80 	 3.33 	 5.64 	 7.67 	 2.90 	 8.14 	 4.71 	 7.18 
47평균	 7.80 	 1.33 	 8.45 	 1.00 	 6.90 	 8.14 	 8.29 	 5.82 
48평균	 8.00 	 1.33 	 4.91 	 2.67 	 8.30 	 5.86 	 5.43 	 5.64 
49평균	 9.20 	 7.67 	 4.00 	 5.67 	 5.50 	 1.29 	 4.71 	 9.64 
`;

export const radar2 = `응답자	배려	개방성	학습	즐거움	결과	권위	안전	질서
리더평균	3.60	3.33	3.82	4.33	3.80	3.57	4.00	3.91
임원평균	2.84	2.40	2.71	3.00	2.78	2.74	3.00	2.85
직원평균	3.11	2.85	3.06	2.98	3.09	2.92	3.46	3.13
`;

export const radar2b = `응답자	배려	개방성	학습	즐거움	결과	권위	안전	질서
리더평균	3.60	3.33	3.82	4.33	3.80	3.57	4.00	3.91
임원평균	2.84	2.40	2.71	3.00	2.78	2.74	3.00	2.85
직원평균	3.11	2.85	3.06	2.98	3.09	2.92	3.46	3.13
`;

export const radar2c = `응답자	배려	개방성	학습	즐거움	결과	권위	안전	질서
R&D	3.60	3.33	3.82	4.33	3.80	3.57	4.00	3.91
제조	2.84	2.40	2.71	3.00	2.78	2.74	3.00	2.85
마케팅	3.11	2.85	3.06	2.98	3.09	2.92	3.46	3.13
IT	3.84	2.40	4.71	2.00	1.78	1.74	4.00	4.85
HR	4.84	2.40	4.71	2.00	1.78	4.74	3.00	4.85
`;

export const radar3 = `그룹	배려	개방성	학습	즐거움	결과	권위	안전	질서
전체평균	3.06	2.77	3.01	3.01	3.04	2.9	3.38	3.09
`;
export const radar3b = `그룹	배려	개방성	학습	즐거움	결과	권위	안전	질서
전체평균	3.06	4.77	3.01	3.01	3.04	2.9	3.38	3.09
`;

export const radar4 = `구분	혁신전략	혁신프로세스	혁신문화	조직구조 	지식경영	기술역량	제품서비스역량	시장분석역량	방법론및기법	혁신성과
값	3.58	3.17	3.64	3.08	3.4	3.47	3.84	3.22	3.25	3.44
`;

export const bead = `응답자	결과	배려	질서	학습	안전	즐거움	책임	개방성
1그룹	8	7	6	5	4	3	2	1
2그룹	8	7	3	6	4	5	2	1
3그룹	8	7	6	5	4	2	3	1
4그룹	8	7	5	6	2	4	3	1
진단기업	6	3	2	5	7	4	8	1
`;

export const beadb = `응답자	결과	배려	질서	학습	안전	즐거움	책임	개방성
1그룹	1	8	5	5	5	7	8	8
2그룹	6	3	1	3	5	3	8	3
3그룹	1	6	4	3	8	1	6	8
4그룹	7	5	4	1	8	5	6	3
진단기업	6	3	2	5	7	4	8	1
`;

export const flow1 = `항목	혁신전략	시장분석	기술역량	제품서비스	혁신성과
값	3.58	3.22	3.47	3.84	3.44
`;

export const flow2 = `항목	방법론 및 기법	지식경영	프로세스	조직구조	혁신문화
값	3.25	3.40	3.17	3.08	3.64
`;
export const faucet = `Group	1그룹	2그룹	3그룹
혁신전략	3.58	2.5	4.58
혁신프로세스	3.17	2.1	4.17
혁신문화	3.64	2.8	4.64
조직구조 	3.08	2	4.08
지식경영	3.4	4.1	4.4
기술역량	3.84	2.5	4.84
제품서비스역량	3.47	2.4	4.47
시장분석역량	3.22	2.2	4.22
방법론및기법	3.25	2.25	4.25
혁신성과	3.44	2.44	4.44
`;

export const faucetb = `Group	직원급	임원급
혁신전략	3.46	3.75
혁신프로세스	3.07	3.32
혁신문화	3.47	3.90
조직구조 	2.92	3.33
지식경영	3.42	3.38
기술역량	3.25	3.79
제품서비스역량	3.50	4.35
시장분석역량	3.03	3.50
방법론및기법	3.31	3.17
혁신성과	3.40	3.50
`;

export const minmax = `항목	값
전략과 목표의 연계	3.8
구성원의 전략 이해	3.3
`;

export const pyramid = `group	male	female
0-4	10	10
5-9	20	20
10-14	50	50
15-19	80	80
20-24	100	10
25-29	110	110
30-34	120	120
35-39	130	130
40-44	140	140
45-49	150	150
50-54	160	160
55-59	170	170
60-64	160	160
65-69	150	150
70-74	140	140
75-79	130	130
80-84	120	120
85-89	110	110
90-94	100	100
95-99	90	90
100+	20	20
`;

/**
export const population = [
    { agegroup : "0-4",    agenmMale: "age_male_5",   agenmFemale: "age_female_5",   male: 0, female: 0 },
    { agegroup : "5-9",    agenmMale: "age_male_10",  agenmFemale: "age_female_10",  male: 0, female: 0 },
    { agegroup : "10-14",  agenmMale: "age_male_15",  agenmFemale: "age_female_15",  male: 0, female: 0 },
    { agegroup : "15-19",  agenmMale: "age_male_20",  agenmFemale: "age_female_20",  male: 0, female: 0 },
    { agegroup : "20-24",  agenmMale: "age_male_25",  agenmFemale: "age_female_25",  male: 0, female: 0 },
    { agegroup : "25-29",  agenmMale: "age_male_30",  agenmFemale: "age_female_30",  male: 0, female: 0 },
    { agegroup : "30-34",  agenmMale: "age_male_35",  agenmFemale: "age_female_35",  male: 0, female: 0 },
    { agegroup : "36-39",  agenmMale: "age_male_40",  agenmFemale: "age_female_40",  male: 0, female: 0 },
    { agegroup : "40-44",  agenmMale: "age_male_45",  agenmFemale: "age_female_45",  male: 0, female: 0 },
    { agegroup : "45-49",  agenmMale: "age_male_50",  agenmFemale: "age_female_50",  male: 0, female: 0 },
    { agegroup : "50-54",  agenmMale: "age_male_55",  agenmFemale: "age_female_55",  male: 0, female: 0 },
    { agegroup : "55-59",  agenmMale: "age_male_60",  agenmFemale: "age_female_60",  male: 0, female: 0 },
    { agegroup : "60-64",  agenmMale: "age_male_65",  agenmFemale: "age_female_65",  male: 0, female: 0 },
    { agegroup : "65-69",  agenmMale: "age_male_70",  agenmFemale: "age_female_70",  male: 0, female: 0 },
    { agegroup : "70-74",  agenmMale: "age_male_75",  agenmFemale: "age_female_75",  male: 0, female: 0 },
    { agegroup : "75-79",  agenmMale: "age_male_80",  agenmFemale: "age_female_80",  male: 0, female: 0 },
    { agegroup : "80-84",  agenmMale: "age_male_85",  agenmFemale: "age_female_85",  male: 0, female: 0 },
    { agegroup : "85-89",  agenmMale: "age_male_90",  agenmFemale: "age_female_90",  male: 0, female: 0 },
    { agegroup : "90-94",  agenmMale: "age_male_95",  agenmFemale: "age_female_95",  male: 0, female: 0 },
    { agegroup : "95-100", agenmMale: "age_male_100", agenmFemale: "age_female_100", male: 0, female: 0 },
    { agegroup : "100+",   agenmMale: "age_male_105", agenmFemale: "age_female_105", male: 0, female: 0 },
  ]
**/


export const pyramidJson = [
    {group: "0-4",   male:  10, female:  10},
    {group: "5-9",   male:  20, female:  20},
    {group: "10-14", male:  50, female:  50},
    {group: "15-19", male:  80, female:  80},
    {group: "20-24", male: 100, female:  10},
    {group: "25-29", male: 110, female: 190},
    {group: "30-34", male: 120, female: 120},
    {group: "35-39", male: 130, female: 130},
    {group: "40-44", male: 140, female: 140},
    {group: "45-49", male: 150, female: 150},
    {group: "50-54", male: 160, female: 160},
    {group: "55-59", male: 170, female: 170},
    {group: "60-64", male: 160, female: 160},
    {group: "65-69", male: 150, female: 150},
    {group: "70-74", male: 140, female: 140},
    {group: "75-79", male: 130, female: 130},
    {group: "80-84", male: 120, female: 120},
    {group: "85-89", male: 110, female: 110},
    {group: "90-94", male: 100, female: 100},
    {group: "95-99", male:  90, female:  90},
    {group: "100+",  male:  20, female:  20}
]
pyramidJson["columns"] = ["group", "male", "female"]

export const keyAgeGroupJson = [
{gender: "male",  age14: 120, age64: 820, age65: 230},
{gender: "female", age14: 100, age64: 800, age65: 200},


/**
{group: "0-14",  male: 120, female: 100},
{group: "15-64", male: 820, female: 800},
{group: "65+",   male: 230, female: 200},
**/
];
keyAgeGroupJson["columns"] = ["gender", "age14", "age64", "age65"]

export const keyAgeGroup = `group	male	female
0-14	120	100
15-64	820	800
65+	230	200
`;

export const maleVsFemale = `gender	value
male	800
female	900
`;

export const genderRateJson = [
{group:"genderRate", male:800, female:900}
]
genderRateJson["columns"] = ["genderRate", "male", "female"]


export const popTimeline = `group	2013년	2014년	2015년	2016년	2017년	2018년	2019년
0-14	120	100	120	130	140	150	160
`;

export const popTrendJson = [
  {
    name: "a14", 
    values: [ 
      {year: 2014, population: "100"}, 
      {year: 2015, population: "110"},
      {year: 2016, population: "130"},
      {year: 2017, population: "140"},
      {year: 2018, population: "120"},
      {year: 2019, population: "100"},
    ],
  },
  {
    name: "a64",
    values: [
      {year: 2014, population: "400"},
      {year: 2015, population: "410"},
      {year: 2016, population: "430"},
      {year: 2017, population: "440"},
      {year: 2018, population: "420"},
      {year: 2019, population: "400"},
    ],
  },
  {
    name: "a65",
    values: [
      {year: 2014, population: "200"},
      {year: 2015, population: "210"},
      {year: 2016, population: "230"},
      {year: 2017, population: "240"},
      {year: 2018, population: "220"},
      {year: 2019, population: "200"},
    ],
  },
];

export const popTimelineJson = [
{year: "2014년", "age14":"100", "age64":400, "age65":"200"},
{year: "2015년", "age14":"110", "age64":410, "age65":"210"},
{year: "2016년", "age14":"130", "age64":430, "age65":"230"},
{year: "2017년", "age14":"140", "age64":440, "age65":"240"},
{year: "2018년", "age14":"120", "age64":420, "age65":"220"},
{year: "2019년", "age14":"100", "age64":400, "age65":"200"},
];

// 소상공인 현황
/**
export const retailClass1 = `group	value
관광	62468
부동산	69211
서비스	371272
소매	814167
숙박	37913
음식	796600
의료	77240
교육	180971
`;
**/
export const retailClass1Json = [
{name: "관광",   nos: "62468"  },
{name: "부동산", nos: "69211"  },
{name: "서비스", nos: "371272" },
{name: "소매",   nos: "814167" },
{name: "숙박",   nos: "37913"  },
{name: "음식",   nos: "796600" },
{name: "의료",   nos: "77240"  },
{name: "교육",   nos: "180971" }
];
retailClass1Json["columns"] = ["name", "nos"]

export const retailClass2Json = [
{name: "관광",   nos: "62468"  },
{name: "부동산", nos: "69211"  },
{name: "서비스", nos: "371272" },
{name: "소매",   nos: "814167" },
{name: "숙박",   nos: "37913"  },
{name: "음식",   nos: "796600" },
{name: "의료",   nos: "77240"  },
{name: "교육",   nos: "180971" }
];
retailClass2Json["columns"] = ["name", "nos"]
 
export const retailClass3Json = [
{name: "관광",   nos: "62468"  },
{name: "부동산", nos: "69211"  },
{name: "서비스", nos: "371272" },
{name: "소매",   nos: "814167" },
{name: "숙박",   nos: "37913"  },
{name: "음식",   nos: "796600" },
{name: "의료",   nos: "77240"  },
{name: "교육",   nos: "180971" }
];
retailClass3Json["columns"] = ["name", "nos"]




export const retailClass2Ori = `code	가전제품소매	사무/문구/컴퓨터	유아용품	음/식료품소매	의복의류	가구소매	기타판매업	책/서적/도서	화장품소매	페인트/유리제품소매	운동/경기용품소매	종교용품판매	시계/귀금속소매	애견/애완/동물	철물/난방/건설자재소매	종합소매점	자동차/자동차용품	가정/주방/인테리어	선물/팬시/기념품	예술품/골동품/수석/분재	취미/오락관련소매	건강/미용식품	중고품소매/교환	가방/신발/액세서리	의약/의료품소매	사진/광학/정밀기기소매
value	7919	112529	2027	28658	24176	33558	74739	36382	25511	31790	9168	8094	18475	4462	3445	21276	4417	13054	2056	130794	17744	146734	38110	6684	11891	474
`;

export const retailClass2 = `code	가전제품	사무/문구	유아용품	음/식료품	의복의류	가구	기타판매	책/서적	화장품	페인트/유리	운동용품	종교용품	시계/귀금속	애완동물	철물/자재	종합소매	자동차용품	주방	선물/기념품	예술/골동	취미/오락	건강/미용	중고품	가방/신발	의약/의료	사진/정밀
value	7919	112529	2027	28658	24176	33558	74739	36382	25511	31790	9168	8094	18475	4462	3445	21276	4417	13054	2056	130794	17744	146734	38110	6684	11891	474
`;

export const retailClass3Ori = `code	닭집	미곡상	어물상	정육점	건어물상	곡물소매	김치판매	두유판매	반찬가게	생수판매	식료품점	얼음가게	우유판매	유과판매	육류소매	건과류판매	수산물소매	식용유판매	식자재판매	청과물소매	냉동식품판매	머리고기전문	제과재료판매	유기농식품판매	드라이아이스판매
value	1523	1634	516	7354	2995	2201	471	196	6562	920	22713	522	522	91	12868	64	10388	66	2000	37521	317	13	58	1003	11
`;

export const retailClass3 = `code	닭집	미곡상	어물상	정육점	건어물상	곡물소매	김치판매	두유판매	반찬가게	생수판매	식료품점	얼음가게	우유판매	유과판매	육류소매	건과류	수산물	식용유	식자재	청과물	냉동식품	머리고기	제과재료	유기농식품	드라이아이스
value	1523	1634	516	7354	2995	2201	471	196	6562	920	22713	522	522	91	12868	64	10388	66	2000	37521	317	13	58	1003	11
`;

export const storeListJson = {
  columns : 
  [
    {field: "id",          hide: false, headerName: "ID",           width: 100, type: ""},
    {field: "desk",        hide: false, headerName: "Desk",         width: 200, type: ""},
    {field: "commodity",   hide: false, headerName: "Comodity",     width: 200, type: ""},
    {field: "traderEmail", hide: false, headerName: "Trader Email", width: 200, type: ""},
    {field: "quantity",    hide: false, headerName: "Quantity",     width: 100, type: ""},
  ],
  rows : 
  [
    {id: "id0001", commodity: "Cotton No.1", desk: "D-2775", quantity: 69401, traderEmail: "aa1@gmail.com"},
    {id: "id0002", commodity: "Cotton No.2", desk: "D-2775", quantity: 69402, traderEmail: "aa2@gmail.com"},
  ]
}

export const defTblData = {
  columns :
  [
    {field: "id", hide: false, headerName: " ", width: "100%", type: ""},
  ],
  rows :
  [
    {},
  ]
}


//    {id: "id0001" },
export const resultsJson = {
  flag: 'init',
  columns : [
    { field: 'id', headerName: 'NO', width: 80 },
    { field: 'pop', headerName: '인구', type: 'number', width: 80 },
    { field: 'nocvs', headerName: '기존 편의점수', type: 'number', width: 140 },
    {
      field: 'rate',
      headerName: '점포당 인구',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 140,
      type: 'number',
      valueGetter: (params) =>
        Math.round(params.getValue('pop') / params.getValue('nocvs'), 2),
    },
    { field: 'city', headerName: '지역', width: 100, hide: false },
    { field: 'key_code', headerName: 'key_code', width: 80, hide: true },
    { field: 'lng', headerName: '경도', width: 100, hide: true },
    { field: 'lat', headerName: '위도', width: 100, hide: true },
  ],
  rows : 
  [
    { id: 1, key_code: '5527400421', pop: 9000, nocvs: 5,  city: '서울', lng: '127.024612', lat: '36.532600' },
    { id: 2, key_code: '5527400422', pop: 8000, nocvs: 1,  city: '서울', lng: '127.024612', lat: '37.532600' },
    { id: 3, key_code: '5527400511', pop: 7000, nocvs: 2,  city: '서울', lng: '127.024612', lat: '37.532600' },
    { id: 4, key_code: '5527407433', pop: 6500, nocvs: 3,  city: '경기', lng: '127.024612', lat: '37.532600' },
    { id: 5, key_code: '5527407434', pop: 6400, nocvs: 19, city: '경기', lng: '127.024612', lat: '37.532600' },
    { id: 6, key_code: '5527407441', pop: 5000, nocvs: 4,  city: '경기', lng: '127.024612', lat: '37.532600' },
    { id: 7, key_code: '5527407443', pop: 4500, nocvs: 9,  city: '경기', lng: '127.024612', lat: '37.532600' },
    { id: 8, key_code: '5527407444', pop: 4300, nocvs: 8,  city: '경기', lng: '127.024612', lat: '37.532600' },
    { id: 9, key_code: '5527407533', pop: 4100, nocvs: 3,  city: '경기', lng: '127.024612', lat: '37.532600' },
  ]
};
