import * as d3 from 'd3';
import wrap from 'shared/wrap';

export function drawRadarSkeleton(element, data, options) {
  /** An array of objects. E.g. [{ group: A, axis: foo, value: bar }]. */
  // const data = element.data();
  /** Group data by axis for convenience. */
  const byAxis = d3
    .nest()
    .key((d) => d.axis)
    .entries(data);

  // Options
  /** Radius of the radar. */
  const radius = options.radius;
  /** Number of circles. */
  const levels = options.levels || 1;
  /** Circle opacity. */
  const opacity = options.opacity || 0.35;
  /** Font size. */
  const fontSize = options.fontSize || 12;
  /** Wrap text if it goes past this width. */
  const wrapWidth = options.wrapWidth || 60;
  /** Maximum value. */
  const maxValue = Math.max(
    options.maxValue || 0,
    d3.max(data, (d) => d.value),
  );
  /** Size of the gutter between the axis line and text. */
  const labelGutter = options.labelGutter || 1.2;
  /** Provide an array of axes if you want a custom sort order. */
  const axes = options.axes || byAxis.map((d) => d.key).sort(d3.ascending);

  // Scales.
  /** Angle per slice. */
  const sliceAngle = (Math.PI * 2) / axes.length;
  /** Radius scale. */
  const r = d3.scaleLinear().domain([0, maxValue]).range([0, radius]);
  /** Angle scale. */
  const a = d3
    .scaleOrdinal()
    .domain(axes)
    .range(axes.map((d, i) => i * sliceAngle - Math.PI / 2));

  // Draw ticks.
  element
    .selectAll('.tick')
    .data(d3.range(1, levels + 1).reverse())
    .join('circle')
    .attr('class', 'tick')
    .transition()
    .attr('r', (d) => (radius / levels) * d)
    .attr('stroke-width', 1)
    .attr('stroke', 'rgb(217, 217, 217)')
    .style('fill', 'None')
    .style('fill-opacity', opacity);

  // Draw axis lines.
  element
    .selectAll('.axisLine')
    .data(axes)
    .join('line')
    .attr('class', 'axisLine')
    .transition()
    .attr('x1', 0)
    .attr('y1', 0)
    .attr('x2', (d, i) => radius * Math.cos(a(d)))
    .attr('y2', (d, i) => radius * Math.sin(a(d)))
    .style('stroke', 'rgb(217, 217, 217)')
    .style('stroke-width', 1);

  // Draw axis labels.
  element
    .selectAll('.axisLabel')
    .data(axes)
    .join('text')
    .transition()
    .attr('class', 'axisLabel')
    .attr('alignment-baseline', 'central')
    .attr('text-anchor', 'middle')
    .attr('dy', 0.35)
    .attr('x', (d, i) => r(maxValue * labelGutter) * Math.cos(a(d)) )
    .attr('y', (d, i) => r(maxValue * labelGutter) * Math.sin(a(d)) )
    .text((d) => d)
    .style('font-size', fontSize);
  // .call(wrap, wrapWidth);

  return {
    radiusScale: r,
    angleScale: a,
  };
}
