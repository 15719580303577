import React from 'react';
import * as d3 from 'd3';
import legend from 'shared/legend';
import './Pie.scss';
import { flattenObjArray, createColorScale } from 'shared/helpers';
import { pie as defaults, strings } from 'shared/defaults';
import { progressBar, watermark } from 'shared/helpers';

const Pie = React.forwardRef(({ data, wparam }, ref) => {
  const cfg = Object.assign({}, defaults, strings, wparam);

  const w       = cfg.width,
        h       = cfg.height,
        mtop    = cfg.margin.top,
        mleft   = cfg.margin.left,
        mbottom = cfg.margin.bottom,
        mright  = cfg.margin.right,
        gutter  = cfg.legendGutter;


  const bounded = {
    width: w - mleft - mright,
    height: h - mbottom - mtop - gutter,
  };

  const center = {
    X: bounded.width / 2,
    Y: bounded.height / 2
//    X: cfg.width / 2,
//    Y: cfg.height / 2 - cfg.legendGutter
  };
  const bodyRef      = React.useRef(null);
  const titleRef     = React.useRef(null);
  const unitsRef     = React.useRef(null);
  const xAxisRef     = React.useRef(null);
  const yAxisRef     = React.useRef(null);
  const legendRef    = React.useRef(null);
  const pbarRef      = React.useRef(null);
  const watermarkRef = React.useRef(null);


  const draw = () => {
    const body = d3.select(bodyRef.current);
    const groupColumn = data.columns[0];
    const groups = data.map((d) => d[groupColumn]);
    const flat = flattenObjArray(data, groupColumn);

    const color = createColorScale(
      cfg.colors,
      groups,
      cfg.firstColor,
      cfg.lastColor,
    );
//    const labelFormat = cfg.formatValue;
    const format = d3.format(",");
    const radius = Math.min(bounded.width, bounded.height) / 2 - cfg.margin.left

    const createPie = d3
      .pie()
      .startAngle(-180 * Math.PI/180)
      .sort(null)  // do not sort group by size
      .value( (d) => d.value )


    const createArc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(radius)

    const pieWithData = body
      .selectAll("g.arc")
      .data(createPie(flat))
      .attr('x', 700 )
      .attr('y', 700 )

    pieWithData.exit().remove();

    const groupWithUpdate = pieWithData
      .enter()
      .append("g")
      .attr("class", "arc");

    const path = groupWithUpdate
      .append("path")
      .merge(pieWithData.select("path.arc"));

    path
      .attr("class", "arc" )
      .attr("d", createArc)
      .attr("fill", (d, i) => color(d.data.group)) 

    const text = groupWithUpdate
      .append("text")
      .merge(pieWithData.select("text"));

    text
      .attr("text-anchor", "middle" )
      .attr("alignment-baseline", "middle" )
      .attr("transform", (d) => `translate(${createArc.centroid(d)})`)
      .style("fill", "red")
      .style("font-size", 14)
      .text( d => format(d.value) + '명')
      .transition()
      .on("end", (d, i) => {
          if( i+1 === flat.length ) {
            progressBar.hide(pbarRef)
            watermark.show(watermarkRef, cfg.width, cfg.height, cfg.watermark );
          }
       })

    // Draw the legend.
    if (cfg.useLegend) {
      d3.select(legendRef.current).call(legend, {
        color,
        maxWidth: bounded.width,
//        fontSize: cfg.fontSize,
      });
    }

    if (cfg.units) {
      d3.select(unitsRef.current).text(cfg.units || null);
    }
  };

  React.useEffect(() => {
    try {
      if( data && data.length > 0 && 
         (data[0].population > 0 || data[1].population > 0) ) 
      {
        draw();
      }
      else {

        // show progress bar
        progressBar.show(pbarRef, cfg.width, cfg.height, cfg.norec );
        watermark.hide(watermarkRef);

        d3.select(unitsRef.current)      .text( null )
        d3.select(legendRef.current)     .text( null )
        d3.select(xAxisRef.current)      .selectAll("*").remove()
        d3.select(yAxisRef.current)      .selectAll("*").remove()
        d3.select(bodyRef.current)       .selectAll("*").remove()

      }

    } catch (error) {
      alert(`Invalid data entered for chart id. `);
    }
  }, [data, cfg, draw]);

  return (
    <svg
      ref={ref}
      className="chart"
      viewBox={`0 0 ${cfg.width} ${cfg.height}`}
      style={{ margin: 'auto', display: 'block', fontSize: cfg.fontSize }}
    >

      <g ref={pbarRef} transform={`translate(0,0)`}/>
      <g ref={watermarkRef} transform={`translate(0,0)`}/>

      <g transform={`translate(${cfg.margin.left}, ${cfg.margin.top})`}>
        <text
          ref={titleRef}
          transform={`translate(${bounded.width / 2}, ${cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize * 1.5, textAnchor: 'middle' }}
        />
        <text
          ref={unitsRef}
          transform={`translate(${bounded.width - 50}, ${cfg.margin.top})`}
          style={{ fontSize: cfg.fontSize, textAnchor: 'right' }}
        />
        <g ref={bodyRef} transform={`translate(${center.X} ${center.Y})`} />
        <g
          ref={xAxisRef}
          transform={`translate(${0}, ${bounded.height})`}
          style={{ fontSize: cfg.fontSize }}
        />
        <g ref={yAxisRef} style={{ fontSize: cfg.fontSize }} />
        <g
          ref={legendRef}
          transform={`translate(${0}, ${bounded.height + cfg.legendGutter})`}
        />
      </g>
    </svg>
  );
});

export default Pie;
