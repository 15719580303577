import * as d3 from 'd3';

const heights = [480, 360]

export const class1 = {
  wparam : {
    width : 480,
    height: heights[0],
    units:'(단위:개)',
    numFormat:',',
  }
};

export const class2 = {
  wparam : {
    width : 1024,
    height: heights[1],
    units:'(단위:개)',
    numFormat:',',
  }
};

export const class3 = {
  wparam : {
    width : 1024,
    height: heights[1],
    units:'(단위:개)',
    numFormat:',',
  }
};
