import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { strings } from 'shared/defaults';


const Smallbiz = React.forwardRef( ({id, data, option, callback}, ref ) => {

  const emptyPage = () => {
    return (
      <span style={{  height: '450px', width: '100%', 
                      display: 'flex', 
                      justifyContent: 'center', alignItems:"center", 
                  }} > 
          {strings.norec}
      </span>
    )
  }

  if( data == null )
  {
    return emptyPage()
  }

  return (
    <div style={{ height: '450px', width: '100%' }}>
          <DataGrid
            rowHeight={52}
            pageSize={6}
            pagination
            onRowClick={ (param) => {callback(param)} }
            {...data}
          />
    </div>
  );
});
export default Smallbiz 
