import React from 'react';
import PropTypes from "prop-types";

import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings} from 'aws-amplify-react';
//import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';

export default function SigninDlg(props) {
//  const classes = useStyles();
//  const { className, children, onClose, onApply, open, ...rest } = props;

//  const [chartData, setChartData] = React.useState("");



  /* eslint-disable no-unused-vars */
  const { onClose, selectedValue, open } = props;
  /* eslint-enable no-unused-vars */


  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const AlwaysOn = (props) => {
    return (
        <div>
            <div>I am always here to show current auth state: {props.authState}</div>
            <button onClick={() => props.onStateChange('signUp')}>Show Sign Up</button>
        </div>
    )
  }

  const handleAuthStateChange = (state) => {
    if (state === 'signedIn') {
        /* Do something when the user has signed-in */
      onClose('selectedValue');
    }
  }


  return (
    <Dialog  onClose={handleClose} fullWidth={true} maxWidth="sm" open={open}>
        <Authenticator hideDefault={true} onStateChange={handleAuthStateChange} >
            <SignIn/>
            <SignUp/>
            <ConfirmSignUp/>
        </Authenticator>

    </Dialog>
  );
{/**
            <AlwaysOn/>
            <Greetings/>
**/}

}

SigninDlg.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired
};
