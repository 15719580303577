import React from 'react';
import * as d3 from 'd3';
import legend from 'shared/legend';
import './Pyramid.scss';
import { progressBar, watermark } from 'shared/helpers';
import { pyramid as defaults, strings } from 'shared/defaults.js';

const Pyramid = React.forwardRef(({ data, wparam }, ref) => {

  const cfg = Object.assign({}, defaults, strings, wparam);

  cfg.fontSize = 12

/**
  const bounded = {
    width: cfg.width - cfg.margin.left - cfg.margin.right,
    height: cfg.height - cfg.margin.bottom - cfg.margin.top - cfg.legendGutter,
  };
**/

  const w       = cfg.width,
        h       = cfg.height,
        mtop    = cfg.margin.top,
        mleft   = cfg.margin.left,
        mbottom = cfg.margin.bottom,
        mright  = cfg.margin.right,
        gutter  = cfg.legendGutter;


  const bounded = {
    width: w - mleft - mright,
    height: h - mbottom - mtop - gutter,
  };




  const bodyRef        = React.useRef(null);
  const bodyLeftRef    = React.useRef(null);
  const bodyRightRef   = React.useRef(null);
  const titleRef       = React.useRef(null);
  const unitsRef       = React.useRef(null);
  const xTitleRef      = React.useRef(null);
//  const xAxisRef       = React.useRef(null);
  const xAxisLeftRef   = React.useRef(null);
  const xAxisRightRef  = React.useRef(null);
//  const yAxisRef       = React.useRef(null);
  const yAxisLeftRef   = React.useRef(null);
  const yAxisRightRef  = React.useRef(null);
  const legendRef      = React.useRef(null);
  const pbarRef        = React.useRef(null);
  const watermarkRef   = React.useRef(null);

  const draw = () => {
    const body         = d3.select(bodyRef.current);
    const bodyLeft     = d3.select(bodyLeftRef.current);
    const bodyRight    = d3.select(bodyRightRef.current);
    const title        = d3.select(titleRef.current);
//    const xAxis        = d3.select(xAxisRef.current);
    const xTitle       = d3.select(xTitleRef.current);
    const xAxisLeft    = d3.select(xAxisLeftRef.current);
    const xAxisRight   = d3.select(xAxisRightRef.current);
//    const yAxis        = d3.select(yAxisRef.current);
    const yAxisLeft    = d3.select(yAxisLeftRef.current);
    const yAxisRight   = d3.select(yAxisRightRef.current);

    const groupColumn  = data.columns[0];
//    const maleColumn   = data.columns[1];
//    const femaleColumn = data.columns[2];
    const groups       = data.map((d) => d[groupColumn]);
//    const males        = data.map((d) => d[maleColumn]);
//    const females      = data.map((d) => d[femaleColumn]);

    const pointA       = bounded.width / 2 - cfg.margin.middle
    const pointB       = bounded.width - pointA

    const flat = data

/**
    const maxMaleValue = Math.max(
      cfg.maxValue || 0,
      d3.max(flat.map((d) => d.male))
    );
    const maxFemaleValue = Math.max(
      cfg.maxValue || 0,
      d3.max(flat.map((d) => d.female))
    );
**/

    const totalPopulation = d3.sum(flat, (d) => { return d.male + d.female; })
    const percentage = (d) => { return d / totalPopulation * 100; };

    const maxValue = Math.max(
      d3.max(flat, (d) => { return percentage(d.male); }) || 0,
      d3.max(flat, (d) => { return percentage(d.female); }) || 0
    );

    const color = d3.scaleOrdinal(cfg.colors).domain(['male', 'female'])

//    const labelFormat = d3.format(cfg.formatString);

    // the width of each side of the chart
    const regionWidth = bounded.width / 2 - cfg.margin.middle;

    // Scales.
    const xScale = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([0, regionWidth])
//      .rangeRound([0, regionWidth])
      .nice()


/**
    const xScaleLeft = d3
      .scaleLinear()
//      .domain([0, maxMaleValue])
      .domain([0, maxValue])
      .rangeRound(regionWidth, 0)
//      .rangeRound([bounded.width / 2, cfg.margin.left ])
**/

/**
    const xScaleRight = d3
      .scaleLinear()
      .domain([0, maxValue])
//      .domain([0, maxFemaleValue])
      .rangeRound(0, regionWidth)
**/

    const yScale = d3
      .scaleBand()
      .domain(groups)
      .rangeRound([bounded.height - cfg.margin.bottom, cfg.margin.top ])


    var yAxisL = d3
      .axisRight(yScale)
      .tickSize(4,0)
      .tickPadding(cfg.margin.middle - 4);

    var yAxisR = d3
      .axisLeft(yScale)
      .tickSize(4,0)
      .tickFormat('');

    // Draw axes.

    xAxisLeft
      .attr("transform", `translate(0, ${bounded.height})`)
      .call(d3.axisBottom()
              .scale(xScale.copy()
              .range([pointA, 0])) 
//              .tickFormat(d3.format("%"))
         )
//      .selectAll(".domain")
//      .remove()
      .transition()
/**
              .tickFormat( d3.format('%'))
      .call(d3.axisBottom(xScale
                          .copy()
                          .range([pointA, 0])
                          )
        .tickFormat(d3.format("%"))
           )

      .attr("transform", `translate(0, ${bounded.height})`)
//      .call(d3.axisBottom(xScale
//                          .copy()
//                          .range([pointA, 0])
//                          )
//              .tickValues( d3.range(0, maxValue, unit) )
//              .tickFormat(d3.format(".1s"))
              .tickFormat(d3.format("%"))
           )
**/



    xAxisRight
      .attr("transform", `translate(${pointB}, ${bounded.height})`)
      .call(d3.axisBottom().scale(xScale) )
//      .selectAll(".domain")
//      .remove()
      .transition()
/**
              .tickValues( d3.range(0, 100, 20) )
              .tickFormat( d3.format('%'))
           )
**/
/**
      .scale(xScale)
      .tickFormat(d3.format('%'))
      .call(d3.axisBottom(xScale)
//              .tickValues( d3.range(0, 1, 0.2) )
              .tickFormat(d3.format('%'))
           )
//      .selectAll(".domain")
//      .remove()
//      .transition()
**/

    yAxisLeft
      .attr("transform", `translate(${pointA},0)`)
      .call(yAxisL)
      .selectAll("text")
      .style('text-anchor', 'middle')

    yAxisRight
      .attr("transform", `translate(${pointB},0)`)
      .call(yAxisR)
      .selectAll("text")
      .style('text-anchor', 'middle')
//      .selectAll(".domain")
//      .remove()


    // Draw bars.
    bodyLeft
      .attr('transform', translation(pointA, 0) + 'scale(-1,1)')
      .selectAll('.bar.left')
      .data(flat)
      .join('rect')
      .attr('x', 0 )
      .attr('y', (d) => yScale(d.group))
      .attr('width', d => xScale(percentage(d.male)) )
      .attr('height', yScale.bandwidth())
      .attr('class', 'bar left')

    // Draw bars.
    bodyRight
      .attr('transform', translation(pointB, 0))
      .selectAll('.bar.right')
      .data(flat)
      .join('rect')
      .attr('x', 0 )
      .attr('y', (d) => yScale(d.group))
      .attr('width', d => xScale(percentage(d.female)) )
      .attr('height', yScale.bandwidth())
      .attr('class', 'bar right')
      .transition()
      .on("end", (d, i) => {
          if( i+1 === flat.length ) {
            progressBar.hide(pbarRef);
            watermark.show(watermarkRef, cfg.width, cfg.height, cfg.watermark );
          } 
       })


    // attach labels
    body
      .selectAll('.label')
      .remove()

    body
      .append("g")
      .selectAll('.label.left')
      .data(flat)
      .join('text')
      .attr('x', (d) => regionWidth - xScale(percentage(d.male)) )
      .attr('y', (d) => yScale(d.group))
      .attr("dy", "0.92em")
      .text(d => d.male > 0 ? d.male.toLocaleString() : "")
      .attr('class', 'label left')
      .style('text-anchor', 'end')
      .transition()

    body
      .append("g")
      .selectAll('.label.right')
      .data(flat)
      .join('text')
      .attr('x', (d) => regionWidth + xScale(percentage(d.female)) + cfg.margin.middle + 30 )
      .attr('y', (d) => yScale(d.group))
      .attr("dy", "0.92em")
      .text(d => d.female > 0 ? d.female.toLocaleString() : "")
      .attr('class', 'label right')
      .style('text-anchor', 'start')

    xTitle
      .attr("transform", `translate(${bounded.width / 2}, ${bounded.height - 8 } )`)
      .text("percentage of total population")
      .style('text-anchor', 'middle')


    // Draw title.
    title.text(cfg.title || null);

    // Draw the legend.
    if (cfg.useLegend) {

      d3.select(legendRef.current).call(legend, {
        color,
        maxWidth: bounded.width,
      });

    }


    if (cfg.units) {
      d3.select(unitsRef.current).text(cfg.units || null);
    }
  };

  React.useEffect(() => {

    try {

      const numrows = d3.sum(data, (d) => { return d.male + d.female; })
      if( data && data.length > 0 && numrows > 0 ) {
          draw();
      }
      else {

        // show progress bar
        progressBar.show(pbarRef, cfg.width, cfg.height, cfg.norec );
        watermark.hide(watermarkRef);

        d3.select(bodyRef.current)      .selectAll(".label") .remove()
        d3.select(bodyLeftRef.current)  .selectAll("*") .remove()
        d3.select(bodyRightRef.current) .selectAll("*") .remove()
        d3.select(xTitleRef.current)    .text( null )
        d3.select(unitsRef.current)     .text( null )
        d3.select(xAxisLeftRef.current) .selectAll("*") .remove()
        d3.select(xAxisRightRef.current).selectAll("*") .remove()
        d3.select(yAxisLeftRef.current) .selectAll("*") .remove()
        d3.select(yAxisRightRef.current).selectAll("*") .remove()
        d3.select(legendRef.current)    .selectAll("*") .remove()
      }
    } catch (error) {
      alert(`Invalid data entered for chart id. ` + error);
    }

  }, [data ]);

  return (
    <svg
      ref={ref}
      className="chart"
      viewBox={`0 0 ${cfg.width} ${cfg.height}`}
      style={{ margin: 'auto', display: 'block', fontSize: cfg.fontSize }}
    >
      <g ref={pbarRef} transform={`translate(0,0)`}/>
      <g ref={watermarkRef} transform={`translate(0,0)`}/>
      <g ref={bodyRef} transform={`translate(${cfg.margin.left}, ${cfg.margin.top})`}
      >
        <g ref={bodyLeftRef} />
        <g ref={bodyRightRef} />
        <text ref={xTitleRef} />
{/*
        <text transform={`translate(${cfg.margin.left}, ${cfg.margin.bottom})`} > 
        <text
          ref={titleRef}
          transform={`translate(${bounded.width / 2}, ${cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize * 1.5, textAnchor: 'middle' }}
        />
*/}
        <text
          ref={unitsRef}
          transform={`translate(${bounded.width - 50}, ${cfg.margin.top})`}
          style={{ fontSize: cfg.fontSize, textAnchor: 'right' }}
        />
{/*
        <g
          ref={xAxisLeftRef}
          transform={`translate(${0}, ${bounded.height})`}
          style={{ fontSize: cfg.fontSize }}
        />
        <g
          ref={xAxisRightRef}
          transform={`translate(${0}, ${bounded.height})`}
          style={{ fontSize: cfg.fontSize }}
        />
*/}
        <g ref={xAxisLeftRef } style={{ fontSize: cfg.fontSize }} />
        <g ref={xAxisRightRef} style={{ fontSize: cfg.fontSize }} />
        <g ref={yAxisLeftRef } style={{ fontSize: cfg.fontSize }} />
        <g ref={yAxisRightRef} style={{ fontSize: cfg.fontSize }} />
        <g className="bar"
          ref={legendRef}
          transform={`translate(${cfg.margin.left}, ${bounded.height + cfg.legendGutter})`}
        />
      </g>
    </svg>
  );
});
function translation(x,y) {
  return 'translate(' + x + ',' + y + ')';
}


export default Pyramid;
