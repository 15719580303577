import * as d3 from 'd3';

const heights = [360]

export const class3 = {
  wparam : {
    width : 520,
    height: heights[0],
    units:'(단위:개)',
    numFormat:',',
  },
};

export const pyramid = {
  wparam : {
    width : 720,
    height: heights[0],
  }
};

export const mline = {
  wparam : {
    width : 480,
    height: heights[0],
  }
};

export const radar3 = {
  wparam : {
    width : 360,
    height: heights[0],
  }
};



/**
export default htype = {
  { agegroup : "4",    agenmMale: "age_male_5",   agenmFemale: "age_female_5",   male: 0, female: 0 },
}

    const cfg = Object.assign({}, Config, config);
    const population = {

      data: [
        { agegroup : "0-4",    agenmMale: "age_male_5",   agenmFemale: "age_female_5",   male: 0, female: 0 },
        { agegroup : "5-9",    agenmMale: "age_male_10",  agenmFemale: "age_female_10",  male: 0, female: 0 },
**/


export const htype = [
  { name : "htype", num_house: 50, num_apt: 100, num_etc: 100 },
/**
  { name : "htype", htype: "num_apt",     cnt: 100 },
  { name : "htype", htype: "num_etc",     cnt: 100 },
**/
];
htype["columns"] = ["name", "htype", "num_house", "num_apt", "num_etc"];

/*8
export const htype = `name	num_house	num_apt	num_etc
cnt	821	1320	913
`;
**/
export const htype1 = `그룹	단독	아파트	연립/다세대	기타
전체평균	821	1320	864	49
`;
export const htype2 = `그룹	단독	아파트	연립주택	다세대	기타
전체평균	821	1320	354	510	49
`;

export const htype3 = `그룹	단독-일반	단독-다가구	단독-영업겸용	아파트	연립주택	다세대	비거주용건물내
전체평균	551	188	82	1320	354	510	49
`;

export const htypetmp = `그룹	배려	개방성	학습	즐거움	결과	권위	안전	질서
전체평균	3.06	2.77	3.01	3.01	3.04	2.9	3.38	3.09
`;

/**
export const htype = `그룹	단독-일반	단독-다가구	단독-영업겸용	아파트	연립주택	다세대	비거주용건물내
전체평균	551	188	82	1320	354	510	49
551 * 100 / 3054
188 * 100 / 3054
82 * 100 / 3054
1320 * 100 / 3054
354 * 100 / 3054
510 * 100 / 3054
49 * 100 / 3054
**/
