import React from 'react';
import ChartContainer from './ChartContainer';
import { parseDataString } from 'shared/helpers';

function DataContainer({ dsvString, jsonString, transpose, ...props }) {
//function DataContainer({ dsvString, transpose, ...props }) {
  const data = React.useMemo(() => {
    if (dsvString) {
      return parseDataString(dsvString, transpose);

    } 
    else if (jsonString) {
      return jsonString
    }
    else {
      return {};
    }
  }, [dsvString, jsonString, transpose]);

  return <ChartContainer data={data} {...props} />;
}

export default DataContainer;
