
export default function watermark(element, config) {

    const width  = config.width;
    const height = config.height;
    const msg    = config.msg;
   
    // root component
    const root = element
      .append('g')
      .attr("transform", "translate(0,0)")

    // background
    root
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', "100%")
      .attr('height', "100%")
      .attr('fill', '#fff')

    // watermark
    root
      .append("text")
      .text( msg )
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ") rotate(315)")
      .attr("font-size", "24")
      .attr('fill', '#4D4D4D')
      .style('text-anchor', 'middle')
}
