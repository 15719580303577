import mapboxgl from 'mapbox-gl';
import React from 'react';
import MapToolbar from './MapToolbar';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MBMap.scss';
import { sourceFromUrl } from './sources';
import {
  gridLayer,
  highlightedGridLayer,
} from './layers';
import { populationScheme } from './styles';

const TOKYO_COORDS = [35.6762, 139.6503];

class MBMap extends React.Component {
  constructor(props) {
    super(props);

    this.selected = new Map();

    this.state = {
      lng: TOKYO_COORDS[0],
      lat: TOKYO_COORDS[1],
      zoom: 11,
      tool: 'select',
      action: 'new',
    };

    const { onFeatureHover } = this.props;

    this.onFeatureHover = onFeatureHover;
//    this.onBookmarkAdd = this.onBookmarkAdd;
    this.onMapLoad = this.onMapLoad.bind(this);
    this.handleToolChange = this.handleToolChange.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleBookmarkAdd = this.handleBookmarkAdd.bind(this);
  }

  componentDidMount() {
//    const { selection } = this.props;
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style:
        'https://api.maptiler.com/maps/streets/style.json?key=ElkgcjiPHXyLvEovADXa',
      // 'https://api.maptiler.com/maps/basic/style.json?key=ElkgcjiPHXyLvEovADXa',
      // 'https://api.maptiler.com/maps/jp-mierune-gray/style.json?key=ElkgcjiPHXyLvEovADXa',
      center: [139.76706, 35.68115],
      zoom: 11,
    });
    this.map = map;

    map.boxZoom.disable();
    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    map.addControl(scale);

    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', this.onMapLoad);
    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      });
    });

    this.disableDragRotate();
  }

  componentWillUpdate(nextProps, nextState) {
    // if (!this.map.loaded()) return;

    const { selection } = this.props;
    const { selection: nextSelection } = nextProps;

    if (
      nextSelection &&
      (selection === null || selection.dateCreated !== nextSelection.dateCreated)
    ) {
      try {
        this.loadSelection(nextSelection);
      } catch (error) {
        console.log('Style is not done loading.');
      }
    }
  }

  loadSelection(selection) {
    if (selection) {
      this.selected = new Map(selection.features);
      this.map.setFilter('grid-highlighted', [
        'in',
        'key_code',
        ...Array.from(this.selected.keys()),
      ]);
    }
  }

  onContextMenu(e) {}

  disableDragRotate() {
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
    this.map.on('contextmenu', this.onContextMenu);
  }

  enableDragRotate() {
    this.map.dragRotate.enable();
    this.map.touchZoomRotate.enableRotation();
    this.map.off('contextmenu', this.onContextMenu);
  }

  async onMapLoad() {
    const { onSelectionChange, selection } = this.props;
    const canvas = this.map.getCanvasContainer();

    // Add vector tile sources and layers
    this.map.addSource(
      'grid',
      await sourceFromUrl('http://192.168.0.15:9000/japan'),
    );
    this.map.addLayer(gridLayer);
    this.map.addLayer(highlightedGridLayer);

    /** Load selection */
    this.loadSelection(selection);

    let selStart;
    let selCurrent;
    let selBoxElement;
    let selButton;

    // Return the xy coordinates of the mouse position
    const mousePos = e => {
      var rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop,
      );
    };

    const mouseDown = e => {
      const { tool } = this.state;

      selButton = e.button;

      if (!(tool === 'select' || tool === 'deselect')) return;

      // if (e.shiftKey || e.button !== 0) return;

      // Disable default drag panning when the shift key is held down.
      this.map.dragPan.disable();

      // Call functions for the following events
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('keydown', onKeyDown);

      // Capture the first xy coordinates
      selStart = mousePos(e);
    };

    const onMouseMove = e => {
      // Capture the ongoing xy coordinates
      selCurrent = mousePos(e);

      // Append the box element if it doesnt exist
      if (!selBoxElement) {
        selBoxElement = document.createElement('div');
        selBoxElement.classList.add('boxdraw');
        canvas.appendChild(selBoxElement);
      }

      var minX = Math.min(selStart.x, selCurrent.x),
        maxX = Math.max(selStart.x, selCurrent.x),
        minY = Math.min(selStart.y, selCurrent.y),
        maxY = Math.max(selStart.y, selCurrent.y);

      // Adjust width and xy position of the box element ongoing
      var pos = 'translate(' + minX + 'px,' + minY + 'px)';
      selBoxElement.style.transform = pos;
      selBoxElement.style.WebkitTransform = pos;
      selBoxElement.style.width = maxX - minX + 'px';
      selBoxElement.style.height = maxY - minY + 'px';
    };

    const onMouseUp = e => {
      // Capture xy coordinates
      finish([selStart, mousePos(e)]);
    };

    const onKeyDown = e => {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    };

    const finish = bbox => {
      const { action } = this.state;
      const rightClicked = selButton === 2;

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mouseup', onMouseUp);

      if (selBoxElement) {
        selBoxElement.parentNode.removeChild(selBoxElement);
        selBoxElement = null;
      }

      if (bbox) {
        var features = this.map.queryRenderedFeatures(bbox, {
          layers: ['grid'],
        });

        if (features.length >= 5000) {
          return window.alert('Select a smaller number of features');
        }

        if (action === 'new') {
          if (!rightClicked) {
            this.clearSelection();
            this.selectAll(features);
          } else {
            this.unselectAll(features);
          }
        } else if (action === 'add') {
          if (!rightClicked) {
            this.selectAll(features);
          } else {
            this.unselectAll(features);
          }
        } else if (action === 'remove') {
          if (!rightClicked) {
            this.unselectAll(features);
          } else {
            this.selectAll(features);
          }
        } else {
          console.log('Invalid action.');
        }

        // features.forEach(feature => {
        //   if (unselect || action === 'remove') {
        //     selected.delete(feature.properties.key_code);
        //   } else {
        //     selected.set(feature.properties.key_code, feature.properties);
        //   }
        // });

        // var filter = features.reduce(function(memo, feature) {
        //   memo.push(feature.properties.key_code);
        //   return memo;
        // }, []);

/**
        const filter = ['in', 'key_code'].concat(
          Array.from(this.selected.keys()),
        );
**/

        // this.map.setFilter('grid-highlighted', filter);

        onSelectionChange(this.selected, this.map.getCenter());
        // if (selButton === 0) {
        // map.setFilter('grid-highlighted', oldFilter.concat(filter));
        // onSelectionChange(selected);
        // } else if (selButton === 2) {
        // filter = oldFilter.filter(el => !filter.includes(el));
        // map.setFilter('grid-highlighted', filter);
        // onSelectionChange(selected);
        // }
      }
      this.map.dragPan.enable();
    };

    // this.map.on('click', e => {
    //   var features = this.map.queryRenderedFeatures(e.point, {
    //     layers: ['grid'],
    //   });
    //   // Change the cursor style as a UI indicator.
    //   this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';

    //   if (!features.length) {
    //     // popup.remove();
    //     return;
    //   }

    //   var feature = features[0];

    //   // popup
    //   //   .setLngLat(e.lngLat)
    //   //   .setText(JSON.stringify(feature.properties.key_code))
    //   //   .addTo(map);

    //   onFeatureHover(feature.properties);
    // });

    // Set `true` to dispatch the event before other functions
    // call it. This is necessary for disabling the default map
    // dragging behaviour.
    canvas.addEventListener('mousedown', mouseDown, true);
  }

  onMapClick(e) {}

  selectAll(features) {
    features.forEach(feature => {
      this.select(feature.properties.key_code, feature.properties);
    });
  }

  unselectAll(features) {
    features.forEach(feature => {
      this.unselect(feature.properties.key_code);
    });
  }

  select(gid, feature) {
    this.selected.set(gid, feature);
  }

  unselect(gid) {
    this.selected.delete(gid);
  }
  clearSelection() {
    this.selected.clear();
  }

  setTool(tool) {
    this.setState(state => ({ tool: tool, lastTool: state.tool }));
  }

  lastTool() {
    this.setState(state => ({ tool: state.lastTool, lastTool: state.tool }));
  }

  resizeMap() {
    this.map.resize();
  }

  handleToolChange(newTool) {
    if (newTool === 'clearAll') {
      this.map.setFilter('grid-highlighted', ['in', 'key_code', '']);
    }
    this.setState({ tool: newTool });
  }

  handleActionChange(newAction) {
    this.setState({ action: newAction });
  }

  handleBookmarkAdd() {
    const { onBookmarkAdd } = this.props;
    const center = this.map.getCenter();
    onBookmarkAdd(this.selected, center);
  }

  render() {
    return (
      // eslint-disable-next-line no-return-assign
      <div className="interactive-map">
        <div
          ref={node => (this.mapContainer = node)}
          className="mapContainer"
        />
        <div className="overlay">
          <MapToolbar
            className="toolbar"
            onToolChange={this.handleToolChange}
            onActionChange={this.handleActionChange}
            onBookmarkAdd={this.handleBookmarkAdd}
          />
          <div className="legend">
            {populationScheme.colors.map((c, i) => (
              <div>
                <span style={{ backgroundColor: c }}></span>
                {populationScheme.thresholds[i]}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="map-overlay"> */}
        {/* <div className="sidebarStyle">
            <div>
              Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom:{' '}
              {this.state.zoom}
            </div>
          </div>

          <div class="map-overlay-inner">
            <h2>Some text</h2>
            <label id="month"></label>
            <input
              id="slider"
              type="range"
              min="0"
              max="11"
              step="1"
              value="0"
            />
          </div>
          <div class="map-overlay-inner">
            <div id="legend" class="legend">
              <div class="bar"></div>
              <div>Magnitude (m)</div>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}
export default MBMap;
