import mapboxgl from 'mapbox-gl';
import React, { useEffect } from "react";
import MapToolbar from './MapToolbar';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MBMap.scss';
import { sourceFromUrl } from './sources';
import * as Config from 'config';


import {
  grid,
  population,
  popHighlighted,
  krPopTrendLayer,
  highlightedGridLayer,
} from './layers';
import { populationScheme } from './styles';

//const TOKYO_COORDS = [37.6762, 139.6503];
const SEOUL_COORDS = [37.532600, 127.024612];
var   Gmap;

const MBMap = React.forwardRef(
               ({selection, onSelectionChange, onBookmarkAdd }, ref ) => {

  var mapContainer;
  var selected = new Map();

  const cfg = Object.assign({}, Config);

  const [ didMount, setDidMount ] = React.useState( false )

  const stateDef = {
      lng: SEOUL_COORDS[0],
      lat: SEOUL_COORDS[1],
      zoom: 11,
      tool: 'select',
      action: 'new',
  };
  const [state,   setState ] = React.useState(stateDef);


  const loadSelection = (selection) => {
    if (selection) {
//      selected = new Map(selection.features);
      selected = new Map(selection.featPop);
      Gmap.setFilter('grid-highlighted', [
        'in',
        'key_code',
        ...Array.from(selected.keys()),
      ]);
    }
  }

  const compWillUpdate = () => {
    loadSelection(selection);
  }

  const compDidMount = () => {

    Gmap = new mapboxgl.Map({
      container: mapContainer,
      style: `${cfg.maptiler.styleUrl}`,
/**
      style:
        'https://api.maptiler.com/maps/streets/style.json?key=ElkgcjiPHXyLvEovADXa',
**/
      center: [127.024612, 37.532600],
      zoom: 11,
    });

    Gmap.boxZoom.disable();

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    Gmap.addControl(scale);
    Gmap.addControl(new mapboxgl.NavigationControl());

    Gmap.on('load', onMapLoad);
    Gmap.on('move', () => {
      state.lng = Gmap.getCenter().lng.toFixed(4)
      state.lat = Gmap.getCenter().lat.toFixed(4)
      state.zoom = Gmap.getZoom().toFixed(2)
      setState( state )
    });

    disableDragRotate();
  }



/**
    const { onFeatureHover, onSelectionChange } = this.props;

    this.onFeatureHover = onFeatureHover;
    this.onBookmarkAdd = this.onBookmarkAdd;
    this.onMapLoad = this.onMapLoad.bind(this);
    this.handleToolChange = this.handleToolChange.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleBookmarkAdd = this.handleBookmarkAdd.bind(this);
  }
**/

/**
  componentWillUpdate(nextProps, nextState) {
    // if (!this.map.loaded()) return;

    const { selection } = this.props;
    const { selection: nextSelection } = nextProps;
console.log(this.props)
console.log(selection)
console.log(nextSelection)


    if (
      nextSelection &&
      (selection === null || selection.dateCreated != nextSelection.dateCreated)
    ) {
      try {
        this.loadSelection(nextSelection);
      } catch (error) {
        console.log('Style is not done loading.');
      }
    }
  }
**/

  const onContextMenu = (e) => {}

  const disableDragRotate = () => {
    Gmap.dragRotate.disable();
    Gmap.touchZoomRotate.disableRotation();
    Gmap.on('contextmenu', onContextMenu);
  }

/**
  const enableDragRotate = () => {
    Gmap.dragRotate.enable();
    Gmap.touchZoomRotate.enableRotation();
    Gmap.off('contextmenu', onContextMenu);
  }
**/

  async function onMapLoad() {

    const canvas = Gmap.getCanvasContainer();

    // Add vector tile sources and layers
    Gmap.addSource(
      'population',
      await sourceFromUrl(`${cfg.net.enabled.url}:${cfg.net.enabled.port.mbtiles}/kr.popAndTrend`),

    );

    Gmap.addLayer(grid);
    Gmap.addLayer(population);
    Gmap.addLayer(popHighlighted);

//    Gmap.addLayer(krPopLayer);
    Gmap.addLayer(krPopTrendLayer);
    Gmap.addLayer(highlightedGridLayer);

    /** Load selection */
    loadSelection(selection);

    let selStart;
    let selCurrent;
    let selBoxElement;
    let selButton;

    // Return the xy coordinates of the mouse position
    const mousePos = e => {
      var rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop,
      );
    };

    const mouseDown = e => {
      const { tool } = state;

      selButton = e.button;
      const rightClicked = selButton === 2;

      if( rightClicked ) return;

      if (!(tool === 'select' || tool === 'deselect')) return;

      if (e.shiftKey && e.button === 0) return;

      // Disable default drag panning when the shift key is held down.
      Gmap.dragPan.disable();

      // Call functions for the following events
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('keydown', onKeyDown);

      // Capture the first xy coordinates
      selStart = mousePos(e);
    };

    const onMouseMove = e => {
      // Capture the ongoing xy coordinates
      selCurrent = mousePos(e);

      // Append the box element if it doesnt exist
      if (!selBoxElement) {
        selBoxElement = document.createElement('div');
        selBoxElement.classList.add('boxdraw');
        canvas.appendChild(selBoxElement);
      }

      var minX = Math.min(selStart.x, selCurrent.x),
        maxX = Math.max(selStart.x, selCurrent.x),
        minY = Math.min(selStart.y, selCurrent.y),
        maxY = Math.max(selStart.y, selCurrent.y);

      // Adjust width and xy position of the box element ongoing
      var pos = 'translate(' + minX + 'px,' + minY + 'px)';
      selBoxElement.style.transform = pos;
      selBoxElement.style.WebkitTransform = pos;
      selBoxElement.style.width = maxX - minX + 'px';
      selBoxElement.style.height = maxY - minY + 'px';
    };

    const onMouseUp = e => {
      // Capture xy coordinates
      finish([selStart, mousePos(e)], e);
//      finish([selStart, mousePos(e)]);
    };

    const onKeyDown = e => {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    };

    const finish = (bbox, e) => {
      const { action } = state;
      const rightClicked = selButton === 2;

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mouseup', onMouseUp);

      if (selBoxElement) {
        selBoxElement.parentNode.removeChild(selBoxElement);
        selBoxElement = null;
      }

      if (bbox) {

        const featPop = Gmap.queryRenderedFeatures(bbox, {
          layers: ['population'],
        });

        const featPopTimeseries = Gmap.queryRenderedFeatures(bbox, {
          layers: ['krPopTrendLayer'],
        });

        const features = {
          'population': featPop,
          'poptrend': featPopTimeseries
        }

        if (features.length >= 5000) {
          return window.alert('Select a smaller number of features');
        }

        if (action === 'new') {
          if (!rightClicked) {
            clearSelection();
            selectAll(features);
          } else {
//            unselectAll(features);
          }
        } else if (action === 'add') {
          if (!rightClicked) {
            selectAll(features);
          } else {
            unselectAll(features);
          }
        } else if (action === 'remove') {
          if (!rightClicked) {
            unselectAll(features);
          } else {
            selectAll(features);
          }
        } else {
          console.log('Invalid action.');
        }

        onSelectionChange(selected, Gmap.getCenter());
      }
      Gmap.dragPan.enable();
    };

    canvas.addEventListener('mousedown', mouseDown, true);
  }

//  const onMapClick = (e) => {}

  const selectAll = (features) => {

    var featPop = new Map()
    var featPoptrend = new Map()

    features.population.forEach(feature => {
      select(feature.id, feature.properties, featPop);
    });

    features.poptrend.forEach(feature => {
      select(feature.id, feature.properties, featPoptrend);
    });

    selected = {
        'population': featPop,
        'poptrend': featPoptrend,
    }
  }

  const select = (gid, feature, buf) => {

    buf.set(gid, feature )
  }

  const unselectAll = (features) => {

    features.forEach(feature => {
      unselect(feature.properties.key_code);
//      this.unselect(feature.properties.key_code);
    });
  }

  const unselect = (gid) => {
    selected.delete(gid);
  }
  const clearSelection = () => {
    if( selected.poptrend   ) selected.poptrend.clear()
    if( selected.population ) selected.population.clear()
  }

/**
  const setTool = (tool) => {

    state.lastTool = state.tool
    state.tool = tool
    setState(state)
  }

  const lastTool = () => {
    state.lastTool = state.tool
    state.tool = state.lastTool
    setState(state)
  }

  const resizeMap = () => {
    Gmap.resize();
  }
**/


  const handleToolChange = (newTool) => {

    if (newTool === 'clearAll') {
      Gmap.setFilter('grid-highlighted', ['in', 'key_code', '']);
    }

    state.tool = newTool
    setState( state );
  }


  const handleActionChange = (newAction) => {
    state.action = newAction
    setState( state )
  }

  const handleBookmarkAdd = () => {
    const center = Gmap.getCenter();
    onBookmarkAdd(selected, center);
  }

  useEffect( () => {

    if( didMount ) {
      compWillUpdate()
    }
    else
    {
      compDidMount()
      setDidMount(true)
    }

  }, [selection] );

//  render() {
    return (
      // eslint-disable-next-line no-return-assign
      <div className="interactive-map">
        <div
          ref={node => (mapContainer = node)}
          className="mapContainer"
        />
        <div className="overlay">
          <MapToolbar
            className="toolbar"
            onToolChange={handleToolChange}
            onActionChange={handleActionChange}
            onBookmarkAdd={handleBookmarkAdd}
          />
          <div className="legend">
            {populationScheme.colors.map((c, i) => (
              <div key={"legendScheme"+i}>
                <span style={{ backgroundColor: c }}></span>
                {populationScheme.thresholds[i]}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="map-overlay"> */}
        {/* <div className="sidebarStyle">
            <div>
              Longitude: {state.lng} | Latitude: {state.lat} | Zoom:{' '}
              {state.zoom}
            </div>
          </div>

          <div class="map-overlay-inner">
            <h2>Some text</h2>
            <label id="month"></label>
            <input
              id="slider"
              type="range"
              min="0"
              max="11"
              step="1"
              value="0"
            />
          </div>
          <div class="map-overlay-inner">
            <div id="legend" class="legend">
              <div class="bar"></div>
              <div>Magnitude (m)</div>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    );
});

export default MBMap;
