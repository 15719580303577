import React from 'react';
import ChartMenu from './ChartMenu';
//import CSVLink from 'components/Link/CSVLink';
//import PNGLink from 'components/Link/PNGLink';
import Paper from '@material-ui/core/Paper';
//import { SizeMe } from 'react-sizeme';
//import { withSize } from 'react-sizeme';

//const MENU_HEIGHT = 28;

class ChartContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.state = {
      visible: true,
    };
  }

  setVisible = (visible) => this.setState({ visible });

  render() {
    const {
      children,
      type,
      data,
      id,
      title,
      component: Component,
      dndProps,
      size,
      onEdit,
      onChartClick,
      ...rest
    } = this.props;
    const { visible } = this.state;


    return (
      <Paper
        variant="outlined"
        style={{
          cursor: 'default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <ChartMenu
          svgElement={this.svgRef}
          id={id}
          title={title}
          data={data}
          visible={visible}
          handleVisClick={this.setVisible}
          handleEditClick={onEdit}
        />
        {visible && (
          <Component data={data} chartId={id} type={type} callback={onChartClick} ref={this.svgRef} {...rest} />
        )}
      </Paper>
    );
  }
}

export default ChartContainer;
