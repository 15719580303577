import { populationScheme } from './styles';

export const krRetailLayer = {
  id: 'krRetailLayer',
  type: 'line',
  source: 'retail',
  'source-layer': 'krretail',
  paint: {
    'line-color': 'rgba(0,0,0,0.3)',
//    'line-color': '#484896',
    'line-width': 0.5,
  },
//  filter: ['==', 'year', '2010'],
};

export const highlightedGridLayer = {
  id: 'grid-highlighted',
  type: 'fill',
  source: 'retail',
  'source-layer': 'krretail',
  paint: {
    'fill-outline-color': '#484896',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'nos'],
      ...populationScheme.expr,
    ],
    'fill-opacity': 0.75,
  },
  filter: ['in', 'key_code', '' ] 
};


/**
export const krRetailPointLayer = {
  id: 'pointLayer',
  source: 'raw',
  type: 'circle',
  'source-layer': 'raw',

  paint: {
    'circle-radius': 1.5,
    'circle-color': '#007cbf'
  },

};

export const krRetailGridLayer = {
  id: 'gridLayer',
  type: 'line',
  source: 'Q13',
  'source-layer': 'Q13',
  paint: {
    'line-color': 'rgba(0,0,0,0.1)',
    'line-width': 1,
  },
//  filter: ['==', 'year', '2010'],
};
**/
