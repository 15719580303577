import React from "react";

import MBMap from 'components/Jmap/MBMap';
import * as d3 from 'd3';


import Navbar from "components/Navbar.js";
//import Footer from "components/Footer.js";
import Sidebar from "components/Sidebar.js";



//const ResponsiveGridLayout = WidthProvider(Responsive);
export default function MapView() {

  const [selection, setSelection ] = React.useState(null);
  const [bookmarks, setBookmarks ] = React.useState([]);
//  const [hovered,   setHovered   ] = React.useState({});

  React.useEffect( () => {

    // codes that equivalant to componentWillMount
    window.addEventListener('beforeunload', onUnload);
    localStorageLoad();


    // equivalant to componentWillUnmount
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    }
  }, []);


  const onUnload = e => {
    localStorageSave();
  };

  const localStorageSave = function() {
    localStorage.setItem(
      'selection',
      JSON.stringify(selection, jsonMapReplacer),
    );
    localStorage.setItem(
      'bookmarks',
      JSON.stringify(bookmarks, jsonMapReplacer),
    );
  };

  const localStorageLoad = function() {
    const selection = JSON.parse(
      localStorage.getItem('selection'),
      jsonMapReviver,
    );
/**
    const bookmarks = JSON.parse(
      localStorage.getItem('bookmarks'),
      jsonMapReviver,
    );
**/

    selection ? setSelection(selection) : setSelection(null);
//    bookmarks ? setBookmarks(bookmarks) : setBookmarks([]);

/**
    setState(state => ({
      selection: selection ? selection : null,
      bookmarks: bookmarks ? bookmarks : [],
    }));
**/
  };

  const jsonMapReplacer = function(key, value) {
    const originalObject = this[key];
    if (originalObject instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(originalObject.entries()), // or with spread: value: [...originalObject]
      };
    } else {
      return value;
    }
  };

  const jsonMapReviver = function(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  };

  const handleFeatureHover = function(feature) {
alert( "111" );
    if (feature) {
//      setHovered({hovered: {feature}} )
    }
  };

  const handleSelectionChange = function(features, center) {
    const sel = newSelectionObject(features, center);
    setSelection(sel);
  };

  const newSelectionObject = function(features, latlng) {
    const sel = {
      center: latlng.toArray(),
      features: new Map(features),
      dateCreated: Date.now(),
    };
    return sel;
  };

  const handleBookmarkAdd = function(features, center) {
    const selection = newSelectionObject(features, center);
    const newBookmark = {
      name: `b${selection.features.size}`,
      selection: selection,
      dateCreated: Date.now(),
    };
    setBookmarks([bookmarks, newBookmark]);
    localStorageSave();
  };


/**
  const handleResize = function(layout, oldItem, newItem, placeholder, e, element) {
    if (this.mapComponent) {
      this.mapComponent.current.resizeMap();
    }
  };
**/

/**
  const handleBookmarkClick = function(bookmark) {
    setSelection(bookmark.selection);
  };

  const handleBookmarkDelete = function(action, index) {
    const newBookmarks = bookmarks
      .slice(0, index)
      .concat(bookmarks.slice(index + 1));

    if (action === 'Delete') {
      setBookmarks( newBookmarks );
    };
  };
**/

/**
  const renderSelectionJson = function(dataAgg) {
    if (dataAgg) {
      const formatted = dataAgg.reduce((acc, row) => {
        acc[row.group] = row.value;
        return acc;
      }, {});
      return <pre>{JSON.stringify(formatted, null, 2)}</pre>;
    }
  };
**/

/**
  const renderBookmark = function(bookmark) {
    if (bookmark) {
      return <li>{bookmark.length}</li>;
    }
  };
**/

/**
  const renderBookmarks = function() {
    return bookmarks.map(b => renderBookmark(b));
  };
**/



  const data = selection ? Array.from(selection.features.values()) : [];

    /** Prepare data for use with d3 */
  let dataAgg = [];

  if (data.length > 0) {
    /* eslint-disable no-unused-vars */
    const [gid, ...fields] = Object.keys(data[0]);
    /* eslint-enable no-unused-vars */

    fields.forEach(field => {
      const vals = data.reduce((memo, d) => {
        memo.push(d[field]);
        return memo;
      }, []);
      const sum = d3.sum(vals);
      dataAgg.push({ group: field, value: sum });
    });
  }


  return (
    <>
      <Sidebar />
      <Navbar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="bg-pink-00 p-4 flex-auto w-full xl:w-8/12">

          <div className="relative" style={{height: "650px" }}>

            {/* Card stats */}
            <MBMap
              selection={selection}
              onFeatureHover={handleFeatureHover}
              onSelectionChange={handleSelectionChange}
              onBookmarkAdd={handleBookmarkAdd}
            />
          </div>
        </div>

{/*
        <div className="bg-pink-00 p-3 flex-auto w-full xl:w-12/12">
          <div>
            <CopyDialog open={open} onClose={handleClose} onApply={handleApply} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Card>
                  <ChartContainer
                    onEdit={handleOnEdit}
                    component={Bar}
                    dsvString={bar1}
                    id={rchartlist[7].title}
                    options={custom.barResponses}
                    transpose
                  />
                </Card>
              </div>
          </div>
        </div>
*/}
      </div>
    </>

  );
}
