import { populationScheme } from './styles';

export const gridLayer = {
  id: 'grid',
  type: 'line',
  source: 'grid',
  'source-layer': 'japangeo',
  paint: {
    // 'fill-outline-color': 'rgba(0,0,0,0.1)',
    // 'fill-color': 'rgba(0,0,0,0)',
    'line-color': 'rgba(0,0,0,0.1)',
    'line-width': 1,
  },
  //   filter: ['!', ['has', 't000876001']],
};

export const highlightedGridLayer = {
  id: 'grid-highlighted',
  type: 'fill',
  source: 'grid',
  'source-layer': 'japangeo',
  paint: {
    'fill-outline-color': '#484896',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 't000876001'],
      ...populationScheme.expr,
    ],
    'fill-opacity': 0.75,
  },
  filter: ['in', 'key_code', ''],
};

export const clusterGridLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'clustergrid',
  'source-layer': 'japangeo',
  filter: ['has', 't000876001'],
  paint: {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    // with three steps to implement three types of circles:
    //   * Blue, 20px circles when point count is less than 100
    //   * Yellow, 30px circles when point count is between 100 and 750
    //   * Pink, 40px circles when point count is greater than or equal to 750
    'circle-color': [
      'step',
      ['get', 't000876001'],
      '#51bbd6',
      100,
      '#f1f075',
      750,
      '#f28cb1',
    ],
    'circle-radius': ['step', ['get', 't000876001'], 20, 100, 30, 750, 40],
  },
};

export const clusterGridCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'clustergrid',
  'source-layer': 'japangeo',
  filter: ['has', 't000876001'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
};

// export const clusterCountLayer = {
//   id: 'cluster-count',
//   type: 'symbol',
//   source: 'earthquakes',
//   filter: ['has', 'point_count'],
//   layout: {
//     'text-field': '{point_count_abbreviated}',
//     'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
//     'text-size': 12,
//   },
// };

// export const unclusteredPointLayer = {
//   id: 'unclustered-point',
//   type: 'circle',
//   source: 'earthquakes',
//   filter: ['!', ['has', 'point_count']],
//   paint: {
//     'circle-color': '#11b4da',
//     'circle-radius': 4,
//     'circle-stroke-width': 1,
//     'circle-stroke-color': '#fff',
//   },
// };
